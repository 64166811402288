import { SET_ORDER_DETAILS } from './orderActionTypes';

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */

function setOrderDetails(state, actions) {
    return {
        ...state,
        order: actions.payload
    };
}


/*
* item example:
* {
*   id: 1,
*   product: {...}
*   options: [
*     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
*   ],
*   price: 250,
*   quantity: 2,
*   total: 500
* }
* extraLine example:
* {
*   type: 'shipping',
*   title: 'Shipping',
*   price: 25
* }
*/

const initialState = {
    order: null
};

export default function orderReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ORDER_DETAILS:
            return setOrderDetails(state, action);
        default:
            return state;
    }
}
