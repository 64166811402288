import { SET_ORDER_DETAILS } from './orderActionTypes';

export function setOrderDetailsItemsSuccess(data) {
    return {
        type: SET_ORDER_DETAILS,
        payload: data
    };
}

export function saveOrderDetails(data) {
    return (dispatch) => {
        dispatch(setOrderDetailsItemsSuccess(data));
    }
}
