// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// application
import Collapse from "../shared/Collapse";
import { ArrowRoundedRight6x9Svg } from "../../svg";
import { addCategoryName } from "../../store/category";
import { useDispatch } from "react-redux";

function WidgetCategories(props) {
    const { categories, location, catID, dispatch } = props;

    const otherDispatch = useDispatch()
    const categoriesList = categories.map((category) => {
        const renderCategory = ({ toggle, setItemRef, setContentRef }) => {
            if (catID === category.id) {

                // otherDispatch(addCategoryName(category))
            }

            let expander;
            let children;
            let active = false;
            let activated;
            if (category.children && category.children.length > 0) {
                expander = (
                    <button
                        className="widget-categories__expander"
                        type="button"
                        aria-label="Expand"
                        onClick={toggle}
                    />
                );
                children = (
                    <div className="widget-categories__subs" ref={setContentRef}>
                        <ul>
                            {category.children.map((sub) => {
                                if (catID === sub.id) {
                                    // otherDispatch(addCategoryName(sub))
                                    active = true;
                                    activated = true;
                                }

                                return (
                                    <li className={activated ? "hoverStyle" : ""} key={sub.id}>
                                        <a
                                            onClick={() => {
                                                dispatch({
                                                    type: "RESET_FILTERS",
                                                    option: "filter_brand",
                                                    value: "",
                                                });
                                                dispatch({
                                                    type: "RESET_FILTERS",
                                                    option: "brand",
                                                    value: "",
                                                });
                                                dispatch({
                                                    type: "SET_OPTION_VALUE",
                                                    option: "category_id",
                                                    value: sub.id,
                                                });
                                            }}
                                        >
                                            {sub.name}
                                        </a>
                                        {(activated = false)}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            }
            return (
                <li
                    className={` ${
                        catID === category.id || active === true ? "widget-categories__item--open" : ""
                    } widget-categories__item`}
                    ref={setItemRef}
                >
                    <div className="widget-categories__row">
                        <a
                            onClick={() => {
                                dispatch({
                                    type: "RESET_FILTERS",
                                    option: "filter_brand",
                                    value: "",
                                });
                                dispatch({
                                    type: "RESET_FILTERS",
                                    option: "brand",
                                    value: "",
                                });
                                dispatch({
                                    type: "SET_OPTION_VALUE",
                                    option: "category_id",
                                    value: category.id,
                                });


                            }}
                            className={catID == category.id ? "hoverStyle" : ""}
                        >
                            <ArrowRoundedRight6x9Svg className="widget-categories__arrow" />
                            {category.name}
                        </a>
                        {expander}
                    </div>
                    {children}
                </li>
            );
        };

        return <Collapse key={category.id} toggleClass="widget-categories__item--open" render={renderCategory} />;
    });

    return (
        <div className={`widget-categories widget-categories--location--${location} widget`}>
            <h4 className="widget__title">
                <FormattedMessage id="categories" defaultMessage="Categories" />
            </h4>
            <ul className="widget-categories__list">{categoriesList}</ul>
        </div>
    );
}

WidgetCategories.propTypes = {
    /**
     * category array
     */
    categories: PropTypes.array,
    /**
     * widget location (default: 'blog')
     * one of ['blog', 'shop']
     */
    location: PropTypes.oneOf(["blog", "shop"]),
};

WidgetCategories.defaultProps = {
    categories: [],
    location: "blog",
};

export default WidgetCategories;
