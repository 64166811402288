// react
import React, {Fragment} from 'react';
import {Link} from "react-router-dom";

function Breadcrumbs(props) {
    const {breadcrumb} = props;

    return (
        <div className="breadcrumbs">
            <div className="container navigation">
                {breadcrumb.map((el, index) => {
                    return <Fragment key={index}>
                        <Link  to={el.url} className={`path ${index === breadcrumb.length - 1 && "last_path"}`}>{el.title}</Link>
                        {index !== breadcrumb.length - 1 && <div>/</div>}
                    </Fragment>
                })}
            </div>
        </div>
    );
}


export default Breadcrumbs;
