import { LOGIN_CHANGE } from './loginActionTypes';

const initialState = {login: true};

export default function loginReducer(state = initialState, action) {
    if (action.type == LOGIN_CHANGE) {
        return {
            ...state,
            login: !state.login,
        };
    }
    return state;
}
