// react
import React from "react";

// third-party
import {Redirect, Route, Switch} from "react-router-dom";

// application
import PageHeader from "../shared/PageHeader";

// pages
import AccountPageDashboard from "./AccountPageDashboard";
import AccountPageAddresses from "./AccountPageAddresses";
import AccountPageOrders from "./AccountPageOrders";
import AccountPageOrderHistoryMenger from "./AccountPageOrderHistoryMenger";
import AccountPageProfile from "./AccountPageProfile";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import CompanyUsersPage from "./companyUsers";
import AccountLayoutLinks from "./AccountLayoutLinks";
import {url} from "../../helper";
import Breadcrumbs from "../shared/Breadcrumbs";

export default function AccountLayout(props) {
    const customer = useSelector((state) => state.customer);
    const {match, location} = props;

    const breadcrumb = [
        {title: <FormattedMessage id="home" defaultMessage="home"/>, url: url},
        {title: <FormattedMessage id="my.profile" defaultMessage="My Profile"/>, url: location.pathname},
    ];

    return (
        <React.Fragment>
            <Breadcrumbs breadcrumb={breadcrumb}/>
            <PageHeader header=<FormattedMessage id="my.profile" defaultMessage="My Profile"/>/>

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3 d-flex">
                            <div className="account-nav flex-grow-1">
                                <ul><AccountLayoutLinks match={match}/></ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/dashboard`}/>
                                <Route exact path={`${match.path}/dashboard`} component={AccountPageDashboard}/>
                                <Route exact path={`${match.path}/profile`} component={AccountPageProfile}/>
                                <Route exact path={`${match.path}/addresses`} component={AccountPageAddresses}/>
                                {customer.role && customer.role === "Manager" ? (
                                    <Route exact path={`${match.path}/company-users`} component={CompanyUsersPage}/>
                                ) : (
                                    <Redirect
                                        exact
                                        from={`${match.path}/company-users`}
                                        to={`${match.path}/profile`}
                                    />
                                )}
                                <Route exact path={`${match.path}/orders`} component={AccountPageOrders}/>
                                <Route
                                    exact
                                    path={`${match.path}/orders-menger`}
                                    component={AccountPageOrderHistoryMenger}
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
