import React, {useCallback, useEffect, useMemo, useState} from 'react';
import InputRange from 'react-input-range';
import {connect} from 'react-redux';
import Currency from '../shared/Currency';
import languages from '../../i18n';

import {FormattedMessage} from "react-intl";

function FilterRange(props) {
    const {
        data,
        value,
        onChangeValue,
        initialMaxPrice,
    } = props;

    const [state, setState] = useState(value || [data.min, data.max]);
    const [timer, setTimer] = useState(null);

    const handleChange = useCallback((newValue) => {
        setState([newValue.min, newValue.max]);
        if (onChangeValue) {
            if (timer) {
                clearTimeout(timer);
            }
            setTimer(setTimeout(() => {
                onChangeValue({filter: 'price', value: [newValue.min, newValue.max]});
            }, 1000));
        }
    }, [onChangeValue, setState, timer]);

    useEffect(() => {
        setState(value || [data.min, data.max]);
    }, [value]);

    const handleMinPriceChange = useCallback((event) => {
        const newValue = parseInt(event.target.value);
        setState(state => [newValue, state[1]])
        if (timer) {
            clearTimeout(timer);
        }
        handleChange({min: newValue > state[1] ? state[1] : newValue, max: state[1]});
    }, [handleChange, state, timer]);

    const handleMaxPriceChange = useCallback((event) => {
        const newValue = parseInt(event.target.value);
        setState(state => [state[0], newValue])
        if (timer) {
            clearTimeout(timer);
        }
        handleChange({min: state[0], max: newValue < state[0] ? state[0] : newValue});
    }, [handleChange, state, timer]);

    const {min, max} = data;
    const [from, to] = state;

    return (
        <>
            <div className="filter-price">
                <div className="widget__title title-arrow-flex">
                    <FormattedMessage id={props.title.toLowerCase().replace(/ /g, ".")} defaultMessage={props.title}/>
                </div>
                <div className="filter-inputs">
                    <div>
                        <FormattedMessage id="min" defaultMessage="Minimum">
                            {(message) => <input
                                type='number'
                                value={state[0]}
                                onChange={handleMinPriceChange}
                                placeholder={message}
                                max={state[1]}
                                min={min}
                            />}
                        </FormattedMessage>
                        <span>֏</span>
                    </div>
                    <div>
                        <FormattedMessage id="max" defaultMessage="Maximum">
                            {(message) => <input
                                type='number'
                                value={state[1]}
                                onChange={handleMaxPriceChange}
                                placeholder={message}
                                max={initialMaxPrice}
                                min={state[0]}
                            />}
                        </FormattedMessage>
                        <span>֏</span>
                    </div>
                </div>
                <div className="filter-price__slider" dir="ltr">
                    <InputRange
                        minValue={min}
                        maxValue={initialMaxPrice}
                        value={{min: from, max: to}}
                        step={1}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FilterRange);
