// react
import React from "react";

// third-party
import classNames from "classnames";
import {Link, matchPath, Redirect, Switch, Route, useLocation} from "react-router-dom";

// application
import PageHeader from "../shared/PageHeader";

// pages
import AccountPageDashboard from "./AccountPageDashboard";
import AccountPageAddresses from "./AccountPageAddresses";
import AccountPageOrders from "./AccountPageOrders";
import AccountPageOrderHistoryMenger from "./AccountPageOrderHistoryMenger";
import ShopPageWishlist from "../shop/ShopPageWishlist";
import AccountPageProfile from "./AccountPageProfile";
import {getCartDataMount} from "../../store/cart";
import {FormattedMessage} from "react-intl";

import {useSelector, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {url} from "../../helper";
import CompanyUsersPage from "./companyUsers";

export default function AccountLayoutLinks({match}) {
    const customer = useSelector((state) => state.customer);
    const locale = useSelector((state) => state.locale);
    const location = useLocation()

    const dispatch = useDispatch();
    const history = useHistory();
    const logout = (e) => {
        e.preventDefault();
        localStorage.setItem("userData", JSON.stringify({}));
        localStorage.setItem("companyName", JSON.stringify(""));
        dispatch({type: "AUTHENTICATED", payload: false});
        dispatch({type: "CUSTOMER_TOKEN", payload: ""});
        history.push("/");
        dispatch(getCartDataMount())
    };

    let data;
    if (customer.role === "subUser") {
        data = [
            // { title: "Account settings", url: "dashboard", translation: "accountSettings" },
            {title: "Personal information", url: "profile", translation: "personalInformation"},
            {title: "Order History", url: "orders", translation: "orderHistory"},
            {title: "Order History Menger ", url: "orders-menger", translation: "orderHistorymenger "},
            {title: "Logout", url: "logout", translation: "logout"},
        ];
    } else {
        data = [
            // { title: "Account settings", url: "dashboard", translation: "accountSettings" },
            {title: "Personal information", url: "profile", translation: "personalInformation"},
            {title: "Company Users", url: "company-users", translation: "companyUsers"},
            {title: "Addresses", url: "addresses", translation: "addresses"},
            {title: "Order History", url: "orders", translation: "orderHistory"},
            // { title: "Wishlist", url: "shop/wishlist", translation: "wishlist" },
            {title: "Logout", url: "logout", translation: "logout"},
        ];
    }


    return data.map((link) => {
        const url = match ? `${match.url}/${link.url}` : `/${locale}/account/${link.url}`;
        const isActive = match ? matchPath(location.pathname, {path: url, exact: true}) : false;
        const classes = classNames("account-nav__item", {
            "account-nav__item--active": isActive,
        });

        const links = () => {
            switch(true){
                case(link.url === "logout"):
                    return <li key={link.url} className={classes}>
                        <a href={`/${locale}/account/logout`} onClick={logout} style={{color: "red"}}>
                            <FormattedMessage id="account.logout" defaultMessage="Logout"/>
                        </a>
                    </li>
                case(link.url !== "logout" && link.url !== "shop/wishlist" && link.url !== "company-users"):
                    return <li key={link.url} className={classes}>
                        <Link to={url}>
                            <FormattedMessage id={`account.${link.translation}`} defaultMessage={link.title}/>
                        </Link>
                    </li>
                case(link.url === "shop/wishlist"):
                    return <li key={link.url} className={classes}>
                        <Link to={`${locale}/shop/wishlist`}>
                            <FormattedMessage id={`account.${link.translation}`} defaultMessage={link.title}/>
                        </Link>
                    </li>
                case(customer.role && customer.role === "Manager" && link.url === "company-users"):
                    return <li key={link.url} className={classes}>
                        <Link to={url}>
                            <FormattedMessage id={`account.${link.translation}`} defaultMessage={link.title}/>
                        </Link>
                    </li>
                default: return;
            }
        }

        return links()

        // return (

            // <li key={link.url} className={classes}>
            //     {link.url === "logout" ? (
            //         <a href="/account/logout" onClick={logout} style={{color: "red"}}>
            //             <FormattedMessage id="account.logout" defaultMessage="Logout"/>
            //         </a>
            //     ) : (
            //         ""
            //     )}
            //     {link.url !== "logout" && link.url !== "shop/wishlist" && link.url !== "company-users" ? (
            //         <Link to={url}>
            //             <FormattedMessage id={`account.${link.translation}`} defaultMessage={link.title}/>
            //         </Link>
            //     ) : (
            //         ""
            //     )}
            //     {link.url === "shop/wishlist" ? (
            //         <Link to={"/shop/wishlist"}>
            //             <FormattedMessage id={`account.${link.translation}`} defaultMessage={link.title}/>
            //         </Link>
            //     ) : (
            //         ""
            //     )}
            //     {customer.role && customer.role === "Manager" && link.url === "company-users" ? (
            //         <Link to={url}>
            //             <FormattedMessage id={`account.${link.translation}`} defaultMessage={link.title}/>
            //         </Link>
            //     ) : (
            //         ""
            //     )}
            // </li>
        // );
    });
}
