import React, {useEffect, useRef, useState} from "react"
import MenuLinks from "./MenuLinks"
import {MenuIconSVG} from "../../svg";
import {FormattedMessage} from "react-intl";
import DropdownLanguage from "./DropdownLanguage";
import {useLocation} from "react-router-dom";


const MenuDropdown = ({routes}) => {

    let location = useLocation()
    const [mobile, setMobile] = useState(window.innerWidth <= 991)

    useEffect(() => {
        setMobile(window.innerWidth <= 991)
    }, [window.innerWidth])

    const [openMenu, setOpenMenu] = useState(false)

    const menuRef = useRef(null);
    const isInsideDropdownRef = useRef(false);

    useEffect(() => {
        const closeWhenDocumentClick = (event) => {
            if (!isInsideDropdownRef.current && menuRef.current && !menuRef.current.contains(event.target)) {
                setOpenMenu(false);
            }
            isInsideDropdownRef.current = false;
        };

        if (openMenu) {
            document.addEventListener("click", closeWhenDocumentClick);
        }

        return () => {
            document.removeEventListener("click", closeWhenDocumentClick);
        };
    }, [openMenu]);


    const handleDropdownClick = () => {
        isInsideDropdownRef.current = true;
    };

    return <>

        <div style={{display: "flex"}}>
            <div className='site_menu_icon' onClick={() => setOpenMenu(!openMenu)}>

                <div>
                    <MenuIconSVG/>


                </div>

                <div>
                    <span>
                        <FormattedMessage id={"menu"} defaultMessage="Menu"/>

                    </span>
                </div>

            </div>
            {openMenu && <div className="category-dropdown-wrapper" ref={menuRef}>
                <div className="category-dropdown" onClick={handleDropdownClick}>
                    <MenuLinks links={routes} depth={0} openMenu={openMenu} setOpenMenu={setOpenMenu}/>
                    {mobile && (
                        <div className="mobile_lang_select">
                            <div className={"topbar__item " + (location.pathname !== "/" ? 'header_nav_fm' : '')}>
                                <DropdownLanguage context={mobile && "mobile"} setOpenMenu={setOpenMenu}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>}
        </div>

    </>
}

export default MenuDropdown
