import React from "react";

const ConfigAttributes = (props) => {

    const {attr, label, goToFirstIndex, state, handleChangeStyle, handleChange} = props

    return (
        <div key={attr + label} className="attr_fms">
            {label}
            {attr.options.length && !attr.options[0].swatch_value && (
                <div className="other-list-main-fms">
                    {attr.options.map((e, i) => {

                        return (
                            <label
                                key={i}
                                className={`other-list__item ${attr.code}_fm attr-border-fm`}
                                id={e.id + attr.code}
                            >
                                <span className="other-list__input">
                                    <span className="other-radio__body">
                                        <input
                                            id={attr.code + e.id}
                                            className="other-radio__input"
                                            type="radio"
                                            name={attr.code}
                                            value={e.id}
                                            defaultChecked={state[attr.code] && state[attr.code].id === e.id}
                                            checked={state[attr.code] && state[attr.code].id === e.id}
                                            onChange={(target) => {
                                                // this.addAttributes(attr.code, e.label)
                                                handleChangeStyle(target, attr.code, e.id);
                                                handleChange(e.products, attr.code, e.id);
                                                goToFirstIndex();
                                            }}
                                        />
                                    </span>
                                </span>
                                <span className="other-list__title">{e.label}</span>
                            </label>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default ConfigAttributes
