// react
import React from 'react';
// third-party
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import BlockNavMenu from '../blocks/BlockNavMenu'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react';

// application
import NavPanel from './NavPanel';
import Search from './Search';
import Topbar from './Topbar';
import { LogoSvg } from '../../svg';
import { useWindowWidth } from '../../hooks/useWindowWidth';

function Header(props) {
    let location = useLocation()
    const width = useWindowWidth()
    useEffect(
        () => {},
        [location]
    )
    const { layout } = props;

    let bannerSection;
    let blockNavMenu

    if (layout === 'default') {
        bannerSection = (
            <div className="site-header__middle container">
                <div className="site-header__logo">
                    <Link to="/"><LogoSvg /></Link>
                </div>
                <div className="site-header__search">
                    <Search context="header" />
                </div>
                <div className="site-header__phone">
                    <div className="site-header__phone-title">
    
                    </div>
                    <div className="site-header__phone-number">
                        <FormattedMessage id="header.phone" defaultMessage="(800) 060-0730" />
                    </div>
                </div>
            </div>
        );
    }

    if(location.pathname != '/' && location.pathname != '/shop/catalog'){
        blockNavMenu = (<BlockNavMenu />)
    }

    return (
        <div className="site-header">
         {width > 991 ? <Topbar /> : ""}   
            {/* {bannerSection} */}
            <div className="site-header__nav-panel">
                <NavPanel />
            </div>
            {/* {blockNavMenu} */}
           
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

Header.defaultProps = {
    layout: 'default',
};

export default Header;
