// react
import React, {useEffect, useState} from "react";

// application
import FooterContacts from "./FooterContacts";
import FooterLinks from "./FooterLinks";
import FooterNewsletter from "./FooterNewsletter";
import ToTop from "./ToTop";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {url} from "../../helper";
// data stubs
import theme from "../../data/theme";
import {Form, Modal} from "reactstrap";
import {
    BanksSvg,
    HomeNavSVG,
    LogoSvg,
    UserNavSVG,
    WishlistNavSVG,
    CartNavSVG,
    Cross20Svg,
    AmExpressSVG,
    IDramSVG,
    MasterSVG,
    MirSVG,
    PaypalSVG,
    VisaSVG,
    ArcaSVG,
    SearchIconSVG,
    SearchIconActiveSVG
} from "../../svg";
import ContactWithUs from "./ContactWithUs";
import SocialLinks from "../shared/SocialLinks";
import {useLocation, useHistory, Link} from "react-router-dom";
import CustomBottomNavigation from "../shared/CustomBottomNavigation";
import Indicator from "../header/Indicator";
import LoginBlock from "../blocks/LoginBlock";
import {toast} from "react-toastify";
import {useWindowWidth} from '../../hooks/useWindowWidth';
import arca from "../../svg/arca.png"
import Search from "../header/Search";

export default function Footer() {
    // const informationLinks = [
    //     { title: 'About Us', url: '/site/about-us' },
    //     { title: 'About Shop Delivery', url: '' },
    //     { title: 'New Items', url: '' },
    //     { title: 'Special Offers', url: '/menu-page/15' },
    //     { title: 'Careers', url: '' },
    //     { title: 'Cusomer Reviews', url: '' },
    //     { title: 'Blog', url: `/${locale}/blog` },
    //     { title: 'Contact Us', url: '/site/contact-us' },
    // ];
    const locale = useSelector((state) => state.locale);
    const customer = useSelector((state) => state.customer);
    const wishlist = useSelector((state) => state.wishlist);
    const cart = useSelector((state) => state.cart);
    const [navLinks, SetNavLinks] = useState();
    const [login, SetLogin] = useState(false)
    const [forgotPass, SetForgotPass] = useState(false)
    const [reg, SetReg] = useState(false)
    const [sign, SetSign] = useState(false)
    const [modal, SetModal] = useState(false)
    const [searchModal, setSearchModal] = useState(false)
    const location = useLocation();
    const router = useHistory();

    const width = useWindowWidth()

    useEffect(() => {
        setSearchModal(false)
    }, [location])

    useEffect(() => {
        fetch(`${url}/api/cms/menus?locale=${locale}`)
            .then((response) => response.json())
            .then((res) => {
                SetNavLinks(res.data);
            });
    }, [locale]);

    let FooterContact;
    if (navLinks != undefined) {
        navLinks.sort(function (a, b) {
            var keyA = a.position,
                keyB = b.position;
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });
    }
    if (navLinks) {
        FooterContact = navLinks.map((item, index) => {
            if (item.type == "footer" && item.status) {
                return (
                    <li key={index}>
                        <FooterContacts id={item.id} page_id={item.page_id} url_key={item.url_key} name={item.name}/>
                    </li>
                );
            }
        });
    }
    let FilteredFooterContact;
    if (FooterContact) {
        FilteredFooterContact = FooterContact.filter(function (x) {
            return x !== undefined;
        });
    }
    if (!FilteredFooterContact) {
        return null;
    }
    const links = [{title: <FormattedMessage id="footer.contact.us" defaultMessage="Contact with us "/>, url: ""}];
    const linksList = links.map((item, index) => (
        <Link key={index} to={`/${locale}/site/contact-us`}>
            <span className="topbar__item-title-fms">{item.title}</span>
        </Link>
    ));

    const date = new Date().getFullYear();


    // const [cart, SetCart] = useState(false)

    const clicked = (type, check) => {
        SetModal(true)
        if (type == 'login')
            SetLogin(check)

        if (type == 'reg')
            SetReg(check)

        if (type == 'sign')
            SetSign(check)

        if (type == 'forgot')
            SetForgotPass(check)
    }

    const close = (type, check) => {
        clicked(type, check)
    }

    const closeModal = () => {
        SetModal(false)
        SetSign(false)
        SetReg(false)
        SetLogin(false)
        SetForgotPass(false)
    }

    const bottomNavItems = [
        /*Home*/
        {
            icon: <Indicator aria-label="mobile go home" url="/" icon={<HomeNavSVG className={"click-disabled"}/>}/>,
            activeIcon: (
                <Indicator aria-label="mobile go home" url="/" icon={<HomeNavSVG className={"click-disabled"}/>}/>
            ),
            title: <FormattedMessage id="home" defaultMessage="Home"/>,
            param: ["/"],
            onClick: () => router.push("/"),
        },
        {
            icon: <SearchIconSVG/>,
            activeIcon: <SearchIconActiveSVG className="disabled-path-style"/>,
            title: "",
            param: [`/${locale}/shop/main/catalog/search`],
            onClick: () => setSearchModal(true),
        },

        {
            icon: (
                <Indicator
                    className={"indicator--mobile"}
                    aria-label="go to shop card"
                    url={`/${locale}/shop/cart`}
                    value={cart?.items?.length}
                    icon={<CartNavSVG className={"click-disabled"}/>}
                />
            ),
            activeIcon: (
                <Indicator
                    style={{fontSize: "18px", color: "red"}}
                    icon={<CartNavSVG className={"click-disabled"}/>}
                    url={`/${locale}/shop/cart`}
                    value={cart?.items?.length}
                    aria-label="go to shop card"
                />
            ),
            title: <FormattedMessage id="cart" defaultMessage="Cart"/>,
            param: [`/${locale}/shop/cart`, `/${locale}/shop/checkout`],
            onClick: () => router.push(`/${locale}/shop/cart`),
        },
        /*Divider*/

        /*Favourite*/
        {
            icon: customer.authenticated ? (
                <Indicator
                    className={"indicator--mobile"}
                    url="/wishlist"
                    value={wishlist.length}
                    icon={<WishlistNavSVG className={"click-disabled"}/>}
                />
            ) : (
                <div className={"wishlist-icon-mobile"}>
                    <WishlistNavSVG className={"click-disabled"}/>
                    {/* <span className="indicator__value">0</span> */}
                </div>
            ),

            activeIcon: customer.authenticated ? (
                <Indicator
                    className={"indicator--mobile"}
                    url="/wishlist"
                    value={wishlist.length}
                    icon={<WishlistNavSVG className={"click-disabled"}/>}
                />
            ) : (
                <div className={"wishlist-icon-mobile"}>
                    <WishlistNavSVG className={"click-disabled"}/>
                    {/* <span className="indicator__value">0</span> */}
                </div>
            ),
            title: <FormattedMessage id="wishlist" defaultMessage="Favorites"/>,
            param: ["/wishlist"],
            onClick: () => {
                !customer.authenticated
                    ?
                    toast.success(
                        <FormattedMessage
                            id="please.login"
                            defaultMessage="`Please login or register in order to add to favorites.`"
                        />
                    )
                    : router.push(`/${locale}/shop/wishlist`);
            },
        },
        /*Sign in*/
        {
            icon:
                customer && customer.authenticated ? (
                    <Indicator url={`/${locale}/account/profile/`} icon={<UserNavSVG className="authenticated"/>}/>
                ) : (
                    <UserNavSVG/>
                ),
            activeIcon: <UserNavSVG className={customer && customer.authenticated && "authenticated"}/>,
            param: [`/${locale}/account/profile`, `/${locale}/account/addresses`, `/${locale}/account/addresses/new`, `/${locale}/account/orders`, `/${locale}/signin`],
            onClick: () => {
                customer && !customer.authenticated ? clicked('login', true) : router.push(`/${locale}/account/profile`);
            },
            title: customer.authenticated ? (
                <FormattedMessage id="my_account" defaultMessage="My account"/>
            ) : (
                <FormattedMessage id="sign_in" defaultMessage="Sign in"/>
            ),
        },
    ];
    const closeSearchModal = () => {
        setSearchModal(false)
    }

    return (
        <>
            <Modal className="nav_modal_fm" isOpen={modal} toggle={closeModal} centered size="xl">
                <div className="quickview">
                    <button
                        className="quickview__close"
                        type="button"
                        onClick={() => {
                            closeModal();
                        }}
                    >
                        <Cross20Svg/>
                    </button>
                    <LoginBlock
                        login={login}
                        reg={reg}
                        clicked={clicked}
                        sign={sign}
                        forgot={forgotPass}
                        close={close}
                        closeModal={closeModal}
                    />
                </div>
            </Modal>


            <Modal isOpen={searchModal} className={"search-mobile-mpdal"} toggle={closeSearchModal} size="xl">
                <div className="site-header__search">
                    <Search context="header"/>
                </div>
            </Modal>


            {width <= 768 ? <CustomBottomNavigation items={bottomNavItems}/> : ""}

            <ToTop/>
            {/*{location.pathname === "/" || location.pathname === "/shop/catalog" ? (*/}
            {/*    <div className="footer-in-home">*/}
            {/*        /!* <ul>*/}
            {/*        <li> <a  href="tel:+37411251152"><span><PhoneSVG /></span> <span>+(374) 11 251 152</span></a> </li>*/}
            {/*        <li> <a href="mailto:shopinfo@gmail.com"><span><MailSVG /></span> <span>shopinfo@gmail.com</span></a>  </li>*/}
            {/*    </ul> *!/*/}

            {/*        <ul>*/}
            {/*            <li> © {date}  <FormattedMessage  id="copyright" defaultMessage="All Rights Reserved"/> </li>*/}
            {/*            <li>*/}
            {/*                <FormattedMessage id="developed.by" defaultMessage="Developed by" />*/}
            {/*                <a href="https://www.fidem.am/" target="_blank">*/}
            {/*                    Fidem LLC*/}
            {/*                </a>{" "}*/}
            {/*            </li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*) : (*/}
            <div className="site-footer">
                <div className="container_fm">
                    <div className="site-footer__widgets">
                        <div className="d-block d-md-none">
                            <div className="d-block d-md-block">
                                <FooterNewsletter/>
                            </div>
                        </div>
                        <div className="row_footer_fm">
                            <div className="col_block_fm">
                                <div className="site-footer__widget footer-contacts">
                                    <div className="logo_fm">
                                        {/* <img src="images/logos/partner_logo_74.png" alt="logo" />
                                                <img className="shop_fm" src="images/logos/shop.png" alt="logo" /> */}
                                        <LogoSvg/>
                                    </div>
                                    <div className="info_fm">
                                        <span>(+374) 41 373 373</span>
                                        <span>info@fooddepot.am</span>
                                    </div>
                                    {/* <div className="social_icon_fm">
                                    <img src="images/logos/fb.png" alt="fb" />
                                    <img src="images/logos/tw.png" alt="tw" />
                                    <img src="images/logos/in.png" alt="in" />
                                </div> */}
                                    <SocialLinks/>
                                </div>
                            </div>
                            <div className="col_block_fm">
                                <ul className="footer-contacts__contacts">{FilteredFooterContact.splice(0, 5)}</ul>
                                {/* <FooterLinks title="Information" /> */}
                            </div>
                            <div className="col_block_fm">
                                <ul className="footer-contacts__contacts">
                                    {FilteredFooterContact}
                                    {linksList}
                                </ul>
                                {/* <div> </div> */}
                                {/* <FooterLinks title="Information" /> */}
                            </div>
                            <div className="col_block_fm d-none d-md-block">
                                <FooterNewsletter/>
                            </div>
                        </div>
                    </div>

                    <div className="site-footer__bottom">

                        <div className="site-footer__copyright">
                            © {date} <FormattedMessage id="copyright" defaultMessage="All Rights Reserved"/>
                        </div>
                        <div className="site-footer__payment_icons">
                            {/*<div>*/}
                            {/*    <AmExpressSVG/>*/}
                            {/*</div>*/}

                            {/*<div>*/}
                            {/*    <PaypalSVG/>*/}
                            {/*</div>*/}
                            <div>
                                <VisaSVG/>
                            </div>
                            <div>
                                <MasterSVG/>
                            </div>
                            <div>
                                <img src={arca}/>
                            </div>
                            <div>
                                <IDramSVG/>
                            </div>
                        </div>

                        <div className="site-footer__developed_by">

                                <span>
                                    <FormattedMessage id="developed.by" defaultMessage="Developed by"/>
                                </span>

                            <a href="https://www.fidem.am/" target="_blank">
                                Fidem LLC
                            </a>{" "}
                        </div>
                    </div>
                </div>
            </div>
            {/*)}*/}
        </>
    )
}
