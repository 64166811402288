import {CATEGORY_NAME_ADD, CATEGORY_NAME_REMOVE, SET_CATEGORY_PRODUCTS} from './category-nameActionTypes';

const initialState = {
    name: "",
    products: []
}
export default function categoryPageReducer(state = initialState, action) {
    switch(action.type){
        case CATEGORY_NAME_ADD:
            return {
                ...state,
                name : action.payload
            }
        case CATEGORY_NAME_REMOVE:
            return {
            ...state,
            name : ""
        }
        case SET_CATEGORY_PRODUCTS:
            return {
                ...state,
                products : action.payload
            }
        default:
            return state;
    }
}
