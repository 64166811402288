// react
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {url} from '../../helper'


export default function FooterNewsletter() {

    const [email, Setemail] = useState();
    const [success, SetSuccess] = useState();
    const hnadlarChange = (event) => {

        Setemail(event.target.value)

    }

    const handlerClick = (event) => {

        event.preventDefault();

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {


            fetch(`${url}/api/subscribe`, {

                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({subscriber_email: email})
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        SetSuccess({message: data.success, class: 'text-success'})
                    } else {
                        SetSuccess({message: data.success, class: 'text-danger'})
                    }
                    setTimeout(() => {
                        SetSuccess({})
                    }, 3000);
                })
                .catch(error => console.error(error, 'error'))
        } else {

            SetSuccess({message: 'Invalid Email', class: 'text-danger'})

        }


    }
    return (
        <div className="site-footer__widget footer-newsletter">
            <div className="footer-newsletter__text">
                <FormattedMessage id="footer.subscribe.to" defaultMessage="Subscribe to our newsletter"/>
            </div>

            <form action="" className="footer-newsletter__form">
                <FormattedMessage id="footer.your.email" defaultMessage="Your email">
                    {placeholder => <input
                        onChange={hnadlarChange}
                        type="text"
                        className="footer-newsletter__form-input form-control"
                        id="footer-newsletter-address"
                        placeholder={placeholder}
                    />}
                </FormattedMessage>
                {success ? <div id="passwordHelp" className={success.class}>
                    {success.message}
                </div> : ""}
                <button type="submit" onClick={handlerClick} className="footer-newsletter__form-button">
                    <FormattedMessage id="global.submit" defaultMessage="Submit"/>
                </button>
            </form>
        </div>
    );
}
