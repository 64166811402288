// react
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {url} from '../../helper';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {FormattedMessage} from 'react-intl';

class BlogInnerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogData: null,
            topBlog: null,
            comment: null,
            customer: this.props.customer,
            locale: this.props.locale,
        };
    }

    handleChange = (e) => {
        const {value} = e.target;
        this.setState({comment: value});
        ;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const {comment} = this.state;

        let options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({comment: comment, token: this.props.customer.token})
        };

        fetch(`${url}/api/cms/comments/${this.props.blogId}/create`, options)
            .then(responce => responce.json())
            .then(res => {
                toast.success(res.message);
                this.setState({comment: ''});
                ;
            })
            .catch(err => console.error(err));
    };

    getData(id) {
        fetch(`${url}/api/cms/blogs?id=${id}&locale=${this.state.locale}`)
            .then(responce => responce.json())
            .then(res => {
                this.setState({blogData: res.data[0]});
            })
            .catch(err => console.error(err));

        fetch(`${url}/api/cms/blogs/top?limit=3&locale=${this.state.locale}`)
            .then(responce => responce.json())
            .then(res => {
                this.setState({topBlog: res.data});
            })
            .catch(err => console.error(err));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.blogId !== this.props.blogId) {
            this.getData(this.props.blogId)
        }
    }

    componentDidMount() {
        this.getData(this.props.blogId)
    }

    render() {
        const {blogData, topBlog, customer, comment} = this.state
        const {locale} = this.props
        if (blogData) {
            blogData.comments = blogData.comments.filter(e => e.status == "approved")
            blogData.comments.forEach((element, i) => {
                let date = new Date()
                let day = date.getDay(element.created_at).toString()
                let month = date.getMonth(element.created_at).toString()
                let getFullYear = date.getFullYear(element.created_at).toString()
                let getHours = date.getHours(element.created_at).toString()
                let getMinutes = date.getMinutes(element.created_at).toString()
                let customDate = `${day}.${month}.${getFullYear}, ${getHours}:${getMinutes}`
                Object.assign(element, {costomDate: customDate})
                blogData.comments[i] = element
            });
            let str = new Date(Date.parse(blogData.created_at)).toString()
            let arr = str.split(" ")
            let customDate = `${arr[1]} ${arr[2]}, ${arr[3]}`
            Object.assign(blogData, {costomDate: customDate})
        }

        if (topBlog) {
            topBlog.forEach((element, i) => {
                let str = new Date(Date.parse(element.created_at)).toString()
                let arr = str.split(" ")
                let customDate = `${arr[1]} ${arr[2]}, ${arr[3]}`
                Object.assign(element, {costomDate: customDate})
                topBlog[i] = element
            });
        }

        return (
            <React.Fragment>
                <div className='blog_inner_page_fm'>
                    <div className="container_fm">
                        {blogData ? <>
                            <div className="inner_block_fm">
                                <div className="inner_page_left_fm">
                                    <h1 className="inner_page_title_fm">
                                        {blogData.title}
                                    </h1>
                                    <div className="blog_date_fm">
                                        <span>
                                            {blogData.costomDate}
                                        </span>
                                        {/* <span className="inner_social_icon_fm">
                                        <img src="/images/icon/facebook.png" alt="" width="18px" />
                                        <img src="/images/icon/twitter.png" alt="" width="18px" />
                                    </span> */}
                                    </div>
                                    <div className="inner_page_img_fm"
                                         style={{backgroundImage: "url(" + blogData.image + ")"}}></div>
                                    <div className="inner_page_content_fm"
                                         dangerouslySetInnerHTML={{__html: blogData.html_content ? blogData.html_content : ''}}></div>
                                </div>
                                {blogData.related_blogs.length ?
                                    <div className="inner_page_right_fm">
                                        <div className="inner_page_right_title_fm"><FormattedMessage id="related"
                                                                                                     defaultMessage="Related"/>
                                        </div>
                                        <div className="inner_line_fm"></div>
                                        <div className="inner_page_related_fm">
                                            {blogData.related_blogs.map((e, i) => {
                                                return <Link to={`${locale}/blog/inner-page/${e.id}`} key={i}>
                                                    <div className="inner_page_related_item_fm">
                                                        <div className="img_fm"
                                                             style={{backgroundImage: "url(" + e.base_image + ")"}}></div>
                                                        <div className="content_title">
                                                            {e.title}
                                                        </div>
                                                    </div>
                                                </Link>
                                            })}
                                        </div>
                                    </div>
                                    : ''}

                            </div>
                        </> : ''}
                        <div className="blog_comment_fm">
                            {blogData?.comments.length ?
                                <div className="comment_right_fm">
                                    {blogData.comments.map((e, i) => {
                                        return <div key={i}>
                                            <div className="info_fm">
                                                <div className="name_fm">{e.name}</div>
                                                <div className="comment_date_fm">{e.costomDate}</div>
                                            </div>
                                            <div className="content_comment_fm">
                                                {e.comment}
                                            </div>
                                        </div>
                                    })}
                                </div>
                                : ''}
                            <form className="comment_left_fm" onSubmit={this.handleSubmit}>
                                {!customer.token ?
                                    <div className="add_com_blok">Please Login to leave a comment.</div> : ''}
                                <div className="comment_inp_fm">
                                    <FormattedMessage id="blog.add.comment" defaultMessage="Add a comment">
                                        {placeholder =>
                                            <textarea rows="3" placeholder={placeholder} defaultValue={comment}
                                                      onChange={this.handleChange}></textarea>

                                        }
                                    </FormattedMessage>
                                </div>
                                <div className="comment_butt_fm">
                                    <button>
                                        <FormattedMessage id="blog.add.comment" defaultMessage="Add comment"/>
                                    </button>
                                </div>
                            </form>

                        </div>
                        {topBlog ?
                            <div className="blog_page_fm">
                                <div className="top_fm">
                                    <FormattedMessage id="blog.top.headlines" defaultMessage="TOP HEADLINES"/>
                                </div>
                                <div className="blog_list_fm">
                                    {topBlog.map((e, i) => {
                                        return (
                                            <div className="blog_item_fm" key={i}>
                                                <Link to={`/${locale}/blog/inner-page/${e.id}`}>
                                                    <div className="blog_date_fm">{e.costomDate}</div>
                                                    <div className="blog_img_fm"
                                                         style={{backgroundImage: "url(" + e.image + ")"}}></div>
                                                    <div className="blog_content_fm">
                                                        {e.title}
                                                    </div>
                                                    <div className="read_more_fm">
                                                        <FormattedMessage id="blog.read.more"
                                                                          defaultMessage="Read more"/>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            : ''}
                    </div>

                </div>
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => ({
    customer: state.customer,
    locale: state.locale
});

export default connect(
    mapStateToProps
)(BlogInnerPage);
