import {
    SET_SEARCHED_WORD,
    SET_CAT_ID,
    SET_INITIAL_MAX_PRICE,
    SET_INITIAL_MIN_PRICE,
    SET_LAST_SCROLL_POSITION
} from './generalActionTypes';

const initialState = {
    serchedWord: null,
    catID: null,
    initialMaxPrice:null,
    initialMinPrice:null,
    lastScrollPosition: 0,
}

export default function wishlistReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SEARCHED_WORD:
            return {
                ...state,
                serchedWord: action.payload
            }
        case SET_CAT_ID:
            return {
                ...state,
                catID: action.payload
            }
        case SET_INITIAL_MAX_PRICE:
            return {
                ...state,
                initialMaxPrice: action.payload
            };
        case SET_INITIAL_MIN_PRICE:
            return {
                ...state,
                initialMinPrice: action.payload
            };
        case SET_LAST_SCROLL_POSITION:
            return {
                ...state,
                lastScrollPosition: action.payload
            };
        default:
            return state;
    }
}
