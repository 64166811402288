import {CATEGORY_NAME_ADD, CATEGORY_NAME_REMOVE, SET_CATEGORY_PRODUCTS} from './category-nameActionTypes';

export function addCategoryName(payload) {
    return {
        type: CATEGORY_NAME_ADD,
        payload,
    };
}

export function removeCategoryName(payload) {
    return {
        type: CATEGORY_NAME_REMOVE,
        payload,
    };
}

export function setCategoryProducts(payload) {
    return {
        type: SET_CATEGORY_PRODUCTS,
        payload,
    };
}


