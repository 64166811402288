// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Pagination from '../shared/Pagination';
import { Link } from 'react-router-dom';
import { url } from '../../helper';
import { FormattedMessage } from 'react-intl';



class BlogPageCategory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            blogData: null,
            locale: this.props.locale
        };
    }

    handlePageChange = (page) => {
        this.setState(() => ({ page }));
    };

    componentDidMount() {
        fetch(`${url}/api/cms/blogs?locale=${this.state.locale}`)
            .then(responce => responce.json())
            .then(res => {
                this.setState({ blogData: res.data });
            })
            .catch(err => console.error(err));

    }

    render() {
        const { locale } = this.props;
        const { blogData } = this.state
        if (blogData) {
            blogData.forEach((element, i) => {
                let str = new Date(Date.parse(element.created_at)).toString()
                let arr = str.split(" ")
                let customDate = `${arr[1]} ${arr[2]}, ${arr[3]}`
                Object.assign(element, { costomDate: customDate })
                blogData[i] = element
            });
        }

        return (
            <React.Fragment>
                <div className="blog_page_fm">
                    <div className="container_fm">
                        <div className="blog_title_fm">
                            <FormattedMessage id="blog.top.headlines"  defaultMessage="TOP HEADLINES" />
                        </div>
                        {blogData ?
                            <div className="blog_list_fm">
                                {blogData.map((e, i) => {
                                    return (
                                        <div className="blog_item_fm" key={i}>
                                            <Link to={`/${locale}/blog/inner-page/${e.id}`}>
                                                <div className="blog_date_fm">{e.costomDate}</div>
                                                <div className="blog_img_fm" style={{ backgroundImage: "url(" + e.image + ")" }}></div>
                                                <div className="blog_content_fm">
                                                    {e.title}
                                                </div>
                                                <div className="read_more_fm">
                                                <FormattedMessage id="blog.read.more"  defaultMessage="Read more" />
                                    </div>
                                            </Link>
                                        </div>
                                    )

                                })}
                            </div> : ''}
                        {/* <div className="posts-view__pagination">
                            <Pagination
                                current={page}
                                siblings={2}
                                total={10}
                                onPageChange={this.handlePageChange}
                            />
                        </div> */}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    locale: state.locale
});

export default connect(
    mapStateToProps
)(BlogPageCategory);
