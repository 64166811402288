// react
// application
import React, { useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'reactstrap';
import { AddUsersSVG, Cross20Svg, EditSVG, RemoveSVG } from '../../svg';
import { url } from '../../helper';
import { FormattedMessage } from 'react-intl';

// data stubs
import theme from '../../data/theme';

export default function CompanyUsersPage() {
    const customer = useSelector((state) => state.customer);
    const intialValues = {
        token: customer.token,
        name: '',
        email: '',
        password: '',
        position: '',
    };
    const [isEdit, setEdit] = useState(false);
    const [modal, SetModal] = useState(false);
    const [subUsers, setSubUsers] = useState();
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [getUsers, setgetUsers] = useState();
    useEffect(() => {
        const options = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };
        fetch(`${url}/api/company/user/get?token=${customer.token}`, options)
            .then((responce) => responce.json())
            .then((res) => {
                setSubUsers([...res.users]);
            })
            .catch((err) => console.error(err));
    }, [getUsers]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.name) {
            errors.name = 'This field is required';
        }

        if (!values.email) {
            errors.email = 'Invalid email format';
        } else if (!regex.test(values.email)) {
            errors.email = 'Invalid email format';
        }

        if (!values.password) {
            errors.password = 'This field is required';
        }

        if (!values.position) {
            errors.position = 'This field is required';
        }

        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);

    useEffect(() => {
        if (!modal) {
            setFormValues(intialValues);
            setFormErrors({});
        }
    }, [modal]);

    const submitForm = () => {
        if (intialValues.email || formValues.email) {
            let options = {
                method: isEdit ? 'PUT' : 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: isEdit
                    ? JSON.stringify({ ...formValues, id: formValues.id, token: customer.token })
                    : JSON.stringify(formValues),
            };
            const customUrl = isEdit ? '/api/company/user/edit' : '/api/company/user/add';

            fetch(`${url}${customUrl}`, options)
                .then((responce) => responce.json())
                .then((res) => {
                    if (res.success) {
                        if (!isEdit) {
                            delete formValues['token'];
                            setSubUsers([{ ...formValues, public_password: formValues.password }, ...subUsers]);
                            toast.success(
                                <FormattedMessage
                                    id="email.verification"
                                    defaultMessage="Company user was successfully created"
                                />
                            );
                            setgetUsers(true);
                        } else {
                            delete formValues.token;
                            const updatedUsers = subUsers.map((e) => {
                                if (e.id === formValues.id) {
                                    return {
                                        ...formValues,
                                        public_password: formValues.password,
                                    };
                                } else {
                                    return e;
                                }
                            });
                            setSubUsers(updatedUsers);
                            toast.success(
                                <FormattedMessage
                                    id="email.update"
                                    defaultMessage="Company user was successfully updated"
                                />
                            );
                        }

                        modalFunc();
                    } else {
                        if (typeof res === 'object') {
                            const object = Object.keys(res).reduce((prev, next) => {
                                return {
                                    ...prev,
                                    [next]: res[next][0],
                                };
                            }, {});
                            ///setFormErrors({ ...object })
                        }
                    }
                })
                .catch((err) => console.error(err));
        }
    };

    const modalFunc = () => {
        SetModal(!modal);
    };
    const usersList = subUsers
        ? subUsers.map(({ id, name, position, email, public_password }) => (
            <tr key={id}>
                <td>{name}</td>
                <td className="order_satus_fm">{position}</td>
                <td>{email}</td>
                <td>{ public_password }</td>
                <td>
                    <div className="users-funcButton_fm">
                        <span>
                            <EditSVG onClick={() => deleteUser(id)} />
                        </span>
                        <span>
                            <RemoveSVG
                                onClick={() => {
                                    setEdit(true);
                                    editUser(id);
                                }}
                            />
                        </span>
                    </div>
                </td>
            </tr>
        ))
        :
        '';

    function deleteUser(id) {
        fetch(`${url}/api/company/user/delete`, {
            method: 'delete',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id : id, token: customer.token }),
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.success === 'ok') {
                    setSubUsers([...subUsers.filter((e) => e.id !== id)]);
                    toast.success(
                        <FormattedMessage id="email.delete" defaultMessage="Company user was successfully deleted" />
                    );
                }
            });
    }

    function editUser(id) {
        const object = subUsers.filter((e) => e.id === id)[0];
        object.password = object.public_password;
        setFormValues({ ...object, id });
        modalFunc();
    }
    // console.log(formErrors.email,'FORMIKI EMAILY');
    return (
        <div className="card">
            <Modal className="account_modal_fm" isOpen={modal} centered size="xl">
                <div className="quickview">
                    <button
                        className="quickview__close"
                        type="button"
                        onClick={() => {
                            if (isEdit) {
                                setEdit(false);
                            }
                            modalFunc();
                        }}
                    >
                        <Cross20Svg />
                    </button>
                    <form action="" onSubmit={handleSubmit}>
                        <div className="title">
                            <FormattedMessage
                                id="account.editAccountInformation"
                                defaultMessage="Edit account information"
                            />
                        </div>
                        <div>
                            <FormattedMessage id="global.name" defaultMessage="Name">
                                {(placeholder) => (
                                    <input
                                        type="text"
                                        placeholder={placeholder}
                                        name="name"
                                        id="name"
                                        value={formValues.name}
                                        onChange={handleChange}
                                    />
                                )}
                            </FormattedMessage>
                        </div>
                        <div>
                            {formErrors.name && (
                                <span className="error">
                                    <FormattedMessage id="account.error.required" defaultMessage={formErrors.name} />
                                </span>
                            )}
                        </div>
                        <div>
                            <FormattedMessage id="global.email" defaultMessage="Email">
                                {(placeholder) => (
                                    <input
                                        type="text"
                                        placeholder={placeholder}
                                        name="email"
                                        id="email"
                                        value={formValues.email}
                                        onChange={handleChange}
                                    />
                                )}
                            </FormattedMessage>
                        </div>
                        <div>
                            {(formErrors.email && (
                                <span className="error">
                                    <FormattedMessage
                                        id="account.error.required"
                                        defaultMessage={formErrors.email}
                                    />
                                </span>
                            ))}
                        </div>
                        <div>
                            <FormattedMessage id="users.position" defaultMessage="Position">
                                {(placeholder) => (
                                    <input
                                        type="text"
                                        placeholder={placeholder}
                                        name="position"
                                        id="position"
                                        value={formValues.position}
                                        onChange={handleChange}
                                    />
                                )}
                            </FormattedMessage>
                        </div>
                        <div>
                            {formErrors.position && (
                                <span className="error">
                                    <FormattedMessage
                                        id="account.error.required"
                                        defaultMessage={formErrors.position}
                                    />
                                </span>
                            )}
                        </div>
                        <div>
                            <FormattedMessage id="global.Password" defaultMessage="Password">
                                {(placeholder) => (
                                    <input
                                        type="text"
                                        placeholder={placeholder}
                                        id="password"
                                        name="password"
                                        value={formValues.password}
                                        onChange={handleChange}
                                    />
                                )}
                            </FormattedMessage>
                        </div>
                        <div>
                            {formErrors.password && (
                                <span className="error">
                                    <FormattedMessage
                                        id="account.error.required"
                                        defaultMessage={formErrors.password}
                                    />
                                </span>
                            )}
                        </div>
                        <div className="butt_block_fm">
                            <div
                                className="cancel_modal_fm cursor_fm"
                                onClick={() => {
                                    modalFunc();
                                }}
                            >
                                <FormattedMessage id="account.cancel" defaultMessage="Cancel" />
                            </div>
                            <div className="change_modal_fm">
                                <button>
                                    {isEdit ? (
                                        <FormattedMessage id="account.save" defaultMessage="Save" />
                                    ) : (
                                        <FormattedMessage id="account.create" defaultMessage="Create" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Helmet>
                <title>{`Company Users — ${theme.name}`}</title>
            </Helmet>

            <div className="card-table d-md-block">
                <div className="table-responsive-sm">
                    {usersList ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="global.name" defaultMessage="Name" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="users.position" defaultMessage="Position" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="global.email" defaultMessage="Email" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="global.Password" defaultMessage="Password" />
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>{usersList}</tbody>
                        </table>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div className="card-divider" />
            <div className="card-footer">
                <div className="add-users_fm">
                    <span>
                        <AddUsersSVG />
                    </span>
                    <span
                        onClick={() => {
                            setEdit(false);
                            modalFunc();
                        }}
                    >
                        <FormattedMessage id="AddNewUser" defaultMessage="Add new user" />
                    </span>
                </div>
            </div>
        </div>
    );
}
