import React from 'react';

import {useState} from 'react';
import {useEffect} from 'react';
import {url} from '../../helper';
import {FormattedMessage} from 'react-intl';
import {toast} from 'react-toastify';
import {LogoSvg} from '../../svg';
import {useSelector} from "react-redux";


export default function RegisterPhys(props) {

    const intialValues = {
        company_name: '',
        address: '',
        tin: '',
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        phone: '',
        password_confirmation: ''
    };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const locale = useSelector(state => state.locale)
    const submitForm = () => {
        let options = {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formValues)
        };

        fetch(`${url}/api/company/register?locale=${locale}`, options)

            .then(responce => responce.json())
            .then(res => {
                if (res.success) {
                    toast.success(<FormattedMessage id="company_registered"
                                                    defaultMessage="`Thank you for registration, we have sent you an email for verification.`"/>)
                    props.clicked('login', true);
                    props.close('sign', false)
                } else {
                    const keys = Object.keys(res);
                    keys.forEach(key => res[key] = res[key][0])
                    setFormErrors(res)
                }
            })
            .catch(err => console.error(err));
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    };

    const handleKeyPress = (event) => {
        const key = event.key;
        const value = event.target.value + key;
        const regex = /^[+]?\d*$/

        if (!regex.test(value)) {
            event.preventDefault();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.company_name) {
            errors.company_name = "This field is required";
        }

        if (!values.address) {
            errors.address = "This field is required";
        }

        if (!values.tin) {
            errors.tin = "This field is required";
        }

        if (!values.first_name) {
            errors.first_name = "This field is required";
        }

        if (!values.last_name) {
            errors.last_name = "This field is required";
        }

        if (!values.phone) {
            errors.phone = "This field is required";
        }

        if (!values.password_confirmation || values.password != values.password_confirmation) {
            errors.password_confirmation = "Passwords do not match";
        }

        if (!values.email || !regex.test(values.email)) {
            errors.email = "Invalid email format";
        }

        if (!values.password || values.password.length < 6) {
            errors.password = "Password must be more than 6 characters";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);

    return (
        <div className="login_content_fm">
            {/* <img className="sh_fm" src="images/logos/sh.png" alt="sh" /> */}
            <div className='new_logo_fm'>
                <LogoSvg/>
            </div>
            <div className="logn_title_fm"><FormattedMessage id="reg.letsGetStarted"
                                                             defaultMessage="Let's Get Started!"/></div>
            <div className="choose_fm">
                <label className="radio_fm" onClick={() => {
                    props.close('sign', false);
                    props.clicked('reg', true)
                }}>
                    <FormattedMessage id="reg.phys" defaultMessage="Ֆիզ Անձ"/>
                    <input type="radio" name="radio"/>
                    <span className="checkmark"></span>
                </label>
                <label className="radio_fm"><FormattedMessage id="reg.loy" defaultMessage="Իրավաբանական Անձ"/>
                    <input type="radio" name="radio" checked={props.sign} readOnly={props.sign}/>
                    <span className="checkmark"></span>
                </label>
            </div>
            <form action="" className="log_ss_fm" onSubmit={handleSubmit}>
                <div className={formErrors.company_name && "input-error_fm"}>
                    <FormattedMessage id="reg.company" defaultMessage="Company name">
                        {placeholder =>
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="text"
                                name="company_name"
                                value={formValues.company_name}
                                onChange={handleChange}
                            />
                        }
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.company_name && (
                        <span className="error">
                            {
                                formErrors.company_name !== "This field is required" ? formErrors.company_name :
                                    <FormattedMessage id="account.error.required"
                                                      defaultMessage={formErrors.company_name}/>
                            }
                        </span>
                    )}
                </div>
                <div className={formErrors.tin && "input-error_fm"}>
                    <FormattedMessage id="reg.tin" defaultMessage="TIN">
                        {placeholder =>
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="text"
                                name="tin"
                                value={formValues.tin}
                                onChange={handleChange}
                            />
                        }
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.tin && (
                        <span className="error">
                            {
                                formErrors.tin !== "This field is required" ? formErrors.tin :
                                    <FormattedMessage id="account.error.required" defaultMessage={formErrors.tin}/>
                            }
                        </span>
                    )}
                </div>
                <div className={formErrors.address && "input-error_fm"}>
                    <FormattedMessage id="global.address" defaultMessage="Address">
                        {placeholder =>
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="text"
                                name="address"
                                value={formValues.address}
                                onChange={handleChange}
                            />

                        }
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.address && (
                        <span className="error">
                            {
                                formErrors.address !== "This field is required" ? formErrors.address :
                                    <FormattedMessage id="account.error.required" defaultMessage={formErrors.address}/>
                            }
                        </span>
                    )}
                </div>
                <div className={formErrors.first_name && "input-error_fm"}>
                    <FormattedMessage id="ceo_firstname" defaultMessage="Firstname">
                        {placeholder =>
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="text"
                                name="first_name"
                                value={formValues.first_name}
                                onChange={handleChange}
                            />

                        }
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.first_name && (
                        <span className="error">
                            {
                                formErrors.first_name !== "This field is required" ? formErrors.first_name :
                                    <FormattedMessage id="account.error.required"
                                                      defaultMessage={formErrors.first_name}/>
                            }
                        </span>
                    )}
                </div>
                <div className={formErrors.last_name && "input-error_fm"}>
                    <FormattedMessage id="ceo_lastname" defaultMessage="Lastname">
                        {placeholder =>
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="text"
                                name="last_name"
                                value={formValues.last_name}
                                onChange={handleChange}
                            />

                        }
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.last_name && (
                        <span className="error">
                            {
                                formErrors.last_name !== "This field is required" ? formErrors.last_name :
                                    <FormattedMessage id="account.error.required"
                                                      defaultMessage={formErrors.last_name}/>
                            }
                        </span>
                    )}
                </div>
                <div className={formErrors.email && "input-error_fm"}>
                    <FormattedMessage id="global.email" defaultMessage="Email">
                        {placeholder =>
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="text"
                                name="email"
                                value={formValues.email}
                                onChange={handleChange}
                            />

                        }
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.email && (
                        <span className="error">
                            {
                                formErrors.email !== "This field is required" ? formErrors.email :
                                    <FormattedMessage id="account.error.required" defaultMessage={formErrors.email}/>
                            }
                        </span>
                    )}
                </div>
                <div className={formErrors.phone && "input-error_fm"}>
                    <FormattedMessage id="reg.phone" defaultMessage="Phone">
                        {placeholder =>
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                // type="text"
                                type="tel"
                                name="phone"
                                value={formValues.phone}
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                                onTouchStart={handleKeyPress}
                            />

                        }
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.phone && (
                        <span className="error">
                            {
                                formErrors.phone !== "This field is required" ? formErrors.phone :
                                    <FormattedMessage id="account.error.required" defaultMessage={formErrors.phone}/>
                            }
                        </span>
                    )}
                </div>
                <div className={formErrors.password && "input-error_fm"}>
                    <FormattedMessage id="account.password" defaultMessage="Password">
                        {placeholder =>
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="password"
                                name="password"
                                value={formValues.password}
                                onChange={handleChange}
                            />
                        }
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.password && (
                        <span className="error"><FormattedMessage id="account.error.password"
                                                                  defaultMessage={formErrors.password}/></span>
                    )}
                </div>
                <div className={formErrors.password_confirmation && "input-error_fm"}>
                    <FormattedMessage id="reg.confirm.password" defaultMessage="Confirm Password">
                        {placeholder =>
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="password"
                                name="password_confirmation"
                                value={formValues.password_confirmation}
                                onChange={handleChange}
                            />

                        }
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.password_confirmation && (
                        <span className="error"><FormattedMessage id="account.error.passwordConfirmation"
                                                                  defaultMessage={formErrors.password_confirmation}/></span>
                    )}
                </div>
                <button><FormattedMessage id="reg.sign.up" defaultMessage="SIGN UP"/></button>
            </form>
            <div className="sign_up_fm">
                <div className="singup_link_fm" onClick={() => {
                    props.clicked('login', true);
                    props.close('sign', false)
                }}>
                    <FormattedMessage id="reg.alreadyHaveAnAccount" defaultMessage="Already have an account?"/>
                    <span> <FormattedMessage id="reg.log.in" defaultMessage="Log In"/></span>
                </div>
            </div>
        </div>
    );
}
