// react
import React, {useEffect, useState} from "react";

// third-party
import PropTypes from "prop-types";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {ToastContainer} from "react-toastify";

// application
import Footer from "./footer";
import Header from "./header";
import Quickview from "./shared/Quickview";

// pages
import AccountLayout from "./account/AccountLayout";
import AccountPageLogin from "./account/AccountPageLogin";
import BlogPageCategory from "./blog/BlogPageCategory";
import BlogPagePost from "./blog/BlogPagePost";
import BlogInnerPage from "./blog/BlogInnerPage";
import PageCart from "./shop/ShopPageCart";
import PageCheckout from "./shop/ShopPageCheckout";
import PageCompare from "./shop/ShopPageCompare";
import PageWishlist from "./shop/ShopPageWishlist";
import ShopPageCategory from "./shop/ShopPageCategory";
import ShopPageCategoryBySearch from "./shop/ShopPageCategoryBySearch";
import ShopPageOrderSuccess from "./shop/ShopPageOrderSuccess";
import ShopPageTrackOrder from "./shop/ShopPageTrackOrder";
import SitePageAboutUs from "./site/SitePageAboutUs";
import SitePageComponents from "./site/SitePageComponents";
import SitePageContactUs from "./site/SitePageContactUs";
import SitePageContactUsAlt from "./site/SitePageContactUsAlt";
import SitePageFaq from "./site/SitePageFaq";
import SitePageNotFound from "./site/SitePageNotFound";
import SitePageTerms from "./site/SitePageTerms";
import SitePageTypography from "./site/SitePageTypography";
import AccountPageOrderDetails from "./account/AccountPageOrderDetails";
import ShopCategory from "./shop/ShopCategory";
import ProductsList from "./shop/ProductsList";
import Brands from "./shop/Brands";
import MenuPage from "./shop/MenuPage";

// data stubs
import {useDispatch, useSelector} from "react-redux";
import {url} from "../helper";
import {addCartToken} from "../store/cart";
import jwt_decode from "jwt-decode";
import ThanksPage from "./site/ThanksPage";
import SiteCustomPage from "./site/SiteCustomPage";
import ContactWithUs from "./footer/ContactWithUs";
import ThankForMessagePage from "./site/ThankForMessagePage";
import ShopPageMainCategory from "./shop/ShopPageMainCategory";
import HomePageTwo from "./home/HomePageTwo";
import {useLocation} from "react-router-dom/cjs/react-router-dom.min";
import BlockLoader from "./blocks/BlockLoader";
import ProductInner from "./Product_Inner/ProductInner";
import {languages} from "./utils";
import {getCartDataMount} from "../store/cart";

const categoryLayouts = [
    ["/shop/category-grid-3-columns-sidebar", {columns: 3, viewMode: "grid", sidebarPosition: "start"}],
    ["/shop/category-grid-4-columns-full", {columns: 4, viewMode: "grid"}],
    ["/shop/category-grid-5-columns-full", {columns: 5, viewMode: "grid"}],
    ["/shop/category-list", {columns: 3, viewMode: "list", sidebarPosition: "start"}],
    ["/shop/category-right-sidebar", {columns: 3, viewMode: "grid", sidebarPosition: "end"}],
].map(([url, options]) => (
    <Route
        key={url}
        exact
        path={url}
        render={(props) => <ShopPageCategory {...props} {...options} categorySlug="power-tools"/>}
    />
));

// const productLayouts = [
//     ["/shop/product-standard", {layout: "standard"}],
//     ["/shop/product-columnar", {layout: "columnar"}],
//     ["/shop/product-sidebar", {layout: "sidebar"}],
// ].map(([url, options]) => (
//     <Route
//         key={url}
//         exact
//         path={url}
//         render={(props) => <ShopPageProduct {...props} {...options} productSlug="brandix-screwdriver-screw1500acc"/>}
//     />
// ));

function Layout(props) {
    const dispatch = useDispatch();

    const login = useSelector((state) => state.login.login);
    const locale = useSelector((state) => state.locale);
    const state = useSelector((state) => state);
    const {match, headerLayout, homeComponent} = props;
    const customer = useSelector((state) => state.customer);
    const wishList = useSelector((state) => state.wishlist);
    const cart = useSelector((state) => state.cart);
    const [closed, setClosed] = useState(false)
    const [timer, setTimer] = useState(false)
    const location = useLocation()
    const history = useHistory();

    const wishlist_routes = customer.token ?
        [
            <Redirect key="redirect1" exact from="/shop/wishlist"
                      to={`/${locale}/shop/wishlist`}/>,
            <Route exact path="/:locale/shop/wishlist" component={PageWishlist}/>
        ]
        :
        [
            <Redirect key="redirect2" exact from="/shop/wishlist" to="/"/>,
            <Redirect key="redirect3" exact from="/:locale/shop/wishlist" to="/"/>
        ]

    // const account_routes = customer.token ?
    //     [
    //         <Redirect key="redirect4" exact from="/account" to={`/${locale}/account`}/>,
    //         <Route path="/:locale/account" component={AccountLayout}/>
    //     ]
    //     :
    //     [
    //         <Redirect key="redirect5" exact from="/account" to="/"/>,
    //         <Redirect key="redirect6" exact from="/:locale/account" to="/"/>
    //     ]


    useEffect(() => {
        if (cart.items?.length === 0 && history.location.pathname === "/shop/cart") {
            localStorage.removeItem("api_token");
        }
    }, [cart.items?.length])

    useEffect(() => {

        const timerID = setTimeout(() => {
            setTimer(true)
        }, 3500)

        return () => {
            clearTimeout(timerID)
        }
    }, [])

    // useEffect(() => {
    //     console.log(location, "aaa");
    //     const isAdult = sessionStorage.getItem("addAgeAnswer")

    //     console.log(isAdult, typeof Boolean(isAdult), 'aaaaa');

    //     dispatch(addAgeAnswer(Boolean(isAdult)))

    // }, [location])


    useEffect(() => {
        if (!localStorage.getItem("api_token")) {
            fetch(`${url}/api/checkout/cart/token`)
                .then((responce) => responce.json())
                .then((res) => {
                    localStorage.setItem("api_token", res.api_token)
                });
        }
    }, [localStorage.getItem("api_token")]);

    useEffect(() => {
        dispatch(getCartDataMount())
    }, [localStorage.getItem("api_token")]);

    useEffect(() => {
        if (customer.token) {
            const {exp} = jwt_decode(customer.token);

            if (Date.now() >= exp * 1000) {
                dispatch({type: "AUTHENTICATED", payload: false});
                dispatch({type: "CUSTOMER_TOKEN", payload: ""});
            }
        }
    }, [customer.token]);

    useEffect(() => {
        if (customer.token)
            fetch(`${url}/api/wishlist?token=${customer.token}`)
                .then((responce) => responce.json())
                .then((res) => {
                    if (res.data.length) {
                        let arr = [];
                        res.data.forEach((element) => {
                            arr.push(element.product);
                        });
                        dispatch({type: "WISH_LIST", payload: arr});
                    } else {
                        dispatch({type: "WISH_LIST", payload: []});
                    }
                });
        else {
            dispatch({type: "WISH_LIST", payload: []});
        }
    }, [customer.token]);

    return (
        <React.Fragment>
            <ToastContainer autoClose={5000} hideProgressBar/>
            <Quickview/>
            {
                !timer ? <BlockLoader/> : <div className="site">
                    {/*{location.pathname !== "/" &&*/}
                    <header>
                        <Header layout={headerLayout}/>
                    </header>
                    {/*}*/}


                    <div className="site__body">
                        <Switch>
                            {/*------------------------------Home------------------------------*/}
                            <Route
                                exact
                                path="/"
                                render={(props) => (
                                    <HomePageTwo {...props} columns={3} viewMode="grid" sidebarPosition="start"/>
                                )}
                            />
                            <Route
                                exact
                                path="/:locale"
                                render={(props) => {
                                    if (!languages.find((x) => x.locale === props.match.params.locale)) {
                                        return <SitePageNotFound/>
                                    }
                                    return <HomePageTwo {...props} columns={3} viewMode="grid" sidebarPosition="start"/>
                                }}
                            />

                            {/*------------------------------Shop------------------------------*/}
                            {/*<Route*/}
                            {/*    exact*/}
                            {/*    path="/shop/main/catalog"*/}
                            {/*    render={(props) => {*/}
                            {/*        return <Redirect exact to={{*/}
                            {/*            pathname: `/${locale}/shop/main/catalog`,*/}
                            {/*            search: props.location.search,*/}
                            {/*        }}/>*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <Route
                                exact
                                path="/:locale/shop/main/catalog"
                                render={(props) => (
                                    <ShopPageCategory {...props} columns={3} viewMode="grid"
                                                      sidebarPosition="start"/>
                                )}
                            />

                            {/*<Route*/}
                            {/*    exact*/}
                            {/*    path="/shop/main/catalog/search"*/}
                            {/*    render={(props) => {*/}
                            {/*        return <Redirect exact to={{*/}
                            {/*            pathname: `/${locale}/shop/main/catalog/search`,*/}
                            {/*            search: props.location.search,*/}
                            {/*        }}/>*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <Route
                                exact
                                path="/:locale/shop/main/catalog/search"
                                render={(props) => {
                                    return <ShopPageCategoryBySearch {...props} columns={3} viewMode="grid"
                                                                     sidebarPosition="start"/>
                                }}
                            />

                            {/*<Redirect exact from="/shop" to={`/${locale}/shop/catalog`}/>*/}
                            {/*<Route*/}
                            {/*    exact*/}
                            {/*    path="/shop/catalog/"*/}
                            {/*    render={(props) => {*/}
                            {/*        return <Redirect exact to={{*/}
                            {/*            pathname: `/${locale}/shop/catalog/`,*/}
                            {/*            search: props.location.search,*/}
                            {/*        }}/>*/}
                            {/*    }}*/}
                            {/*/>*/}

                            <Route
                                exact
                                path="/:locale/shop/catalog/"
                                render={(props) => {
                                    return <ShopPageMainCategory
                                        {...props}
                                        categorySlug={props.match.params.categorySlug}
                                        catID={props.match.params.catID}
                                    />
                                }}
                            />

                            {
                                customer.token ?
                                    <Route exact path="/:locale/shop/wishlist" component={PageWishlist}/>
                                    :
                                    <Redirect key="redirect3" exact from="/:locale/shop/wishlist" to="/"/>
                            }

                            {/*<Redirect exact from="/category" to={`/${locale}/category`}/>*/}
                            {/*<Route exact path="/:locale/category" component={ShopCategory}/>*/}

                            {/*<Redirect exact from="/shop/cart" to={`/${locale}/shop/cart`}/>*/}
                            <Route exact path="/:locale/shop/cart" component={PageCart}/>

                            {/*<Redirect exact from="/shop/checkout" to={`/${locale}/shop/checkout`}/>*/}
                            <Route exact path="/:locale/shop/checkout" component={PageCheckout}/>

                            {/*<Redirect exact from="/shop/checkout/success" to={`/${locale}/shop/checkout/success`}/>*/}
                            <Route exact path="/:locale/shop/checkout/success" component={ShopPageOrderSuccess}/>

                            {/*<Redirect exact from="/shop/compare" to={`/${locale}/shop/compare`}/>*/}
                            <Route exact path="/:locale/shop/compare" component={PageCompare}/>

                            {/*<Redirect exact from="/shop/track-order" to={`/${locale}/shop/track-order`}/>*/}
                            <Route exact path="/:locale/shop/track-order" component={ShopPageTrackOrder}/>

                            {/* I can't find where is this route working  */}
                            {/*<Redirect exact from="/shop/catalog/:categorySlug"*/}
                            {/*          to={`/${locale}/shop/catalog/:categorySlug`}/>*/}
                            {/*<Route*/}
                            {/*    exact*/}
                            {/*    path="/:locale/shop/catalog/:categorySlug"*/}
                            {/*    render={(props) => (*/}
                            {/*        <ShopPageCategory*/}
                            {/*            {...props}*/}
                            {/*            columns={3}*/}
                            {/*            viewMode="grid"*/}
                            {/*            sidebarPosition="start"*/}
                            {/*            categorySlug={props.match.params.categorySlug}*/}
                            {/*            catID={props.match.params.catID}*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {/*/>*/}

                            {/*{categoryLayouts}*/}

                            {/*------------------------------Products------------------------------*/}
                            <Redirect exact from="/shop/products/:url_key"
                                      to={`/${locale}/shop/products/:url_key`}/>
                            <Route
                                exact
                                path="/:locale/shop/products/:url_key"
                                render={(props) => {
                                    return <ProductInner
                                        {...props}
                                        layout="standard"
                                        url_key={props.match.params.url_key}
                                    />
                                }}
                            />

                            {/*<Redirect exact from="/product-list" to={`/${locale}/product-list`}/>*/}
                            {/*<Route exact path="/:locale/product-list" component={ProductsList}/>*/}

                            {/*<Route*/}
                            {/*    exact*/}
                            {/*    path="/product-list/:catId/:catName"*/}
                            {/*    render={(props) => {*/}
                            {/*        return <Redirect exact to={{*/}
                            {/*            pathname: `/${locale}/product-list/:catId/:catName`,*/}
                            {/*            search: new URLSearchParams(props.location.search).toString(),*/}
                            {/*        }}/>*/}
                            {/*    }}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    exact*/}
                            {/*    path="/:locale/product-list/:catId/:catName"*/}
                            {/*    render={(props) => (*/}
                            {/*        <ProductsList*/}
                            {/*            {...props}*/}
                            {/*            layout="standard"*/}
                            {/*            catId={props.match.params.catId}*/}
                            {/*            catName={props.match.params.catName}*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {/*/>*/}

                            {/*------------------------------Footer------------------------------*/}
                            {/*<Redirect exact from="/contact-us" to={`/${locale}/contact-us`}/>*/}
                            {/*<Route exact path="/:locale/contact-us" render={(props) => <ContactWithUs {...props} />}/>*/}

                            {/*------------------------------Blogs------------------------------*/}
                            <Redirect exact from="/:locale/blog" to={`/${locale}/blog/category-classic`}/>,
                            <Route
                                exact
                                path="/:locale/blog/category-classic"
                                render={(props) => {
                                    return <BlogPageCategory {...props} layout="classic"
                                                             sidebarPosition="end"/>
                                }}
                            />

                            {/*<Redirect exact from="/category-grid" to={`/${locale}/blog/category-grid`}/>*/}
                            <Route
                                exact
                                path="/:locale/blog/category-grid"
                                render={(props) => <BlogPageCategory {...props} layout="grid"
                                                                     sidebarPosition="end"/>}
                            />

                            {/*<Redirect exact from="/blog/category-list" to={`/${locale}/blog/category-list`}/>*/}
                            <Route
                                exact
                                path="/:locale/blog/category-list"
                                render={(props) => <BlogPageCategory {...props} layout="list"
                                                                     sidebarPosition="end"/>}
                            />

                            {/*<Redirect exact from="/blog/inner-page/:blogId" to={`/${locale}/blog/inner-page/:blogId`}/>*/}
                            <Route
                                exact
                                path="/:locale/blog/inner-page/:blogId"
                                render={(props) => (
                                    <BlogInnerPage {...props} layout="standard" blogId={props.match.params.blogId}/>
                                )}
                            />

                            {/*<Redirect exact from="/blog/category-left-sidebar"*/}
                            {/*          to={`/${locale}/blog/category-left-sidebar`}/>*/}
                            <Route
                                exact
                                path="/:locale/blog/category-left-sidebar"
                                render={(props) => <BlogPageCategory {...props} layout="classic"
                                                                     sidebarPosition="start"/>}
                            />

                            {/*<Redirect exact from="/blog/post-classic" to={`/${locale}/blog/post-classic`}/>*/}
                            <Route
                                exact
                                path="/:locale/blog/post-classic"
                                render={(props) => <BlogPagePost {...props} layout="classic"
                                                                 sidebarPosition="end"/>}
                            />

                            {/*<Redirect exact from="/blog/post-full" to={`/${locale}/blog/post-full`}/>*/}
                            <Route
                                exact
                                path="/:locale/blog/post-full"
                                render={(props) => <BlogPagePost {...props} layout="full"/>}
                            />

                            {/*------------------------------Account------------------------------*/}
                            {/*<Redirect exact from="/account/login" to={`/${locale}/account/login`}/>*/}
                            <Route exact path="/:locale/account/login" component={AccountPageLogin}/>

                            {/*<Redirect exact from="/orderDetile/:orderId" to={`/${locale}/orderDetile/:orderId`}/>*/}
                            <Route
                                exact
                                path="/:locale/orderDetile/:orderId"
                                render={(props) => (
                                    <AccountPageOrderDetails
                                        {...props}
                                        layout="standard"
                                        orderId={props.match.params.orderId}
                                    />
                                )}
                            />

                            {
                                customer.token ?
                                    <Route path="/:locale/account" component={AccountLayout}/>
                                    :
                                    <Redirect exact from="/:locale/account" to="/"/>
                            }

                            {/*------------------------------Site------------------------------*/}
                            {/*<Redirect exact from="/site" to={`/${locale}/site/about-us`}/>*/}
                            {/*<Redirect exact from="/:locale/site" to={`/${locale}/site/about-us`}/>*/}
                            {/*<Redirect exact from="/site/about-us" to={`/${locale}/site/about-us`}/>*/}
                            <Route exact path="/:locale/site/about-us" component={SitePageAboutUs}/>


                            {/*<Redirect exact from="/site/components" to={`/${locale}/site/components`}/>*/}
                            <Route exact path="/:locale/site/components" component={SitePageComponents}/>


                            {/*<Redirect exact from="/site/contact-us" to={`/${locale}/site/contact-us`}/>*/}
                            <Route exact path="/:locale/site/contact-us"
                                   render={(props) => <ContactWithUs {...props}/>}/>


                            {/*<Redirect exact from="/site/contact-us-alt" to={`/${locale}/site/contact-us-alt`}/>*/}
                            <Route exact path="/:locale/site/contact-us-alt" component={SitePageContactUsAlt}/>


                            {/*<Redirect exact from="/site/not-found" to={`/${locale}/site/not-found`}/>*/}
                            <Route exact path="/:locale/site/not-found" component={SitePageNotFound}/>

                            {/*<Redirect exact from="/site/faq" to={`/${locale}/site/faq`}/>*/}
                            <Route exact path="/:locale/site/faq" component={SitePageFaq}/>

                            {/*<Redirect exact from="/site/terms" to={`/${locale}/site/terms`}/>*/}
                            <Route exact path="/:locale/site/terms" component={SitePageTerms}/>

                            {/*<Redirect exact from="/site/typography" to={`/${locale}/site/typography`}/>*/}
                            <Route exact path="/:locale/site/typography" component={SitePageTypography}/>

                            {/*------------------------------Other------------------------------*/}
                            {/*<Redirect exact from="/page/:pageID" to={`/${locale}/page/:pageID`}/>*/}
                            <Route
                                exact
                                path="/:locale/page/:pageID"
                                render={(props) => {
                                    return <SiteCustomPage {...props} id={props.match.params.pageID}/>
                                }}
                            />


                            <Route exact path="/:locale/brands" component={Brands}/>

                            <Route path="/:locale/thanksforMessage" {...props} component={ThankForMessagePage}/>

                            <Route
                                exact
                                path="/thanks"
                                render={(props) => {
                                    return <Redirect exact to={{
                                        pathname: `/${locale}/thanks`,
                                        search: new URLSearchParams(props.location.search).toString(),
                                    }}/>
                                }}
                            />
                            <Route path="/:locale/thanks" {...props} component={ThanksPage}/>

                            {/*------------------------------Page Not Found------------------------------*/}
                            <Route component={SitePageNotFound}/>


                            {/*<Route exact path={"/"} component={homeComponent} />*/}

                            {/*<Route path={*/}
                            {/*    // `${match.path*/}
                            {/*    // }`*/}
                            {/*    `${"/demo"*/}
                            {/*    }`*/}
                            {/*}*/}
                            {/*    render={(props) => (*/}
                            {/*        <HomePageTwo {...props} columns={3} viewMode="grid" sidebarPosition="start" />*/}
                            {/*    )} />*/}

                            {/* Following category layouts only for demonstration. */}

                            {/*<Route*/}
                            {/*    exact*/}
                            {/*    path="/shop/products/:productSlug"*/}
                            {/*    render={(props) => (*/}
                            {/*        <ShopPageProduct*/}
                            {/*            {...props}*/}
                            {/*            layout="standard"*/}
                            {/*            productSlug={props.match.params.productSlug}*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {/*/>*/}

                            {/* Following product layouts only for demonstration. */}
                            {/*{productLayouts}*/}

                        </Switch>
                    </div>

                    {/*{location.pathname !== "/" &&*/}
                    <footer className="site__footer">
                        <Footer/>
                    </footer>
                    {/*}*/}

                </div>
            }


            {/*: <div className="comming_soon_wrapper" >*/}

            {/*    <div className="logo_comming">*/}
            {/*        <div>*/}
            {/*            <img src={foodLogo} alt="" width="100%" height="100%" />*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <h1>Coming Soon</h1>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <div className="info_comming">*/}
            {/*        <ul>*/}
            {/*            <li>Yerevan, Armenia 0079 Mikoyan str. 15 </li>*/}
            {/*            <li>|</li>*/}
            {/*            <li>sales@fooddepot.am</li>*/}
            {/*            <li>|</li>*/}
            {/*            <li>+37477071834</li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}

            {/*</div>*/}

        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(["default", "compact"]),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: "default",
};

export default Layout;
