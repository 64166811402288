export default [
    {
        title: 'AMD',
        currency: {
            code: 'AMD',
            symbol: '֏',
            name: 'AMD',
        },
    },
    {
        title: 'USD',
        currency: {
            code: 'USD',
            symbol: '$',
            name: 'USD',
        },
    }
];
