// react
import React, {Component, Suspense} from "react";

// third-party
import PropTypes from "prop-types";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {IntlProvider} from "react-intl";
import {ScrollContext} from "react-router-scroll-4";

// application
// import languages from '../i18n';
import {localeChange} from "../store/locale";
import {url} from "../helper";

// pages
import Layout from "./Layout";
import Hided from "./Hided";
import BlockLoader from "./blocks/BlockLoader";
import history from "./history";
import ReactGA from 'react-ga';
import {languages} from "./utils";
import SitePageNotFound from "./site/SitePageNotFound";

const TRACKING_ID = "G-XER3M62HGV"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const direction = "ltr";

class Root extends Component {
    constructor(props) {
        super(props);
        this.state = {language: "", code: ""};
    }

    componentDidMount() {


        fetch(`${url}/api/translations`)
            .then((res) => res.json())
            .then((resp) => {
                this.setState({language: resp});
            });
    }

    shouldUpdateScroll = (prevRouterProps, {location}) =>
        prevRouterProps && location.pathname !== prevRouterProps.location.pathname;

    render() {
        const {locale} = this.props;
        if (!this.state.language) {
            return true;
        }
        const {translations} = this.state.language[locale];

        return (
            <IntlProvider locale={locale} messages={translations}>
                <Suspense fallback={<BlockLoader/>}>

                    <BrowserRouter history={history}>
                        <HelmetProvider>
                            <Helmet htmlAttributes={{lang: locale, dir: direction}}/>
                            {/*TODO delete this commented code after checking the analytics */}
                            {/*<Helmet htmlAttributes={{lang: locale, dir: direction}}>*/}
                            {/*    <script type="text/javascript" dangerouslySetInnerHTML={{*/}
                            {/*        __html: `*/}
                            {/*        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};*/}
                            {/*           m[i].l=1*new Date();*/}
                            {/*           for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}*/}
                            {/*           k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})*/}
                            {/*           (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");*/}
                            {/*           ym(95513737, "init", {*/}
                            {/*                clickmap:true,*/}
                            {/*                trackLinks:true,*/}
                            {/*                accurateTrackBounce:true,*/}
                            {/*                webvisor:true,*/}
                            {/*                ecommerce:"dataLayer"*/}
                            {/*           });*/}
                            {/*        `*/}
                            {/*    }}/>*/}
                            {/*    /!*<noscript>*!/*/}
                            {/*    /!*    <div>*!/*/}
                            {/*    /!*        <img src="https://mc.yandex.ru/watch/95513737" style="position:absolute; left:-9999px;" alt="" />*!/*/}
                            {/*    /!*    </div>*!/*/}
                            {/*    /!*</noscript>*!/*/}
                            {/*</Helmet>*/}
                            {/*<Helmet htmlAttributes={{lang: locale, dir: direction}}>*/}
                            {/*    <script async src="https://www.googletagmanager.com/gtag/js?id=G-XER3M62HGV"></script>*/}
                            {/*    <script type="text/javascript" dangerouslySetInnerHTML={{*/}
                            {/*        __html: `*/}
                            {/*        window.dataLayer = window.dataLayer || [];*/}
                            {/*          function gtag(){dataLayer.push(arguments);}*/}
                            {/*          gtag('js', new Date());*/}
                            {/*          gtag('config', 'G-XER3M62HGV');*/}
                            {/*        `*/}
                            {/*    }}/>*/}
                            {/*    /!*<noscript>*!/*/}
                            {/*    /!*    <div>*!/*/}
                            {/*    /!*        <img src="https://mc.yandex.ru/watch/95513737" style="position:absolute; left:-9999px;" alt="" />*!/*/}
                            {/*    /!*    </div>*!/*/}
                            {/*    /!*</noscript>*!/*/}
                            {/*</Helmet>*/}
                            <ScrollContext shouldUpdateScroll={this.shouldUpdateScroll}>
                                <Switch>
                                    <Route
                                        path="/"
                                        render={(props) => {
                                            const lang = props.location.pathname.split("/")[1]
                                            if(!props.location.pathname.includes("/shop/products")) {
                                                if (lang) {
                                                    const language = languages.find((x) => x.locale === lang);
                                                    if (language) {
                                                        this.props.localeChange(language.locale)
                                                    } else {
                                                        return <Route component={SitePageNotFound}/>
                                                    }
                                                }
                                            }
                                            return <Layout {...props} headerLayout="compact" homeComponent={Hided}/>
                                        }}
                                    />
                                    <Redirect to="/"/>
                                    {/*<Route component={SitePageNotFound}/>*/}
                                </Switch>
                            </ScrollContext>
                        </HelmetProvider>
                    </BrowserRouter>
                </Suspense>

            </IntlProvider>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        locale: state.locale,
    };
};

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
