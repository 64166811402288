// react
import React from 'react';
import {IntlProvider} from 'react-intl';
import messages from './i18n/messages/hy.json';

// third-party
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

// application
import * as serviceWorker from './serviceWorker';
import Root from './components/Root';
import store from './store';
// import '@formatjs/intl-locale-data/jsonp/hy';

// styles
import 'slick-carousel/slick/slick.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-input-range/lib/css/index.css';
import './scss/style.scss';

ReactDOM.render((
    // eslint-disable-next-line react/jsx-filename-extension
    <IntlProvider locale="hy" messages={messages}>
        <Provider store={store}>
            < Root/>
        </Provider>
    </IntlProvider>
), document.getElementById('root'));

{/* <script src="//code-eu1.jivosite.com/widget/QQt0S7tB6P" async></script> */ }
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
