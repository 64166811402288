// react
import React, {useEffect, useState} from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import {url} from "../../helper";

function SocialLinks(props) {
    const {shape, className} = props;
    const [social, getSocial] = useState();
    let items;
    useEffect(() => {
        fetch(`${url}/api/social`)
            .then((res) => res.json())
            .then((data) => {
                if (data && data) getSocial(data);
            });
    }, []);
    const classes = classNames(className, "social-links", {
        "social-links--shape--circle": shape === "circle",
        "social-links--shape--rounded": shape === "rounded",
    });

    // const items = [
    //     { type: "facebook", url: theme.author.profile_url, icon: "fab fa-facebook-f" },
    //     { type: "twitter", url: theme.author.profile_url, icon: "fab fa-twitter" },
    //     { type: "youtube", url: theme.author.profile_url, icon: "fab fa-youtube" },
    //     { type: "instagram", url: theme.author.profile_url, icon: "fab fa-instagram" },
    //     { type: "rss", url: theme.author.profile_url, icon: "fas fa-rss" },
    // ].map((item) => (
    //     <li key={item.type} className="social-links__item">
    //         <a
    //             className={`social-links__link social-links__link--type--${item.type}`}
    //             href={item.url}
    //             target="_blank"
    //             rel="noopener noreferrer"
    //         >
    //             <i className={item.icon} />
    //         </a>
    //     </li>
    // ));
    if (social) {
        items = social.map((item, index) => {
            return (
                <div className="social-items-fms" key={index}>
                    <div className="social-links__item">
                        <a href={item.link} target="_blank" rel="noopener noreferrer">
                            <img src={`${url}/storage/icons/${item.icon}`}/>
                        </a>
                    </div>
                </div>
            );
        });
    }
    return (
        <div className={classes}>
            <ul className="social-links__list">{items}</ul>
        </div>
    );
}

SocialLinks.propTypes = {
    /**
     * rating value
     */
    shape: PropTypes.oneOf(["circle", "rounded"]),
    className: PropTypes.string,
};
SocialLinks.defaultProps = {
    shape: null,
};

export default SocialLinks;
