// react
import React, {useEffect} from "react";

// third-party
import {Helmet} from "react-helmet-async";
// data stubs
import {useSelector} from "react-redux";
import {useState} from "react";
import {url} from "../../helper";

// import Content from "./Content";
import {useHistory} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import Breadcrumbs from "../shared/Breadcrumbs";
import PageHeader from "../shared/PageHeader";

function SiteCustomPage(props) {
    const history = useHistory();
    const selectedData = useSelector((state) => state.locale);
    const {id, location} = props;
    const [content, setContent] = useState();
    const pages = useSelector(state => state.pages)

    const pageTitle = pages?.find(el => el.page_id === +id)?.name


    useEffect(() => {
        let canceled = false;

        fetch(`${url}/api/cms/pages?id=${id}&locale=${selectedData}`)
            .then((response) => response.json())
            .then((res) => {
                if (res === undefined || res.length == 0) {
                    history.push("/");
                } else {
                    setContent(res);
                }
            });
    }, [id, pages]);

    const partnersImages = [
        "images/PARTNERS/partner_logo_1.png",
        "images/PARTNERS/partner_logo_2.png",
        "images/PARTNERS/partner_logo_3.jpeg",
        "images/PARTNERS/partner_logo_4.png",
        "images/PARTNERS/partner_logo_5.png",

        "images/PARTNERS/partner_logo_6.png",
        "images/PARTNERS/partner_logo_7.png",
        "images/PARTNERS/partner_logo_8.jpeg",
        "images/PARTNERS/partner_logo_9.png",
        "images/PARTNERS/partner_logo_10.png",

        "images/PARTNERS/partner_logo_11.png",
        "images/PARTNERS/partner_logo_12.jpeg",
        "images/PARTNERS/partner_logo_13.png",
        "images/PARTNERS/partner_logo_14.png",
        "images/PARTNERS/partner_logo_15.jpg",

        "images/PARTNERS/partner_logo_16.png",
        "images/PARTNERS/partner_logo_17.jpeg",
        "images/PARTNERS/partner_logo_18.jpeg",
        "images/PARTNERS/partner_logo_19.jpeg",
        "images/PARTNERS/partner_logo_20.png",

        "images/PARTNERS/partner_logo_21.png",
        "images/PARTNERS/partner_logo_22.png",
        "images/PARTNERS/partner_logo_23.jpg",
        "images/PARTNERS/partner_logo_24.png",
        "images/PARTNERS/partner_logo_25.png",

        "images/PARTNERS/partner_logo_26.png",
        "images/PARTNERS/partner_logo_27.png",
        "images/PARTNERS/partner_logo_28.png",
        "images/PARTNERS/partner_logo_29.png",
        "images/PARTNERS/partner_logo_30.png",

        "images/PARTNERS/partner_logo_31.jpeg",
        "images/PARTNERS/partner_logo_32.png",
        "images/PARTNERS/partner_logo_33.png",
        "images/PARTNERS/partner_logo_34.png",
        "images/PARTNERS/partner_logo_35.png",

        "images/PARTNERS/partner_logo_36.png",
        "images/PARTNERS/partner_logo_37.png",
        "images/PARTNERS/partner_logo_38.png",
        "images/PARTNERS/partner_logo_39.jpeg",
        "images/PARTNERS/partner_logo_40.png",

        "images/PARTNERS/partner_logo_41.jpeg",
        "images/PARTNERS/partner_logo_42.png",
        "images/PARTNERS/partner_logo_43.png",
        "images/PARTNERS/partner_logo_44.png",
        "images/PARTNERS/partner_logo_45.jpeg",

        "images/PARTNERS/partner_logo_46.png",
        "images/PARTNERS/partner_logo_47.jpeg",
        "images/PARTNERS/partner_logo_48.jpeg",
        "images/PARTNERS/partner_logo_49.jpeg",
        "images/PARTNERS/partner_logo_50.jpeg",

        "images/PARTNERS/partner_logo_51.jpeg",
        "images/PARTNERS/partner_logo_52.png",
        "images/PARTNERS/partner_logo_53.jpg",
        "images/PARTNERS/partner_logo_54.jpg",
        "images/PARTNERS/partner_logo_55.jpg",

        "images/PARTNERS/partner_logo_56.jpeg",
        "images/PARTNERS/partner_logo_57.png",
        "images/PARTNERS/partner_logo_58.png",
        "images/PARTNERS/partner_logo_59.png",
        "images/PARTNERS/partner_logo_60.png",

        "images/PARTNERS/partner_logo_61.png",
        "images/PARTNERS/partner_logo_62.png",
        "images/PARTNERS/partner_logo_63.png",
        "images/PARTNERS/partner_logo_64.jpg",
        "images/PARTNERS/partner_logo_65.png",

        "images/PARTNERS/partner_logo_66.jpeg",
        "images/PARTNERS/partner_logo_67.png",
        "images/PARTNERS/partner_logo_68.png",
        "images/PARTNERS/partner_logo_69.jpg",
        "images/PARTNERS/partner_logo_70.png",

        "images/PARTNERS/partner_logo_71.jpg",
        "images/PARTNERS/partner_logo_72.jpg",
        "images/PARTNERS/partner_logo_73.webp",
        "images/PARTNERS/partner_logo_74.png",
        "images/PARTNERS/partner_logo_75.png",

        "images/PARTNERS/partner_logo_76.jpg",
        "images/PARTNERS/partner_logo_77.png",
        "images/PARTNERS/partner_logo_78.png",
        "images/PARTNERS/partner_logo_79.png",
        "images/PARTNERS/partner_logo_80.png",

        "images/PARTNERS/partner_logo_81.png",
        "images/PARTNERS/partner_logo_82.png",
        "images/PARTNERS/partner_logo_83.png",
        "images/PARTNERS/partner_logo_84.png",
        "images/PARTNERS/partner_logo_85.png",

        "images/PARTNERS/partner_logo_86.png",
        "images/PARTNERS/partner_logo_87.png",
        "images/PARTNERS/partner_logo_88.png",
        "images/PARTNERS/partner_logo_89.png",
        "images/PARTNERS/partner_logo_90.png",

        "images/PARTNERS/partner_logo_91.png",
        "images/PARTNERS/partner_logo_92.png",
        "images/PARTNERS/partner_logo_93.png",
        "images/PARTNERS/partner_logo_94.png",
        "images/PARTNERS/partner_logo_95.png",

        "images/PARTNERS/partner_logo_96.png",
        "images/PARTNERS/partner_logo_97.png",
        "images/PARTNERS/partner_logo_98.png",
        "images/PARTNERS/partner_logo_99.png",
        "images/PARTNERS/partner_logo_100.png",

        "images/PARTNERS/partner_logo_101.png",
        "images/PARTNERS/partner_logo_102.png",
        "images/PARTNERS/partner_logo_103.png",
        "images/PARTNERS/partner_logo_104.png",
        "images/PARTNERS/partner_logo_105.png",

        "images/PARTNERS/partner_logo_106.png",
        "images/PARTNERS/partner_logo_107.png",
        "images/PARTNERS/partner_logo_108.png",
        "images/PARTNERS/partner_logo_109.png",
        "images/PARTNERS/partner_logo_110.png",
    ]

    const clientsImages = [
        "images/CLIENTS/clients_logo_1.jpeg",
        "images/CLIENTS/clients_logo_2.jpeg",
        "images/CLIENTS/clients_logo_3.jpeg",
        "images/CLIENTS/clients_logo_4.png",
        "images/CLIENTS/clients_logo_5.jpeg",

        "images/CLIENTS/clients_logo_6.jpeg",
        "images/CLIENTS/clients_logo_7.jpeg",
        "images/CLIENTS/clients_logo_8.jpeg",
        "images/CLIENTS/clients_logo_9.png",
        "images/CLIENTS/clients_logo_10.png",

        "images/CLIENTS/clients_logo_11.jpeg",
        "images/CLIENTS/clients_logo_12.jpeg",
        "images/CLIENTS/clients_logo_13.jpeg",
        "images/CLIENTS/clients_logo_14.png",
        "images/CLIENTS/clients_logo_15.jpeg",

        "images/CLIENTS/clients_logo_16.png",
        "images/CLIENTS/clients_logo_17.svg",
        "images/CLIENTS/clients_logo_18.png",
        "images/CLIENTS/clients_logo_19.jpeg",
    ]

    const breadcrumb = [
        {title: <FormattedMessage id="home" defaultMessage="home"/>, url: url},
        {title: pageTitle, url: location.pathname},
    ];

    const pageContent = +id === 8
        ?
        <div className="image_container">
            {partnersImages.map((image, index) => {
                return <div className="image">
                    <img src={image} alt={`image${index + 1}`}/>
                </div>
            })}
        </div>
        : +id === 7
            ?
            <div className="image_container">
                {clientsImages.map((image, index) => {
                    return <div className="image">
                        <img src={image} alt={`image${index + 1}`}/>
                    </div>
                })}
            </div>
            :
            <div
                className="dangerous-html-page"
                dangerouslySetInnerHTML={{__html: content ? content.data?.[0]?.html_content : ""}}
            />

    return (
        <React.Fragment>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content="Custom description"/>
            </Helmet>
            <Breadcrumbs breadcrumb={breadcrumb}/>
            {/*<Content text={content}  {...props}/>*/}
            <div className="container">
                <PageHeader header={pageTitle}/>
                {pageContent}
            </div>
        </React.Fragment>
    );
}

export default SiteCustomPage;



