import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Modal } from "reactstrap";
import { addAgeAnswer } from "../../store/age-answer";
import { useState } from "react";
import { useHistory,useLocation  } from "react-router-dom";
import { useEffect } from "react";
import { useRef } from "react";

export default function ModalDialog() {
    const [open, setOpen] = useState(true);
    const [source, setSource] = useState('');

    const router = useHistory();
    useEffect(() => {
  
        setOpen(true)
        const isPreviousPageWithinSite = router.action === 'PUSH';
    
        if (isPreviousPageWithinSite) {
            setSource(false)
        } else {
            setSource(true)
        }
      }, []);
      
    const dispatch = useDispatch();
    return open ? (
        <Modal className="dialog_modal" isOpen={open} centered size="xl">
            <div className="dialog_content">
                <div className="dialog_title">
                    <h4>
                        <FormattedMessage id={"ask_age"} defaultMessage="Are you over 18?" />
                    </h4>
                </div>

                <div className="dialog_actions">
                    <div
                        className="posotive_answer"
                        onClick={() => {
                            // dispatch(addAgeAnswer(true));
                            sessionStorage.setItem("addAgeAnswer",true)
                            setOpen(false);
                        }}
                    >
                        <span>
                            <FormattedMessage id={"yes"} defaultMessage="Yes" />{" "}
                        </span>
                    </div>
                    <div
                        className="negative_answer"
                        onClick={() => {
                            // dispatch(addAgeAnswer(false));
                            sessionStorage.setItem("addAgeAnswer",false)
                            setTimeout(()=>{
                                if(!source){
                                     router.goBack()
                                }else{
                                    router.push("/")
                                }
                               
                            },400)
                            setOpen(false);
                        }}
                    >
                        <span>
                            <FormattedMessage id={"no"} defaultMessage="No" />{" "}
                        </span>
                    </div>
                </div>
            </div>
        </Modal>
    ) : (
        ""
    );
}
