// react
import React from 'react';

// third-party
import {Helmet} from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';
import {Modal} from 'reactstrap';
import {Cross20Svg} from '../../svg';
import {useState} from 'react'
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import {url} from '../../helper';
import {toast} from 'react-toastify';
import {FormattedMessage} from 'react-intl';

export default function AccountPageProfile() {
    const customer = useSelector(state => state.customer);
    const dataCustomer = JSON.parse(localStorage.getItem('userData'));
    const [data, SetData] = useState(dataCustomer);
    const [modal, SetModal] = useState(false);

    const intialValues = {
        last_name: data.last_name,
        first_name: data.first_name,
        phone: data.phone,
        email: data.email,
        password: '',
        password_confirmation: ''
    };

    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        let errors = {};
        const regexp = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.first_name) {
            errors.first_name = "This field is required";
        }

        if (!values.last_name) {
            errors.last_name = "This field is required";
        }

        if (!values.phone) {
            errors.phone = "This field is required";
        }

        if (!values.email) {
            errors.email = "This field is required";
        } else if (!regexp.test(values.email)) {
            errors.email = "Invalid email format";
        }

        if (!values.password || values.password.length < 6) {
            errors.password = "Password must be more than 6 characters";
        }

        if (!values.password_confirmation || values.password_confirmation !== values.password) {
            errors.password_confirmation = 'Passwords do not match'
        }

        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);

    const submitForm = () => {
        let obj = Object.assign(formValues,
            {
                token: customer.token,
                gender: 'gender',
                date_of_birth: null
            })
        let option = {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                obj
            ),
        }

        fetch(`${url}/api/customer/profile`, option)
            .then(responce => responce.json())
            .then((res) => {
                toast.success(res.message);
                SetData(res.data);
                modalFunc()
                localStorage.setItem('userData', JSON.stringify(res.data))
            })
            .catch((err) => console.error(err));
    };

    const modalFunc = () => {
        SetModal(!modal)
    }

    return (
        <div className="personal-info">
            <Modal className="account_modal_fm" isOpen={modal} centered size="xl">
                <div className="quickview">
                    <button className="quickview__close" type="button" onClick={() => {
                        modalFunc()
                    }}>
                        <Cross20Svg/>
                    </button>
                    <form action="" onSubmit={handleSubmit}>
                        <div className="title">
                            <FormattedMessage id="account.editAccountInformation"
                                              defaultMessage="Edit account information"/>
                        </div>

                        <>
                            <div>
                                <FormattedMessage id="account.firstName" defaultMessage="First name">
                                    {placeholder =>
                                        <input
                                            type="text"
                                            placeholder={placeholder}
                                            name="first_name"
                                            id="first_name"
                                            value={formValues.first_name}
                                            onChange={handleChange}
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                            {formErrors.first_name && (
                                <div className="validation">
                                <span className="error">
                                    <FormattedMessage id="account.error.required"
                                                      defaultMessage={formErrors.first_name}/>
                                </span>
                                </div>
                            )}
                        </>

                        <>
                            <div>
                                <FormattedMessage id="account.lastName" defaultMessage="Last name">
                                    {placeholder =>
                                        <input
                                            type="text"
                                            placeholder={placeholder}
                                            name="last_name"
                                            id="last_name"
                                            value={formValues.last_name}
                                            onChange={handleChange}
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                            {formErrors.last_name && (
                                <div className="validation">
                                <span className="error">
                                    <FormattedMessage id="account.error.required"
                                                      defaultMessage={formErrors.last_name}/>
                                </span>
                                </div>
                            )}
                        </>

                        <>
                            <div>
                                <FormattedMessage id="global.phoneNumber" defaultMessage="Phone number">
                                    {placeholder =>
                                        <input
                                            type="text"
                                            placeholder={placeholder}
                                            id="phone"
                                            name="phone"
                                            value={formValues.phone}
                                            onChange={handleChange}
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                            {formErrors.phone && (
                                <div className="validation">
                                <span className="error">
                                    <FormattedMessage id="account.error.required" defaultMessage={formErrors.phone}/>
                                </span>
                                </div>
                            )}
                        </>

                        <>
                            <div>
                                <FormattedMessage id="global.email" defaultMessage="Email">
                                    {placeholder =>
                                        <input
                                            type="text"
                                            placeholder={placeholder}
                                            id="email"
                                            name="email"
                                            value={formValues.email}
                                            onChange={handleChange}
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                            {formErrors.email && (
                                <div className="validation">
                                <span className="error">
                                    <FormattedMessage
                                        id="account.error.email"
                                        defaultMessage={formErrors.email}
                                    />
                                </span>
                                </div>
                            )}
                        </>

                        <>
                            <div>
                                <FormattedMessage id="account.editPassword" defaultMessage="Edit password">
                                    {placeholder =>
                                        <input
                                            type="password"
                                            placeholder={placeholder}
                                            name="password"
                                            id="password"
                                            value={formValues.password}
                                            onChange={handleChange}
                                        />
                                    }
                                </FormattedMessage>
                            </div>
                            {formErrors.password && (
                                <div className="validation">
                                <span className="error">
                                    <FormattedMessage
                                        id="account.error.password"
                                        defaultMessage={formErrors.password}
                                    />
                                </span>
                                </div>
                            )}
                        </>

                        <>
                            <div>
                                <FormattedMessage id="account.confirmPassword" defaultMessage="Confirm password">
                                    {placeholder =>
                                        <input
                                            type="password"
                                            placeholder="Confirm password"
                                            name="password_confirmation"
                                            id="password_confirmation"
                                            value={formValues.password_confirmation}
                                            onChange={handleChange}
                                        />

                                    }
                                </FormattedMessage>
                            </div>
                            {formErrors.password_confirmation && (
                                <div className="validation">
                                <span className="error">
                                    <FormattedMessage
                                        id="account.error.passwordConfirmation"
                                        defaultMessage={formErrors.password_confirmation}
                                    />
                                </span>
                                </div>
                            )}
                        </>

                        <div className="butt_block_fm">
                            <div className="cancel_modal_fm cursor_fm" onClick={() => {
                                modalFunc()
                            }}><FormattedMessage id="account.cancel" defaultMessage="Cancel"/></div>
                            <div className="change_modal_fm">
                                <button><FormattedMessage id="account.change" defaultMessage="Change"/></button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Helmet>
                <title>{`Personal information — ${theme.name}`}</title>
            </Helmet>

            <div className="account__set_fm pers_info_fm">
                <div><FormattedMessage id="account.firstName" defaultMessage="First name"/></div>
                <div>{data ? data.first_name : dataCustomer.first_name}</div>
            </div>
            {customer.role !== "subUser" && <div className="account__set_fm pers_info_fm">
                <div><FormattedMessage id="account.lastName" defaultMessage="Last name"/></div>
                <div>{data ? data.last_name : dataCustomer.last_name}</div>
            </div>}
            {customer.role !== "subUser" && <div className="account__set_fm pers_info_fm">
                <div><FormattedMessage id="global.phoneNumber" defaultMessage="Phone number"/></div>
                <div>{data ? data.phone : dataCustomer.phone}</div>
            </div>}
            <div className="account__set_fm pers_info_fm email">
                <div><FormattedMessage id="global.email" defaultMessage="Email"/></div>
                <div>{data ? data.email : dataCustomer?.email}</div>
            </div>
            {customer.role !== "subUser" && <div className="account__set_fm">
                <div style={{color: '#43B02A'}} className="cursor_fm" onClick={() => {
                    modalFunc()
                }}><FormattedMessage id="account.change" defaultMessage="Change"/></div>
            </div>}
        </div>
    );
}
