import React, {useState} from "react";

import {Helmet} from "react-helmet-async";

import theme from "../../data/theme";
import {Modal} from "reactstrap";
import {Cross20Svg} from "../../svg";
import {useSelector} from "react-redux";
import ChangeEmile from "./ChangeEmile";
import ChangePassword from "./ChangePassword";
import {FormattedMessage} from "react-intl";

export default function AccountPageDashboard() {
    const customer = useSelector((state) => state.customer);

    const dataCustomer = JSON.parse(localStorage.getItem("userData"));
    const [modal, SetModal] = useState(false);
    const [data, SetData] = useState(dataCustomer);
    const [info, SetInfo] = useState(false);
    const [pass, SetPass] = useState(false);
    let FmCustomerChangePassword;
    let FmCustomerChangeEmail;
    // if (match.url === "/account/dashboard" && customer.role == "subUser") {
    //     return <Redirect to="/account/orders" />
    // }
    if (customer.role !== "subUser") {
        FmCustomerChangePassword = (
            <div className="account__set_fm">
                <div>
                    <FormattedMessage id="account.password" defaultMessage="Password"/>
                </div>
                <div
                    className="account_change_fm cursor_fm"
                    onClick={() => {
                        modalFunc("pass");
                    }}
                >
                    <FormattedMessage id="account.change" defaultMessage="Change"/>
                </div>
            </div>
        );
        FmCustomerChangeEmail = <FormattedMessage id="account.change" defaultMessage="Change"/>;
    }

    const modalFunc = (type) => {
        if (type === "pass") {
            SetPass(!pass);
        }

        if (type === "info") {
            SetInfo(!info);
        }

        if (type === "close") {
            SetPass(false);
            SetInfo(false);
        }

        SetModal(!modal);
    };

    return (
        <div className="dashboard">
            <Modal className="account_modal_fm" isOpen={modal} centered size="xl">
                <div className="quickview">
                    <button
                        className="quickview__close"
                        type="button"
                        onClick={() => {
                            modalFunc("close");
                        }}
                    >
                        <Cross20Svg/>
                    </button>
                    {info ? <ChangeEmile modalFunc={modalFunc} setData={SetData}/> : ""}
                    {pass ? <ChangePassword modalFunc={modalFunc}/> : ""}
                </div>
            </Modal>
            <Helmet>
                <title>{`Account settings — ${theme.name}`}</title>
            </Helmet>

            <div className="account__set_fm">
                <div>
                    <FormattedMessage id="global.email" defaultMessage="Email"/>
                </div>
                <div>{data ? data.email : dataCustomer?.email}</div>
                <div
                    className="account_change_fm cursor_fm"
                    onClick={() => {
                        modalFunc("info");
                    }}
                >
                    {FmCustomerChangeEmail}
                </div>
            </div>
            {FmCustomerChangePassword}
        </div>
    );
}
