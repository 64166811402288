// react
import React, {useEffect, useState} from "react";

// third-party
import {Helmet} from "react-helmet-async";

import theme from "../../data/theme";
import "../../scss/home.scss";
import {url} from "../../helper";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Modal} from "reactstrap";
import {languages} from "../utils";
import {localeChange} from "../../store/locale";
import {CatArrow, SliderNextArrow, SliderPrevArrow, Special} from "../../svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {toast} from "react-toastify";
import shopApi from "../../api/shop";
import {translateMetas} from "../../services/utils";

function HomePageTwo() {
    const [categoryData, getCategory] = useState();
    const [modal, setModal] = useState(true);
    const [slides, setSlides] = useState([]);
    const [title, setTitle] = useState("");
    const locale = useSelector((state) => state.locale);
    const distpach = useDispatch()
    const domain = "https://fooddepot.am/"
    let def = `${url}/vendor/webkul/ui/assets/images/product/large-product-placeholder.png`;
    const history = useHistory();
    const location = useLocation();
    const x = useParams();
    const settings = {
        dots: true,
        infinite: true,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 8000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: "linear",
        nextArrow: <SliderNextArrow/>,
        prevArrow: <SliderPrevArrow/>,
    }

    useEffect(() => {
        shopApi.getChannelData()
            .then(res => {
                setTitle(translateMetas(res.data[0].home_seo.meta_title)[locale])
            })
    }, [])


    useEffect(() => {
        fetch(`${url}/api/categories?locale=${locale}`)
            .then((responce) => responce.json())
            .then((res) => {
                let arr = res.categories;
                getCategory(arr);
            })
            .catch((err) => console.error(err));
    }, [locale]);

    useEffect(() => {
        const isChoosedLang = JSON.parse(localStorage.getItem("choosed"))

        if (Boolean(isChoosedLang)) {
            setModal(false)
        }
    })

    useEffect(() => {
        fetch(`${url}/api/sliders?locale=${locale}`)

            .then(responce => responce.json())
            .then(res => {

                setSlides(res.map(el => {
                    if (el.slider_path && el.slider_path.includes(domain)) {
                        const sliced_path = el.slider_path.split(domain)[1] || ""
                        const path_locale = sliced_path && sliced_path.split("/")[0]


                        if (path_locale && !languages.find(lang => lang.locale === path_locale)) {
                            return {
                                ...el,
                                slider_path: domain + locale + "/" + sliced_path
                            }
                        }
                    }
                    return el
                }))

            })
            .catch(err => console.error(err));
    }, [locale]);

    const handlerToggle = () => {
        localStorage.setItem("choosed", false)
        setModal(!modal)
    }

    const handlerClick = (lang) => {

        distpach(localeChange(lang.locale))
        localStorage.setItem("choosed", true)
        setModal(!modal);
        history.push(`/${lang.locale}`)
    };

    const goToLink = (path) => {
        history.push(path);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {/* <div className="home_img_fm">
                <img src="images/home/home.png" alt="home" width="100%"/>
                <div className="box_shadow_fm"></div>
                <div className="search_logo_fm">
                    <div className="logo_fm d-none d-md-block">
                        <img src="images/home/Group 68373.png" alt="home" />
                    </div>
                    <div className="search_inp_fm">
                        <Search />
                    </div>
                </div>
            </div> */}
            {/* <div className="home_nav_fm">
                <BlockNavMenu />
            </div> */}
            {slides.length > 0 &&
                <Slider {...settings} initialSlide={0} className="home_slider">
                    {slides.map(slide => {
                        const {slider_path} = slide

                        return slider_path ?
                            <a className="slide" href={slider_path}>
                                <img
                                    src={`https://api.fooddepot.am/storage/${slide.path}`}
                                    alt={slide.title}
                                />
                                <div className="slide_content_wrapper">
                                    <div className="slide_content" dangerouslySetInnerHTML={{__html: slide.content}}/>
                                </div>
                            </a>
                            :
                            <div className="slide">
                                <img
                                    src={`https://api.fooddepot.am/storage/${slide.path}`}
                                    alt={slide.title}
                                />
                                <div className="slide_content_wrapper">
                                    <div className="slide_content"
                                         dangerouslySetInnerHTML={{__html: slide.content}}/>
                                </div>

                            </div>
                    })}
                </Slider>
            }
            <div className="home_category_fm">
                <h1 className="main_title">
                    <FormattedMessage id="categories_block_title" defaultMessage="categories"/>
                </h1>
                <div className="list_fm">
                    {categoryData
                        ? categoryData.map((e, i) => {
                            // const messageId = e.slug === "profdepot" ? "soon" : "learn_more"
                            const messageId = "learn_more"
                            let imagePath = e.image_url.includes("large-product-placeholder")
                                ? def
                                : `${url}/storage/${e.image_url}`;
                            let rightLink = e.id !== 267 ? `/${locale}/shop/catalog?category_slug=${e.slug}` : `/${locale}/shop/main/catalog?category_id=${e.id}`
                            return (
                                <div
                                    // className={`item_fm new_deppot_category_card ${e.slug === "profdepot" && "disabled"}`}
                                    className={`item_fm new_deppot_category_card `}
                                    key={i}
                                    onClick={() => goToLink(rightLink)}
                                >
                                    <div className="image_wrapper">
                                        {i === 0 && <Special style={{position: "absolute"}}/>}
                                        <Link to={rightLink}>
                                            <img src={imagePath} alt={e.name}/>
                                        </Link>
                                    </div>
                                    <div className="new_deppot_category_content">
                                        <h3>{e.name}</h3>
                                        <div
                                            className="category_card_descr"
                                            dangerouslySetInnerHTML={{
                                                __html: JSON.parse(JSON.stringify(e.description)),
                                            }}
                                        />
                                        <Link to={rightLink}>
                                        <span>
                                                <FormattedMessage
                                                    id={messageId}
                                                    defaultMessage={messageId}
                                                />
                                            </span>
                                            <CatArrow/>
                                        </Link>
                                    </div>
                                </div>
                            );
                        })
                        : ""}
                </div>
            </div>
            {modal &&
                <Modal className="lang_modal_fm" isOpen={modal} toggle={handlerToggle} centered size="xl">
                    {/* <div className="quickview"> */}

                    <div className="lang_modal_tilte">
                        <h2>
                            <FormattedMessage id="select_language" defaultMessage="Select a language"/>
                        </h2>
                    </div>
                    <div className="language-modal-wrapper">
                        {languages.map((lang) => {

                            return (
                                <>
                                    <div onClick={() => handlerClick(lang)}>
                                        <div>{lang.popupIcon}</div>
                                        <span>{lang.formattedTitle}</span>
                                    </div>
                                </>
                            );
                        })}
                    </div>

                    {/* </div> */}
                </Modal>
            }
        </React.Fragment>
    );
}

export default HomePageTwo;
