// react
import React, {useEffect, useState} from "react";
import shopApi from "../../api/shop";
import {connect} from "react-redux";
import ProductsView from "./ProductsView";
import {setCatId, setSearchedWord} from '../../store/general';
import {FormattedMessage} from "react-intl";
import BlockLoader from "../blocks/BlockLoader";
import {url} from "../../services/utils";
import Breadcrumbs from "../shared/Breadcrumbs";
import PageHeader from "../shared/PageHeader";
import InfiniteScroll from "react-infinite-scroll-component";
import {Helmet} from "react-helmet-async";


function ShopPageCategoryBySearch(props) {
    const {catID, categorySlug, columns, viewMode, serchedWord, sidebarPosition, locale, customer, location} = props;
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const limit = 18
    const offcanvas = columns === 3 ? "mobile" : "always";
    const [productsList, setProductsList] = useState({data: []})
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [localSearchWord, setLocalSearchWord] = useState("")
    const api_token = localStorage.getItem("api_token")
    // if (!serchedWord) {
    //     props.setSearchedWord(null)
    //     props.setCatId()
    //     // history.push('/')
    // }

    useEffect(() => {
        setCurrentPage(1)
        setLastPage(1)
        setProductsList({data: []})
        setIsLoading(true)
    }, [location.search]);

    useEffect(() => {
        let searchedWord = decodeURI(location.search).split("search=")[1]
        setLocalSearchWord(searchedWord)
        props.setSearchedWord(searchedWord)
    }, [location.search])


    useEffect(() => {
        // let word = !Boolean(localSearchWord) ? "undefined" : localSearchWord
        if (currentPage <= lastPage && localSearchWord) {
            const options = {
                search: localSearchWord,
                locale: locale,
                limit,
                category_id: catID,
                token: customer.token,
                page: currentPage,
                api_token
            }
            shopApi.getProductsListBySearch(options)
                .then((response) => {
                    // if(response.data){
                    //     response.data = response?.data?.reduce((acc, next) => {
                    //     return [
                    //         ...acc,
                    //         { ...next, id: next.product_id, base_image: { original_image_url: next.image } }
                    //     ]
                    // }, [])
                    setIsLoading(false)
                    setLastPage(response.meta.last_page)
                    setProductsList(prev => {
                        if (currentPage === 1) {
                            return response
                        } else if (prev.data.length < currentPage * limit) {
                            return {
                                ...prev,
                                data: [...prev.data, ...response.data]
                            }
                        }
                        return prev
                    })
                    // }
                    setHasMore(response.meta.current_page !== response.meta.last_page)
                })
        } else setHasMore(false)
    }, [localSearchWord, catID, currentPage]);

    const loadingBlock = (
        <div className="loading">
            <span> </span>
            <span> </span>
            <span> </span>
        </div>
    )

    if (!productsList) {
        // return <BlockLoader/>;
        return <div className="none_content">
            {loadingBlock}
        </div>;
    }

    const breadcrumb = [
        {title: <FormattedMessage id="home" defaultMessage="home"/>, url: url.home()},
        {
            title: <FormattedMessage id={"search_results"} defaultMessage="Search results"/>,
            url: `${location.pathname}${location.search}`
        },
    ];

    const searchPageTitle = <div className="searched_word">
        <h1>
            <FormattedMessage id={"search_results"} defaultMessage="Search results"/>
            :
            <span>{localSearchWord}</span>
        </h1>
    </div>

    const productsView = (
        isLoading
            ?
            <div className="loader_wrapper">
                {loadingBlock}
            </div>
            :
            productsList.data.length > 0 ?
                <InfiniteScroll
                    dataLength={productsList.data.length} //This is important field to render the next data
                    next={() => setCurrentPage(currentPage + 1)}
                    hasMore={hasMore}
                    loader={loadingBlock}
                    endMessage={
                        <p style={{textAlign: 'center'}}>
                            <b><FormattedMessage id="infinit.endmassage"
                                                 defaultMessage="Yay! You have seen it all"/></b>
                        </p>
                    }
                    pullDownToRefreshThreshold={0}

                >
                    <ProductsView
                        fromSearch
                        catID={catID}
                        categorySlug={categorySlug}
                        isLoading={false}
                        productsList={productsList}
                        options={[]}
                        filters={[]}
                        dispatch={() => {
                        }}
                        layout={viewMode}
                        grid={`grid-${columns}-${columns > 3 ? "full" : "sidebar"}`}
                        offcanvas={offcanvas}
                    />
                </InfiniteScroll>
                :
                <ProductsView
                    fromSearch
                    catID={catID}
                    categorySlug={categorySlug}
                    isLoading={false}
                    productsList={productsList}
                    options={[]}
                    filters={[]}
                    dispatch={() => {
                    }}
                    layout={viewMode}
                    grid={`grid-${columns}-${columns > 3 ? "full" : "sidebar"}`}
                    offcanvas={offcanvas}
                />
    )

    return (
        <>
            <Helmet>
                <title>Search results</title>
            </Helmet>
            <div className="cat_blocks_fms">
                <Breadcrumbs breadcrumb={breadcrumb}/>

                <div className="container_fm">

                    <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                        <div className="shop-layout__content">
                            <PageHeader header={searchPageTitle}/>

                            <div className="block">
                                {productsView}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({general, locale, customer}) => ({
    serchedWord: general.serchedWord,
    catID: general.catID,
    locale: locale,
    customer
});

const mapDispatchToProps = (dispatch) => ({
    setSearchedWord: (payload) => dispatch(setSearchedWord(payload)),
    setCatId: (catId) => dispatch(setCatId(catId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCategoryBySearch);
