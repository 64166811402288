// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// application
import BlogCommentsList from './BlogCommentsList';



export default function BlogPost(props) {
    return (
        <div className='blog_inner_page_fm'>
            sdfdf
        </div>
    );
}
