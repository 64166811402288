import React from "react";
import { useHistory,useLocation } from "react-router-dom";

const CustomBottomNavigation = (props) => {
    const {items} = props
    const router = useHistory()
    const location = useLocation()

    const listItems = items.map((item) => {
        let activeElement = false

        if(item.param.includes(location.pathname)){
            activeElement = true
        }

        return (
            <>
                <div
                    className={`bottom-nav-item local-item ${activeElement ? "local-item-active" : ""}`}
                    id={`nav-item-${item.param}`}
                    onClick={(e) => {

                        if (item.onClick) {
                            item.onClick()
                        }
                    }}>
                    <div className={`indicator indicator--trigger--click`}
                         style={{pointerEvents: "none"}}>
                        <span
                            className={`indicator__area mob_area`}
                        >
                            {activeElement ? item.activeIcon : item.icon}
                        </span>
                    </div>
                    {/*<p style={{pointerEvents: "none"}}*/}
                    {/*   className={`"bottom-nav-item--title"]} ${activeElement &"bottom-nav-item--title-active"]}`}*/}
                    {/*>*/}
                    {/*    {item.title}*/}
                    {/*</p>*/}


                </div>
            </>)
    })
    return (
        <div className="bottomNavigation-wrapper">
            {listItems}
        </div>
    )
}

export default CustomBottomNavigation
