import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {url} from "../../helper";
import {useDispatch, useSelector} from "react-redux";
import shopApi from "../../api/shop";
import {FoodArrowSVG} from "../../svg";
import {parse} from "query-string";
import ModalDialog from "../shared/ModalDialog";
import {FormattedMessage} from "react-intl";
import classNames from 'classnames';
import {addCategoryName} from "../../store/category";
import {Helmet} from "react-helmet-async";

const ShopPageMainCategory = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setData] = useState({});
    const [routes, setRoutes] = useState([]);
    const [title, setTitle] = useState("");
    const location = useLocation();
    const locale = useSelector((state) => state.locale);
    const [ageAnswer, setAgeAnswer] = useState(null)
    const dispatch = useDispatch()

    const [slug, setSlug] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    let def = `${url}/vendor/webkul/ui/assets/images/product/large-product-placeholder.png`;

    useEffect(() => {
        const ageAnswerSession = JSON.parse(sessionStorage.getItem("addAgeAnswer"))
        setAgeAnswer(ageAnswerSession)
    }, [])

    useEffect(() => {
        shopApi.getCategories({locale: locale}).then((categories) => {
            setCategories([...categories.categories]);
            let categoryRoutes = categories
                ? categories.categories.map((item) => {
                    return {name: item.name, slug: item.slug, id: item.id};
                })
                : [];
            setRoutes([...categoryRoutes]);
        });

        let parsedQuery = parse(location.search);

        setSlug(parsedQuery.category_slug);

        shopApi.getCategoryBySlug(parsedQuery.category_slug, locale)
            .then(res => {
                setTitle(res.meta_title)
            })

    }, [locale, location.search]);

    const flattingCategories = (arr) => {
        let result = []

        for (let el of arr) {
            if (el.children.length > 0) {
                result.push(...flattingCategories(el.children))
            }
            result.push(el)
        }

        return result
    }

    useEffect(() => {

        if ((slug === "alcodepot" || slug === "alcohol") && !Boolean(ageAnswer)) {
            setOpenDialog(true);
        } else {
            setOpenDialog(false);
        }
        let founndedCat = categories ? flattingCategories(categories).find((item) => item.slug === slug) : {};
        setData({...founndedCat});
    }, [slug, categories]);

    // console.log(selectedCategory, "selectedCategory")
    const list = selectedCategory?.children?.map((item, index, arr) => {
        // console.log(item, "item")
        let rightLink = item.children.length > 0 ? `/${locale}/shop/catalog?category_slug=${item.slug}` : `/${locale}/shop/main/catalog?category_id=${item.id}`

        let imagePath = item.image_url.includes("large-product-placeholder") ? def : `${url}/storage/${item.image_url}`;
        return (
            <div className="selectedCategory-item-card" onClick={() => {
                dispatch(addCategoryName(item.name))
            }}>
                <Link to={rightLink}>
                    <div>
                        <img src={imagePath} alt={item.name}/>
                        <div className="selectedCategory-name">
                            <span>{item.name}</span>
                        </div>
                    </div>
                </Link>
            </div>
        );
    });

    const findParent = (selectedCategory, categories) => {
        let parentCategory = {}

        for (let item of categories) {
            if (!item.children.find(el => el.id === selectedCategory.id)) {
                if (item.children.length !== 0) {
                    parentCategory = findParent(selectedCategory, item.children)
                    if (parentCategory) return item
                }
            } else {
                return item
            }
        }

    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {openDialog && <ModalDialog/>}

            <section className="categoroy-children-wrapper">
                <div className="container_m">
                    <div className="main-routes">
                        <ul>
                            {routes.map((item) => {
                                let parentCategory = findParent(selectedCategory, categories)


                                const classes = classNames("", {
                                    "selected": item.slug === slug || parentCategory && item.slug === parentCategory.slug,
                                    // "disabled": item.slug === "profdepot"
                                })


                //im so sorry
                                if(item.id === 267){

                                    return (
                                        <Link
                                            className={classes}
                                            to={`/${locale}/shop/main/catalog?category_id=267`}
                                        >
                                            {" "}
                                            <li>{item.name}</li>
                                        </Link>
                                    );

                                }else{

                                return (
                                    <Link
                                        className={classes}
                                        to={`/${locale}/shop/catalog?category_slug=${item.slug}`}
                                    >
                                        {" "}
                                        <li>{item.name}</li>
                                    </Link>
                                );
                                }

                            })}
                        </ul>

                        <div className="mobile-router">
                            <div>
                                <div>
                                    <Link to={"/"}>
                                        <FoodArrowSVG/>
                                    </Link>
                                </div>
                                <div>
                                    <h4><FormattedMessage id={"our_range"} defaultMessage="Our Range"/></h4>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="selectedCategory-title">
                        <h2>{selectedCategory.name}</h2>
                    </div>
                    <div className="category-children-list">{list}</div>
                </div>
            </section>
        </>
    );
};

export default ShopPageMainCategory;
