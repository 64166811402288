import {
    SET_SEARCHED_WORD,
    SET_CAT_ID,
    SET_INITIAL_MAX_PRICE,
    SET_INITIAL_MIN_PRICE,
    SET_LAST_SCROLL_POSITION
} from './generalActionTypes';

export const setSearchedWord = (payload) => ({
    type: SET_SEARCHED_WORD,
    payload,
})

export const setCatId = (payload) => ({
    type: SET_CAT_ID,
    payload,
})

export const setInitialMinPrice = (payload) => {
    return ({
        type: SET_INITIAL_MIN_PRICE,
        payload,
    });
}

export const setInitialMaxPrice = (payload) => {
    return ({
        type: SET_INITIAL_MAX_PRICE,
        payload,
    });
}

export const setLastScrollPosition= (payload) => {
    return ({
        type: SET_LAST_SCROLL_POSITION,
        payload,
    });
}
