// react
import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {useSelector} from "react-redux";

export default function BlockNavMenu() {
    const locale = useSelector(state => state.locale)
    return (
        <div>
            <div className="nav_menu_fm">
                <div>
                    <img src="/images/icon/home.png" alt="" className="d-block d-md-none"/>
                    <Link to="/">
                        <FormattedMessage id="menu.home"  defaultMessage="Home" />
                    </Link>
                </div>
                <div>
                    <img src="/images/icon/sale.png" alt="" className="d-block d-md-none"/>
                    <Link to="/shop/catalog?savings=true">
                        <FormattedMessage id="menu.savings"  defaultMessage="Savings" />
                    </Link>
                </div>
                <div>
                    <img src="/images/icon/brands.jpg" alt="" className="d-block d-md-none" width="22px"/>
                    <Link to={`${locale}/brands`}>
                        <FormattedMessage id="menu.brends"  defaultMessage="Brands" />
                    </Link>
                </div>
            </div>
            <div className="header_line_fm d-block d-md-none"></div>
        </div>
    );
}
