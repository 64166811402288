// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import debounce from "lodash.debounce";
import {RemoveSVG} from "../../svg";
import AsyncAction from "./AsyncAction";
import {cartRemoveItemNew} from "../../store/cart";

class InputNumberCart extends Component {
    constructor(props) {
        super(props);

        this.bouncedChange = debounce(this.bouncedChange.bind(this), 2000);
      }
    handleChange = (event) => {

        const { min, onChange,step } = this.props;
        let newStep = step
        if(Number.isNaN(newStep)){
            newStep = 1
        }
        if (onChange) {
            if (event.target.value.trim() === '') {
                onChange('');
            } else {

                const value = parseFloat(event.target.value)
                onChange(Number.isNaN(value) ? (min || 0) : value);
                const rightValue = value % newStep === 0 ? value : value - Math.ceil(value % newStep)
                this.bouncedChange(rightValue,min,onChange,newStep)
            }
        }

    };

    bouncedChange = (rightVal,min,onChange,step) =>{
        if(rightVal < step){
            rightVal = step
        }
        onChange(Number.isNaN(rightVal) ? (min || 0) : rightVal);
    }

    handleAddMouseDown = () => {
        this.change(1);
        this.changeByTimer(1);
    };

    handleSubMouseDown = () => {
        this.change(-1);
        this.changeByTimer(-1);
    };

    /**
     * @param direction - one of [-1, 1]
     */
    change(direction) {
        const {
            value,
            step,
            max,
            min,
            onChange,
        } = this.props;
        let newValue = (value === '' || Number.isNaN(value) ? 0 : value) + step * direction;

        if (max !== null) {
            newValue = Math.min(max, newValue);
        }
        if (min !== null) {
            newValue = Math.max(min, newValue);
        }

        if (newValue !== value) {
            if (onChange) {
                onChange(newValue);
            }
        }
    }

    /**
     * @param direction - one of [-1, 1]
     */
    changeByTimer(direction) {
        let interval;
        const timer = setTimeout(() => {
            interval = setInterval(() => this.change(direction), 50);
        }, 300);

        const documentMouseUp = () => {
            clearTimeout(timer);
            clearInterval(interval);

            document.removeEventListener('mouseup', documentMouseUp, false);
        };

        document.addEventListener('mouseup', documentMouseUp, false);
    }

    render() {

        const {
            size,
            className,
            onChange,
            disabled,
            value,
            min,
            max,
            itemId,
            cartRemoveItemNew,
            ...otherProps
        } = this.props;

        // const classes = classNames('input-number', className);
        // const formControlClasses = classNames('form-control input-number__input', {
        //     'form-control-sm': size === 'sm',
        //     'form-control-lg': size === 'lg',
        // });

        return (
            <div className="qty_fm">
                {value === 1 ?
                    <AsyncAction
                        action={() => {
                            return cartRemoveItemNew(itemId);
                        }}
                        render={({run, loading}) => (
                            <div
                                onClick={run}
                                className={`plus_min_fm ${disabled || value <= min && "remove"} ${classNames({"btn-loading": loading,})}`}
                                onMouseDown={this.handleSubMouseDown}>
                                {value === 1 ? <RemoveSVG/> : '-'}
                            </div>
                        )}
                    />
                    :
                    <div className={`plus_min_fm ${disabled || value <= min && "remove"}`}
                         onMouseDown={this.handleSubMouseDown}>
                    {value === 1 ? <RemoveSVG/> : '-'}
                    </div>
                }
                 {/*<div className={`plus_min_fm ${disabled || value <= min && "remove"}`}*/}
                 {/*     onMouseDown={this.handleSubMouseDown}>*/}
                 {/*    {value === 1 ? <RemoveSVG/> : '-'}*/}
                 {/*</div>*/}
                <div className="qount_fm">
                    <input
                        type="number"
                        min={min}
                        max={max}
                        value={value}
                        onChange={this.handleChange}
                        {...otherProps}
                    />
                </div>
                <div className={`plus_min_fm ${disabled || value >= max && "disabled"}`} onMouseDown={this.handleAddMouseDown}>
                    +
                </div>
            </div>
        );
    }
}

InputNumberCart.propTypes = {
    onChange: PropTypes.func,
    size: PropTypes.oneOf(['sm', 'lg']),
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
};

InputNumberCart.defaultProps = {
    value: '',
    step: 1,
    max: null,
    min: null,
    disabled: false,
};

export default InputNumberCart;
