import {AUTHENTICATED, CUSTOMER_TOKEN, ROLE, LIMIT} from './customerActionTypes'

const initialState = {
    authenticated: false,
    token: '',
    limit: 0,
};

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ROLE:
            return {
                ...state,
                role: action.payload,
            };
        case AUTHENTICATED:
            return {
                ...state,
                authenticated: action.payload,
            };
        case CUSTOMER_TOKEN:
            return {
                ...state,
                token: action.payload,
            };
        case LIMIT:
            return {
                ...state,
                limit: action.payload,
            };
        default:
            return state;
    }
};

export default customerReducer;
