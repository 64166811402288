// react
import React, {Component} from "react";
import RelatedItems from "./RelatedItems";
import InputNumber from "../shared/InputNumber";
import PropTypes from "prop-types";
import ProductGallery from "../shared/ProductGallery";
import AsyncAction from "../shared/AsyncAction";
import {wishlistAddItem} from "../../store/wishlist";
import {cartAddItemNew} from "../../store/cart";
import {AddImages} from "../../store/images";
import classNames from "classnames";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {toast} from "react-toastify";
import {FacebookShareButton} from "react-share";
import {Check12x9Svg, FacebookIconSVG} from "../../svg";
import shopApi from "../../api/shop";
import {colorType} from "../../services/color";
import ConfigAttributes from "../shared/ConfigAttributes";

class InnerProduct extends Component {
    checkAddProd = false;
    cartProduct = null;
    refs = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            quantity: Boolean(this.props.product.min_qty) ? Number(this.props.product.min_qty) : 1,
            wishlist: this.props.wishlist,
            customer: this.props.customer,
            currency: this.props.currancy,
            locale: this.props.locale,
            token: this.props.token,
            product: null,
            productConst: null,
            imagesData: null,
            simpleProduct: null,
            size: null,
            color: null,
            memory: null,
            ram: null,
            ml: null,
            variants: null,
            goToFirstIndex: null,
            colorType: {},
            sizeType: {},
            attributes: [],
            isAllChecked: true,
        };
    }

    componentDidMount() {

        if (this.props.product.type === "configurable") {
            this.setState({
                product: this.props.product,
                simpleProduct: this.props.product,
                imagesData: this.props.product.variants[0] ? this.props.product.variants[0].images : [],
            });


            shopApi
                .getMultipleRequests(this.props.product.id, this.props.locale)
                .then((res) => {
                    if (res) {

                        Object.assign(this.props.product, {
                            attribute: res[0].data,
                            index: res[0].data.index,
                            regular_price: res[0].data.regular_price,
                            variant_images: res[0].data.variant_images,
                            variant_prices: res[0].data.variant_prices,
                            variants: this.props.product.variants,
                        });

                        let filterVariant = this.props.product.variants.filter((e) => {
                            return e.id == this.props.product.variants[0].id;
                        });

                        if (filterVariant.length > 0) {
                            this.props.AddImages(filterVariant[0].images);
                        }
                        this.selectDef(this.props.product, filterVariant[0]);

                        this.setState({
                            ...this.state,
                            imagesData: this.props.product.variants[0] ? this.props.product.variants[0].images : [],
                            simpleProduct: this.props.product.variants[0],
                            quantity: Boolean(this.props.product.variants[0].min_qty) ? Number(this.props.product.variants[0].min_qty) : 1,
                        });

                        let prod = this.state.simpleProduct;
                        let attrib = this.state.product.attribute.attributes;

                        let indexes = this.state.product.index;
                        let addItem = indexes[prod.id];

                        let colItem = attrib[0].options.find((item) => item.id == addItem["76"]);
                        let sizeItem = attrib[1].options.find((item) => item.id == addItem["77"]);

                        this.setState({
                            attributes: [
                                {
                                    attribute_name: "color",
                                    option_label: colItem?.label,
                                },
                                {
                                    attribute_name: "size",
                                    option_label: sizeItem?.label,
                                },
                            ],
                        });
                    }
                })
                .catch((error) => console.log(error));
        } else {
            this.checkAddProd = true;
            this.setState({
                product: this.props.product,
                simpleProduct: this.props.product,
                imagesData: this.props.product.images,
            });

            this.props.AddImages(this.props.product.images);
        }
    }

    sortObj = (obj) => {
        return Object.keys(obj)
            .sort()
            .reduce(function (result, key) {
                result[key] = Number(obj[key]);
                return result;
            }, {});
    };

    handleChangeQuantity = (quantity) => {
        this.setState({quantity});
    };
    handleChangeStyle = (event, code, id) => {
        // let itemList = [...document.getElementsByClassName(code + "_fm")];
        let itemList = document.getElementsByClassName(code + "_fm");
        let itemRadio = document.getElementById(code + id);

        for (let e of itemList) {
            e.style.borderColor = "#929292";
        }

        let item = document.getElementById(event.target.value + code);
        item.style.borderColor = "#FFD34E";

        event.target.checked = true;
        item.style.opacity = "1"
    };

    goToFirstIndex = () => {
        this.setState({
            goToFirstIndex: 1,
        });
        setTimeout(() => {
            this.setState({
                goToFirstIndex: null,
            });
        }, 5);
    };

    selectDef = (prod, simple) => {

        prod.attribute.attributes.forEach((element) => {

            this.setState({[element.code]: {id: prod.index[simple.id][element.id], code: element.code}});
        });
    };

    changeImage = (product) => {
        let data;
        for (let i in this.state.product.variant_images) {
            if (i === product[0]) {
                data = this.state.product.variant_images[i];
                break;
            }
        }
        this.props.AddImages(data);
    };

    handleChange = (product, type) => {
        let prod = this.state.product;
        let valueId = {};
        let attrCheck = {};
        let attr = {};
        let check = true;
        let prodId = null;

        this.props.product.super_attributes.forEach((e) => {
            attr = Object.assign(attr, {[e.code]: [...document.getElementsByName(e.code)]});
            attrCheck = Object.assign(attrCheck, {[e.code]: [false, e.id]});
        });

        for (let attrItem in attr) {
            for (let i of attr[attrItem]) {
                if (i.checked) {
                    valueId = Object.assign(valueId, {[attrCheck[attrItem][1]]: Number(i.value)});
                    attrCheck[attrItem][0] = true;
                }
            }
        }

        for (let attrItem in attrCheck) {
            if (attrCheck[attrItem][0] === false) {
                check = false;
                break;
            }
        }

        valueId = JSON.stringify(this.sortObj(valueId)).slice(1, -1)

        for (let item in prod.index) {
            let elem = JSON.stringify(this.sortObj(prod.index[item]));

            if (elem.includes(valueId)) {
                prodId = item;
            }
        }

        // if (check === false) {
        //     for (let attrItem in attrCheck) {
        //         let item = document.getElementById(`attr_${attrCheck[attrItem][1]}`);
        //         // item.style.display = 'none'
        //     }
        //     this.checkAddProd = true;
        // }

        let checkedArray = []
        this.state.product.attribute.attributes.forEach((element, i) => {

            if (element.code !== type && element.options.length !== 1) {
                element.options.forEach((option, index) => {
                    let item = document.getElementById(option.id + element.code);
                    let fill = []
                    for (let elem = 0; elem < product.length; elem++) {
                        fill.push(...option.products.filter((es) => es === product[elem]))
                    }
                    if (fill.length === 0) {
                        item.style.opacity = "0.4";
                        let radio = document.getElementById(element.code + option.id);
                        radio.checked = false
                    } else {
                        item.style.opacity = "1";
                        // break;
                    }
                });
            }

            let elementsArray = []
            for (let option of element.options) {
                elementsArray.push(document.getElementById(element.code + option.id).checked)
            }
            checkedArray.push(elementsArray.some(el => el === true))
        });

        this.setState({...this.state, isAllChecked: !checkedArray.some(el => el === false)})

        if (prodId) {
            let item = prod.variants.filter((e) => e.id === Number(prodId));

            this.cartProduct = item[0];

            this.setState({
                simpleProduct: this.cartProduct,
                imagesData: this.cartProduct.images,
                quantity: Boolean(this.cartProduct.min_qty) ? Number(this.cartProduct.min_qty) : 1
            });
            this.selectDef(this.state.product, this.cartProduct);
        }
    };

    render() {
        const {
            // layout,
            wishlistAddItem,
            // compareAddItem,
            cartAddItemNew,
            modalFunc,
        } = this.props;
        const {quantity, customer, imagesData, simpleProduct, product} = this.state;
        const wishListStorage = JSON.parse(localStorage.getItem("state"));
        let minQty = Boolean(simpleProduct?.min_qty) ? Number(simpleProduct?.min_qty) : 1;

        if (simpleProduct) {
            if (wishListStorage.wishlist.length) {
                if (wishListStorage.wishlist.filter((e) => e.id == simpleProduct.id).length)
                    Object.assign(simpleProduct, {wishlist: true});
                else {
                    Object.assign(simpleProduct, {wishlist: false});
                }
            } else {
                Object.assign(simpleProduct, {wishlist: false});
            }
        }

        const emptyImage = [
            {
                large_image_url:
                    " https://api.fooddepot.am/vendor/webkul/ui/assets/images/product/meduim-product-placeholder.png",
                medium_image_url:
                    " https://api.fooddepot.am/vendor/webkul/ui/assets/images/product/meduim-product-placeholder.png",
                small_image_url:
                    " https://api.fooddepot.am/vendor/webkul/ui/assets/images/product/meduim-product-placeholder.png",
                original_image_url:
                    " https://api.fooddepot.am/vendor/webkul/ui/assets/images/product/meduim-product-placeholder.png",
            },
        ];

        const singleQtyPrice = (
            <div className="product_price_fm">
                {simpleProduct?.old_price && (
                    <span className="dis_price_fm">
                        {Math.round(simpleProduct?.old_price)} -
                        <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                        <div></div>
                    </span>
                )}
                {Math.round(simpleProduct?.price)} <FormattedMessage id="global.curency" defaultMessage="AMD"/>
            </div>
        );

        return (
            <div>
                <div className="product_fm">
                    <div
                        className="product_img_fm"
                        // style={{backgroundImage: 'url("' + simpleProduct?.base_image.original_image_url + '")'}}
                    >
                        <ProductGallery
                            layout={"standard"}
                            ref="productGallery"
                            images={imagesData?.length >= 1 ? imagesData : emptyImage}
                            //   badges={this.badges[0]}
                            toFirst={this.state.goToFirstIndex}
                        />
                    </div>

                    <div className="product_info_fm">
                        <div className="product_name_fm">
                            <span>{simpleProduct?.name}</span>
                        </div>

                        {simpleProduct?.description ? (
                            <div
                                className="product_description_fm"
                                dangerouslySetInnerHTML={{__html: simpleProduct?.description}}
                            />
                        ) : (
                            ""
                        )}
                        {/* <div className="product_type_fm">Organic</div> */}
                        {Number(simpleProduct?.min_qty) <= 1 || !simpleProduct?.min_qty ? (
                            <>{singleQtyPrice}</>
                        ) : (
                            <>
                                <div className="product_price_fm_for_1">
                                    <span>
                                        <FormattedMessage id={"price_for_one"} defaultMessage="Price for 1"/>
                                    </span>
                                    {simpleProduct?.old_price && (
                                        <del>
                                            {Math.round(simpleProduct?.old_price)}{" "}
                                            <span>
                                                <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                            </span>
                                        </del>
                                    )}

                                    <span>{Math.round(simpleProduct?.price)} </span>
                                    <span>
                                        <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                    </span>
                                </div>
                                <div className="product_price_fm_for_pack">
                                    <span>
                                        <FormattedMessage id={"price_for_pack"} defaultMessage="Price for Pack"/>
                                        {Number(simpleProduct?.min_qty) > 1 && `(${Number(simpleProduct?.min_qty)})`}
                                    </span>
                                    <span> - </span>

                                    <span>{Math.round(simpleProduct?.price) * Number(simpleProduct?.min_qty)} </span>

                                    <span>
                                        <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                    </span>
                                </div>
                            </>
                        )}
                        {simpleProduct?.qty > 0 ? (
                            <div className="product_stock_fm">
                                <FormattedMessage id="wishList.inStock" defaultMessage="In stock"/>
                            </div>
                        ) : (
                            <div className="product_stock_fm" style={{color: "red"}}>
                                <FormattedMessage id="wishList.outOfStock" defaultMessage="Out of stock"/>
                            </div>
                        )}

                        <div className={"product_attributes"}>
                            <div className="product__meta">
                                {product && product?.attribute && product?.attribute?.attributes ? (
                                    product.attribute.attributes.map((attr, i) => {

                                        const label = (
                                            <div key={i} style={{fontWeight: 600, color: "#000"}}>
                                                <p>{attr.label}</p>
                                            </div>
                                        );

                                        if (attr.code !== "color") {
                                            return (
                                                <ConfigAttributes
                                                    attr={attr}
                                                    goToFirstIndex={this.goToFirstIndex}
                                                    handleChange={this.handleChange}
                                                    handleChangeStyle={this.handleChangeStyle}
                                                    label={label}
                                                    state={this.state}
                                                    key={i}
                                                />

                                            );
                                        } else {
                                            return (
                                                <div key={i} className="attr_color_fms">
                                                    {label}
                                                    {attr.options && attr.options.length && (
                                                        <div className="color-attr_fm">
                                                            {attr.options.map((e, i) => {
                                                                return (
                                                                    <div
                                                                        key={i}
                                                                        className="filter-color__item"
                                                                        id={e.id + attr.code}
                                                                    >
                                                                        <span
                                                                            className={classNames(
                                                                                "filter-color__check input-check-color",
                                                                                {
                                                                                    "input-check-color--white":
                                                                                        colorType(e.swatch_value) ===
                                                                                        "white",
                                                                                    "input-check-color--light":
                                                                                        colorType(e.swatch_value) ===
                                                                                        "light",
                                                                                }
                                                                            )}
                                                                            style={{color: `${e.swatch_value}`}}
                                                                        >
                                                                            <label className="input-check-color__body">
                                                                                <input
                                                                                    id={attr.code + e.id}
                                                                                    className="input-check-color__input"
                                                                                    name={attr.code}
                                                                                    type="radio"
                                                                                    value={e.id}
                                                                                    checked={
                                                                                        this.state[attr.code]?.id == e.id
                                                                                    }
                                                                                    onChange={(target) => {
                                                                                        // this.addAttributes(attr.code, e.label)
                                                                                        this.handleChange(
                                                                                            e.products,
                                                                                            attr.code
                                                                                        );
                                                                                        this.changeImage(e.products);
                                                                                        this.goToFirstIndex();
                                                                                    }}
                                                                                />

                                                                                <span
                                                                                    className="input-check-color__box"/>
                                                                                <Check12x9Svg
                                                                                    className="input-check-color__icon"/>
                                                                                <span
                                                                                    className="input-check-color__stick"/>
                                                                            </label>
                                                                        </span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        }
                                    })
                                ) : this.props.product.type === "configurable" ? (
                                    <>
                                        <div className="alternative-variants-block">
                                            <p>Color</p>
                                            <div className="animate-variants"/>
                                        </div>
                                        <div className="bottom-animate-variant">
                                            <p>Size</p>
                                            <div className="animate-variants"/>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        <div className="product_qty_fm">
                            <FormattedMessage id="global.quantity" defaultMessage="Quantity"/>:
                        </div>
                        <div className="block_1">
                            <InputNumber
                                id="product-quantity"
                                aria-label="Quantity"
                                className="product__quantity"
                                size="lg"
                                step={minQty}
                                min={minQty}
                                value={quantity}
                                onChange={this.handleChangeQuantity}
                            />

                            <div className="inner_actions_wrapper">
                                <div className="add_card_fm">
                                    {this.state.simpleProduct ? (
                                        <>
                                            <AsyncAction
                                                action={() => {
                                                    if (this.state.simpleProduct.images[0]?.path) {
                                                        this.state.simpleProduct.images = [
                                                            this.state.simpleProduct?.images[0]?.path,
                                                        ];
                                                    }

                                                    return cartAddItemNew(
                                                        this.state.simpleProduct,
                                                        quantity,
                                                    );
                                                }}
                                                render={({run, loading}) => (
                                                    <>
                                                        {product.type === "configurable" ? (
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    onClick={run}
                                                                    disabled={simpleProduct?.qty <= 0 || !this.state.isAllChecked}
                                                                    className={classNames({
                                                                        "btn-loading": loading,
                                                                        "disabled": simpleProduct?.qty <= 0 || !this.state.isAllChecked
                                                                    })}
                                                                    // disabled={!product?.attribute}
                                                                >
                                                                    <FormattedMessage
                                                                        id="add_to_cart"
                                                                        defaultMessage="Add to cart"
                                                                    />{" "}
                                                                    {quantity}
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    onClick={run}
                                                                    disabled={simpleProduct?.qty <= 0}
                                                                    className={classNames({
                                                                        "btn-loading": loading,
                                                                        "disabled": simpleProduct?.qty <= 0
                                                                    })}
                                                                    // disabled={
                                                                    //     product?.data?.attribute || !product.data.qty
                                                                    //         ? true
                                                                    //         : false
                                                                    // }
                                                                >
                                                                    <FormattedMessage
                                                                        id="add_to_cart"
                                                                        defaultMessage="Add to cart"
                                                                    />{" "}
                                                                    {Number(simpleProduct?.min_qty) > 1 ? quantity : ""}
                                                                </button>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </>
                                    ) : (
                                        <AsyncAction
                                            action={() => {
                                                let prod = this.state.simpleProduct
                                                    ? this.state.simpleProduct
                                                    : product;

                                                if (prod.images[0]?.path) {
                                                    prod.images = [prod?.images[0]?.path];
                                                }

                                                let attrib = this.state.product.data.attribute.attributes;
                                                let indexes = this.state.product.data.index;
                                                let addItem = indexes[prod.id];
                                                let colItem = attrib[0].options.find(
                                                    (item) => item.id == addItem["76"]
                                                );
                                                let sizeItem = attrib[1].options.find(
                                                    (item) => item.id == addItem["77"]
                                                );
                                                let x = [
                                                    {
                                                        attribute_name: "color",
                                                        option_label: colItem?.label,
                                                    },
                                                    {
                                                        attribute_name: "size",
                                                        option_label: sizeItem?.label,
                                                    },
                                                ];

                                                return cartAddItemNew(
                                                    prod,
                                                    quantity,
                                                );
                                            }}
                                            render={({run, loading}) => (
                                                <>
                                                    <button
                                                        type="button"
                                                        onClick={run}
                                                        disabled={!Boolean(simpleProduct?.qty)}
                                                        className={classNames("btn btn-primary", {
                                                            "btn-loading": loading,
                                                            "disabled": !Boolean(simpleProduct?.qty)
                                                        })}
                                                    >
                                                        <FormattedMessage
                                                            id="addtocart "
                                                            defaultMessage="Ավելացնել քարտին"
                                                        />
                                                    </button>
                                                </>
                                            )}
                                        />
                                    )}
                                </div>

                                <div>
                                    <AsyncAction
                                        action={() => {
                                            return wishlistAddItem(
                                                simpleProduct,
                                                customer.token ? customer.token : false,
                                                simpleProduct.wishlist
                                            ).then((e) => {
                                                if (e == false)
                                                    toast.success(
                                                        <FormattedMessage
                                                            id="please.login"
                                                            defaultMessage="`Please login or register in order to add to favorites.`"
                                                        />
                                                    );
                                            });
                                        }}
                                        render={({run, loading}) => (
                                            <button
                                                type="button"
                                                onClick={run}
                                                className={`wishList_button_fm ${classNames({
                                                    "btn-loading": loading,
                                                })}`}
                                            >
                                                {!simpleProduct?.wishlist ? (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="22.48"
                                                        height="20"
                                                        viewBox="0 0 22.48 20"
                                                    >
                                                        <path
                                                            id="heart"
                                                            d="M11.24,20a1.318,1.318,0,0,1-.869-.327c-.908-.794-1.784-1.54-2.556-2.2l0,0A47.539,47.539,0,0,1,2.23,12.233,8.367,8.367,0,0,1,0,6.756a7.026,7.026,0,0,1,1.783-4.8A6.045,6.045,0,0,1,6.28,0,5.654,5.654,0,0,1,9.812,1.219,7.226,7.226,0,0,1,11.24,2.71a7.227,7.227,0,0,1,1.428-1.491A5.654,5.654,0,0,1,16.2,0a6.045,6.045,0,0,1,4.5,1.957,7.026,7.026,0,0,1,1.783,4.8,8.366,8.366,0,0,1-2.229,5.477,47.534,47.534,0,0,1-5.581,5.238c-.774.659-1.651,1.407-2.561,2.2A1.319,1.319,0,0,1,11.24,20ZM6.28,1.317A4.743,4.743,0,0,0,2.751,2.85,5.716,5.716,0,0,0,1.317,6.756a7.04,7.04,0,0,0,1.927,4.637,46.782,46.782,0,0,0,5.421,5.077l0,0c.775.661,1.654,1.41,2.569,2.21.92-.8,1.8-1.552,2.578-2.214a46.793,46.793,0,0,0,5.421-5.076,7.041,7.041,0,0,0,1.927-4.637A5.716,5.716,0,0,0,19.729,2.85,4.742,4.742,0,0,0,16.2,1.317a4.367,4.367,0,0,0-2.727.944,6.39,6.39,0,0,0-1.52,1.758.831.831,0,0,1-1.428,0,6.384,6.384,0,0,0-1.52-1.758A4.367,4.367,0,0,0,6.28,1.317Zm0,0"
                                                            transform="translate(0 0)"
                                                            fill="#b0c472"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="22.48"
                                                        height="20"
                                                        viewBox="0 0 22.48 20"
                                                    >
                                                        <g
                                                            id="Сгруппировать_68760"
                                                            data-name="Сгруппировать 68760"
                                                            transform="translate(16954 18908)"
                                                        >
                                                            <path
                                                                id="heart"
                                                                d="M11.24,20a1.318,1.318,0,0,1-.869-.327c-.908-.794-1.784-1.54-2.556-2.2l0,0A47.539,47.539,0,0,1,2.23,12.233,8.367,8.367,0,0,1,0,6.756a7.026,7.026,0,0,1,1.783-4.8A6.045,6.045,0,0,1,6.28,0,5.654,5.654,0,0,1,9.812,1.219,7.226,7.226,0,0,1,11.24,2.71a7.227,7.227,0,0,1,1.428-1.491A5.654,5.654,0,0,1,16.2,0a6.045,6.045,0,0,1,4.5,1.957,7.026,7.026,0,0,1,1.783,4.8,8.366,8.366,0,0,1-2.229,5.477,47.534,47.534,0,0,1-5.581,5.238c-.774.659-1.651,1.407-2.561,2.2A1.319,1.319,0,0,1,11.24,20ZM.689,6.3c-1.364,0,8.505-.531,7.594.457A24,24,0,0,1,14.67,1.219c1.718-.9,4.074.161,4.074.738,0,.285.776,0,1.506.753A6.774,6.774,0,0,1,21.535,6.3a13.808,13.808,0,0,1-1.285,4.727c-.457.722-.927-.489-.522,0,1.288,1.554-10.524,4.329-2.3-.387,3.611,4.536-4.389,6.492-3.612,5.83a46.793,46.793,0,0,0,5.421-5.076,7.041,7.041,0,0,0,1.927-4.637A5.716,5.716,0,0,0,19.729,2.85,4.742,4.742,0,0,0,16.2,1.317a4.367,4.367,0,0,0-2.727.944c-.722.558,1.491-1.536,1.2-1.042-.152.254-6.091,9.806-6.387,9.806C19.434,4.721,8,16,7.275,15.443,6.465,14.817,1.689,6.3.689,6.3Z"
                                                                transform="translate(-16954 -18908)"
                                                                fill="#43b02a"
                                                            />
                                                        </g>
                                                    </svg>
                                                )}
                                            </button>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>


                        {/*<div className="share_fm">*/}
                        {/*    /!* <button className="fb_share_fm"> *!/*/}

                        {/*    <div className="share_buttons">*/}
                        {/*        <span> <FormattedMessage id="share" defaultMessage="Share"/> : </span>*/}
                        {/*        <div>*/}
                        {/*            <FacebookShareButton url={"http://fooddepot.am/shop/catalog?category_id=116"}>*/}
                        {/*                <FacebookIconSVG/>*/}
                        {/*            </FacebookShareButton>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*    /!* </button> *!/*/}
                        {/*</div>*/}
                    </div>
                </div>
                {product?.cats.length > 0 ? <RelatedItems product={product} modalFunc={modalFunc}/> : null}
            </div>
        );
    }
}

InnerProduct.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

InnerProduct.defaultProps = {
    layout: "standard",
};

const mapStateToProps = (state) => ({
    // cartToken: state.cart.cart_token,
    customer: state.customer,
    wishlist: state.wishlist,
});

const mapDispatchToProps = {
    cartAddItemNew,
    wishlistAddItem,
    AddImages,
};

export default connect(mapStateToProps, mapDispatchToProps)(InnerProduct);
