import React from 'react';
import {ArmeniaModalSVG, GBModalSVG, RussiaModalSVG, USAModalSVG} from '../svg';
import ArmSvg from '../svg/Arm.png';
import EngSvg from '../svg/Eng.png';
import RusSvg from '../svg/Rus.png';
import GBSvg from '../svg/GB.svg';

export const languages = [
    {
        title: 'English',
        formattedTitle:'English',
        locale: 'en',
        code: 'EN',
        icon: GBSvg,
        popupIcon:<GBModalSVG />,
        icon_srcset: 'images/languages/language-1.png 1x, images/languages/language-1@2x.png 2x',
    },
    {
        title: 'Armenian',
        formattedTitle:'Հայերեն',
        locale: 'hy',
        code: 'AM',
        icon: ArmSvg,
        popupIcon: <ArmeniaModalSVG />,
        icon_srcset: 'images/languages/language-2.png 1x, images/languages/language-2@2x.png 2x',
    },
    {
        title: 'Russian',
        formattedTitle:'Русский',
        locale: 'ru',
        code: 'RU',
        icon: RusSvg,
        popupIcon: <RussiaModalSVG />,
        icon_srcset: 'images/languages/language-2.png 1x, images/languages/language-2@2x.png 2x',
    },

];

// export function stringify(obj, replacer, spaces, cycleReplacer) {
//     return JSON.stringify(obj, serializer(replacer, cycleReplacer), spaces)
// }

// function serializer(replacer, cycleReplacer) {
//     var stack = [], keys = []

//     if (cycleReplacer == null) cycleReplacer = function (key, value) {
//         if (stack[0] === value) return "[Circular ~]"
//         return "[Circular ~." + keys.slice(0, stack.indexOf(value)).join(".") + "]"
//     }

//     return function (key, value) {
//         if (stack.length > 0) {
//             var thisPos = stack.indexOf(this)
//             ~thisPos ? stack.splice(thisPos + 1) : stack.push(this)
//             ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key)
//             if (~stack.indexOf(value)) value = cycleReplacer.call(this, key, value)
//         }
//         else stack.push(value)

//         return replacer == null ? value : replacer.call(this, key, value)
//     }
// }
