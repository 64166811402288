import React from 'react';

import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { url } from '../../helper';
import { FormattedMessage } from 'react-intl';
import { LogoSvg } from '../../svg';

export default function ForgotPassword(props) {

    const history = useHistory();
    const dispatch = useDispatch();
    const intialValues = { email: '' };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [doneMSG, setdoneMSG] = useState();
    const [doneMSGError, setdoneMSGError] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submitForm = () => {
        let option = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                formValues
            ),
        }

        fetch(`${url}/api/customer/forgot-password`, option)
            .then(responce => responce.json())
            .then(res => {
               // console.log(res)
                //setdoneMSG(res.message)
                if(res.error){
                    setdoneMSGError(res.error)
                    setdoneMSG('')
                }else{
                    setdoneMSG(res.message)
                    setdoneMSGError('')
                }
            })
            .catch(err => console.error(err));
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.email) {
            errors.email = "This field is required";
        } else if (!regex.test(values.email)) {
            errors.email = "Invalid email format";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);


    return (
        <div className="login_content_fm">
         <div className='new_logo_fm'>
            <LogoSvg />
            </div>
            <form action="" onSubmit={handleSubmit}>
                <div className={formErrors.email && "input-error_fm"}>
                    <img src="images/icon/envelope.png" alt="envelope" />
                   
                     <FormattedMessage id="global.email" defaultMessage="Email">
                        {(placeholder) => (
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="text"
                                name="email"
                                value={formValues.email}
                                onChange={handleChange}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.email && (
                        <span className="error">{formErrors.email}</span>
                    )}
                </div>
                <div className={doneMSGError ?"forgot_password_error":"forgot_password_successful"} >
                    {doneMSG ? doneMSG : doneMSGError}
                </div>
                <button> <FormattedMessage id="reset" defaultMessage="Reset" /> </button>
            </form>
            {/* <div className="sign_up_fm">
                <div className="singup_link_fm" onClick={() => { props.close('forgot', false); props.clicked('login', true) }}>
                        Already have an account?  <span>Sign In</span>
                </div>
            </div> */}
        </div>

    );
}
