// react
import React, { useEffect, useReducer, useState } from 'react';

// third-party
import { Link } from 'react-router-dom';
import shopApi from '../../api/shop';
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl';
import { url } from '../../helper';

export default function Brands() {
    const [brands, setBrands] = useState();
    const locale = useSelector(state => state.locale);

    useEffect(() => {
        fetch(`${url}/api/filters?locale=${locale}`)
            .then((response) => response.json())
            .then(res => {
                setBrands(res.data.brands)
            })
    }, [locale])

    return (
        <div className="brands_fm">
            <div className="container_fm">
                <div className="page-header__title">
                    <h1> <FormattedMessage id="brands" defaultMessage="Brands" /></h1>
                </div>
                {brands && <div className="brands_block_fm">
                    {brands.map(e =>
                        <Link to={`/shop/catalog?filter_brand=${e.id}`}>
                            <div>
                                <img src={e.path} alt="img" height="120px" />
                                <div>{e.label}</div>
                            </div>
                        </Link>
                    )}
                </div>}
            </div>
        </div>
    );
}
