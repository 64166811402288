import { AGE_ANSWER_ADD} from './age-answerActionTypes';

const initialState = null
export default function ageAnswerReducer(state = initialState, action) {
   
    switch(action.type){
       
        case AGE_ANSWER_ADD:
            return state = action.payload
        default:
            return state;
    }
   
    
}