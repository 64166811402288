import React from "react";

import {useState} from "react";
import {useEffect} from "react";
import {url} from "../../helper";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import {LogoSvg} from "../../svg";
import locale from "../../store/locale";
import {useSelector} from "react-redux";

export default function Register(props) {
    const intialValues = {
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        phone: "",
        password_confirmation: "",
    };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const locale = useSelector(state => state.locale)

    const submitForm = () => {
        let options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formValues),
        };


        fetch(`${url}/api/customer/register?locale=${locale}`, options)
            .then((responce) => responce.json())
            .then((res) => {
                if (res.message == "Your account has been created successfully.") {
                    toast.success(<FormattedMessage id="user_registered"
                                                    defaultMessage="Your account has been created successfully."/>)
                    props.close("reg", false);
                    props.clicked("login", true);
                } else {
                    if (res.email.length) {
                    }

                    if (res.password.length) {
                    }
                }
            })
            .catch((err) => console.error(err));
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    };
    const handleKeyPress = (event) => {
        const regex = /^[+]?\d*$/
        const key = event.key;
        const value = event.target.value + key;

        if (!regex.test(value)) {
            event.preventDefault();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const errors = {};
        if (!values.first_name) {
            errors.first_name = "This field is required";
        }

        if (!values.last_name) {
            errors.last_name = "This field is required";
        }

        if (!values.phone) {
            errors.phone = "This field is required";
        }

        if (!values.password_confirmation || values.password != values.password_confirmation) {
            errors.password_confirmation = "Passwords do not match";
        }

        if (!values.email || !regex.test(values.email)) {
            errors.email = "Invalid email format";
        }

        if (!values.password || values.password.length < 6) {
            errors.password = "Password must be more than 6 characters";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);

    return (
        <div className="login_content_fm">
            {/* <img className="sh_fm" src="images/logos/sh.png" alt="sh" /> */}
            <div className='new_logo_fm'>
                <LogoSvg/>
            </div>
            <div className="logn_title_fm">
                <FormattedMessage id="reg.letsGetStarted" defaultMessage="Let's Get Started!"/>
            </div>
            <div className="choose_fm">
                <label className="radio_fm">
                    <FormattedMessage id="reg.phys" defaultMessage="Ֆիզ Անձ"/>
                    <input type="radio" name="radio" checked={props.reg} readOnly={props.reg}/>
                    <span className="checkmark"></span>
                </label>
                <label className="radio_fm">
                    <FormattedMessage id="reg.loy" defaultMessage="Իրավաբանական Անձ"/>
                    <input
                        type="radio"
                        name="radio"
                        onClick={() => {
                            props.close("reg", false);
                            props.clicked("sign", true);
                        }}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>
            <form action="" onSubmit={handleSubmit}>
                <div className={formErrors.first_name && "input-error_fm"}>
                    <img src="images/icon/user12.png" alt="envelope"/>
                    <FormattedMessage id="account.firstName" defaultMessage="Firstname">
                        {(placeholder) => (
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="text"
                                name="first_name"
                                value={formValues.first_name}
                                onChange={handleChange}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.first_name && (
                        <span className="error">
                            <FormattedMessage id="account.error.required" defaultMessage={formErrors.first_name}/>
                        </span>
                    )}
                </div>
                <div className={formErrors.last_name && "input-error_fm"}>
                    <img src="images/icon/user12.png" alt="envelope"/>
                    <FormattedMessage id="account.lastName" defaultMessage="Lastname">
                        {(placeholder) => (
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="text"
                                name="last_name"
                                value={formValues.last_name}
                                onChange={handleChange}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.last_name && (
                        <span className="error">
                            <FormattedMessage id="account.error.required" defaultMessage={formErrors.last_name}/>
                        </span>
                    )}
                </div>
                <div className={formErrors.email && "input-error_fm"}>
                    <img src="images/icon/envelope.png" alt="password"/>
                    <FormattedMessage id="global.email" defaultMessage="Email">
                        {(placeholder) => (
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="text"
                                name="email"
                                value={formValues.email}
                                onChange={handleChange}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.email && (
                        <span className="error">
                            <FormattedMessage id="account.error.email" defaultMessage={formErrors.email}/>
                        </span>
                    )}
                </div>
                <div className={formErrors.phone && "input-error_fm"}>
                    <img src="images/icon/phone.png" alt="envelope"/>
                    <FormattedMessage id="reg.phone" defaultMessage="Phone">
                        {(placeholder) => (
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                // type="text"
                                type="tel"
                                name="phone"
                                value={formValues.phone}
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                                onTouchStart={handleKeyPress}
                            />
                        )
                        }
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.phone && (
                        <span className="error">
                            <FormattedMessage id="account.error.required" defaultMessage={formErrors.phone}/>
                        </span>
                    )}
                </div>
                <div className={formErrors.password && "input-error_fm"}>
                    <img src="images/icon/password.png" alt="password"/>
                    <FormattedMessage id="account.password" defaultMessage="Password">
                        {(placeholder) => (
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="password"
                                name="password"
                                value={formValues.password}
                                onChange={handleChange}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.password && (
                        <span className="error">
                            <FormattedMessage id="account.error.password" defaultMessage={formErrors.password}/>
                        </span>
                    )}
                </div>
                <div className={formErrors.password_confirmation && "input-error_fm"}>
                    <img src="images/icon/password.png" alt="envelope"/>
                    <FormattedMessage id="reg.confirm.password" defaultMessage="Confirm Password">
                        {(placeholder) => (
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="password"
                                name="password_confirmation"
                                value={formValues.password_confirmation}
                                onChange={handleChange}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.password_confirmation && (
                        <span className="error">
                            <FormattedMessage
                                id="account.error.passwordConfirmation"
                                defaultMessage={formErrors.password_confirmation}
                            />
                        </span>
                    )}
                </div>
                <button>
                    <FormattedMessage id="reg.sign.up" defaultMessage="SIGN UP"/>
                </button>
            </form>
            <div className="sign_up_fm">
                <div
                    className="singup_link_fm"
                    onClick={() => {
                        props.close("reg", false);
                        props.clicked("login", true);
                    }}
                >
                    <FormattedMessage id="reg.alreadyHaveAnAccount" defaultMessage="Already have an account?"/>
                    <span>
                        {" "}
                        <FormattedMessage id="reg.log.in" defaultMessage="Log In"/>
                    </span>
                </div>
            </div>
        </div>
    );
}
