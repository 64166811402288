import { UPDATE_CART_DATA } from "./cartActionTypes";
import shopApi from "../../api/shop";

const InitialState = {
    formated_grand_total: "",
    formated_sub_total: "",
    items: []
}

const dispatchValueActionHandle = ({
   grand_total,
   items,
   sub_total,

} = InitialState) => {
    return {
        type: UPDATE_CART_DATA,
        payload: {
            grand_total,
            sub_total,
            items,
            currency_symbol: "֏"
        },
    }
}

export function cartAddItemNew(
    { id },
    quantity,
    resolve
) {
    return async (dispatch, selector) => {
        try {
            const { locale, customer: { role: customerRole, token } } = selector()
            const customerToken = token ? { token } : {}
            const body = {
                api_token: localStorage.getItem("api_token"),
                product_id: id,
                quantity: quantity,
                ...customerToken,
                locale,
            };
            const { data } = await shopApi.cartAddItem({productId: id, body, customerRole});
            if (resolve !== undefined) resolve(true);
            dispatch(dispatchValueActionHandle(data || InitialState))
        } catch (error) {
            console.log(error);
        }
    }
}

export function cartRemoveItemNew(itemId) {
    return async (dispatch, selector) => {
        try {
            const { customer: { role: customerRole, token: customerToken } } = selector();
            const handleQueryParam = () => {
                let loggedIn = ""
                if (customerToken) {
                    loggedIn = `&token=${customerToken}`
                }
                return `?api_token=${localStorage.getItem("api_token")}${loggedIn}`
            }
            const { data } = await shopApi.cartRemoveItem({ productId: itemId, queryParam: handleQueryParam(), customerRole})
            dispatch(dispatchValueActionHandle(data  || InitialState))
        } catch (error) {
            console.log(error)
        }
    }
}

export function cartUpdateQuantitiesNew(quantities, cartItems) {
    const qty = quantities.reduce((obj, item) => {
        obj[item.itemId] = item.value;
        return obj;
    }, {});

    return async (dispatch, selector) => {
        try {
            const { customer: { role: customerRole, token: customerToken } } = selector();
            const customerTokenSetter = customerToken ? { token: customerToken } : {}
            const body = {
                ...customerTokenSetter,
                api_token: localStorage.getItem("api_token"),
                qty: qty,
            };
            const { data } = await shopApi.cartQuantityUpdate({body, customerRole})
            dispatch(dispatchValueActionHandle(data || InitialState))
        } catch (error) {
            console.log(error)
        }
    }
}

export function getCartDataMount(roleForLogin) {
    return async (dispatch, selector) => {
        const { customer: { role: customerRole, token: customerTokenForUrl }, locale } = selector();
        try {
            const customerToken = customerTokenForUrl ? `&token=${customerTokenForUrl}` : ""
            const { data } = await shopApi.getCartData(
                { cartToken: localStorage.getItem("api_token"),
                    customerToken,
                    locale,
                    customerRole: roleForLogin || customerRole
                })
            dispatch(dispatchValueActionHandle(data || InitialState))
        } catch (error) {
            console.log(error)
        }
    }
}
