// react
import React from "react";

// third-party
import classNames from "classnames";
import {connect, useSelector} from "react-redux";
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import PageHeader from "../shared/PageHeader";
import Rating from "../shared/Rating";
import {Cross12Svg} from "../../svg";
import {ArrowRoundedDown12x7Svg} from "../../svg";
import {url} from "../../services/utils";
import {wishlistRemoveItem} from "../../store/wishlist";
import ProductModal from "../blocks/ProductModal";
import {useState} from "react";
import {FormattedMessage} from "react-intl";

// data stubs
import theme from "../../data/theme";
import Breadcrumbs from "../shared/Breadcrumbs";

function ShopPageWishlist(props) {
    const {wishlist, wishlistRemoveItem, customer, location} = props;
    const [modal, SetModal] = useState(false);
    const [product, SetProduct] = useState(false);
    const locale = useSelector(state => state.locale)

    const modalFunc = (product) => {
        SetProduct(product);
        SetModal(!modal);
    };

    const closeModal = (product) => {
        SetModal(!modal);
    };

    let content;
    if (wishlist.length) {
        const itemsList = wishlist.map((item) => {
            let image;

            image = (
                <Link to={`/${locale}/shop/products/${product.url_key}`} style={{color: "unset"}}>
                    <div className="product-image">
                        <span
                            // onClick={() => {
                            //     modalFunc(item);
                            // }}
                            className="product-image__body"
                        >
                            <img className="product-image__img" src={item.base_image.original_image_url} alt=""/>
                        </span>
                    </div>
                </Link>
            );

            const renderAddToCarButton = ({run, loading}) => {
                const classes = classNames("btn btn_wish_fm btn-sm", {
                    "btn-loading": loading,
                });

                return (
                    <button type="button" onClick={run} className={classes}>
                        <FormattedMessage id="wishList.addToCart" defaultMessage="Add To Cart"/>
                    </button>
                );
            };

            const renderRemoveButton = ({run, loading}) => {
                const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
                    "btn-loading": loading,
                });

                return (
                    <button type="button" onClick={run} className={classes} aria-label="Remove">
                        <Cross12Svg/>
                    </button>
                );
            };

            return (
                <tr key={item.id} className="wishlist__row">
                    <td className="wishlist__column wishlist__column--image">{image}</td>
                    <td className="wishlist__column wishlist__column--product">
                        <Link to={`/${locale}/shop/products/${product.url_key}`} style={{color: "unset"}}>
                            <span
                                className="wishlist__product-name"
                                // onClick={() => {
                                //     modalFunc(item);
                                // }}
                            >
                                {item.name}
                            </span>
                        </Link>
                    </td>
                    <td className="wishlist__column wishlist__column--stock">
                        {item.in_stock ? (
                            <div className="badge badge-success">
                                <FormattedMessage id="wishList.inStock" defaultMessage="In Stock"/>
                            </div>
                        ) : (
                            <div className="badge badge-error">
                                <FormattedMessage id="wishList.outOfStock" defaultMessage="Out of Stock"/>
                            </div>
                        )}
                    </td>
                    <td className="wishlist__column wishlist__column--price">
                        <Currency value={+item.price}/>
                    </td>
                    {/* <td className="wishlist__column wishlist__column--tocart">
                        <AsyncAction
                            action={() => cartAddItem(item)}
                            render={renderAddToCarButton}
                        />
                    </td> */}
                    <td className="wishlist__column wishlist__column--remove">
                        <AsyncAction
                            action={() => wishlistRemoveItem(item.id, customer.token)}
                            render={renderRemoveButton}
                        />
                    </td>
                </tr>
            );
        });

        content = (
            <div className="block">
                {/*<ProductModal modal={modal} modalFunc={closeModal} data={product}/>*/}
                <div className="container">
                    <table className="wishlist">
                        <thead className="wishlist__head">
                        <tr className="wishlist__row">
                            <th className="wishlist__column wishlist__column--image">
                                <FormattedMessage id="wishList.image" defaultMessage="Image"/>
                            </th>
                            <th className="wishlist__column wishlist__column--product">
                                <FormattedMessage id="global.product" defaultMessage="Product"/>
                            </th>
                            <th className="wishlist__column wishlist__column--stock">
                                <FormattedMessage id="wishList.stockStatus" defaultMessage="Stock Status"/>
                            </th>
                            <th className="wishlist__column wishlist__column--price">
                                <FormattedMessage id="global.price" defaultMessage="Price"/>
                            </th>
                            {/* <th className="wishlist__column wishlist__column--tocart" aria-label="Add to cart" /> */}
                            <th className="wishlist__column wishlist__column--remove" aria-label="Remove"/>
                        </tr>
                        </thead>
                        <tbody className="wishlist__body">{itemsList}</tbody>
                    </table>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <div className="block-empty__message">
                            <FormattedMessage
                                id="wishList.YourWishListIsEmpty!"
                                defaultMessage="Your wish list is empty!"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const breadcrumb = [
        {title: <FormattedMessage id="home" defaultMessage="home"/>, url: url},
        {title: <FormattedMessage id="account.wishlist" defaultMessage="Wishlist"/>, url: location.pathname},
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Wish List — ${theme.name}`}</title>
            </Helmet>
            <Breadcrumbs breadcrumb={breadcrumb}/>
            <PageHeader header=<FormattedMessage id="account.wishlist" defaultMessage="Wishlist"/>/>
            {content}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
    customer: state.customer,
});

const mapDispatchToProps = {
    wishlistRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageWishlist);
