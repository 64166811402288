// react
import React from 'react';

import Dropdown from './Dropdown';
import DropdownCurrency from './DropdownCurrency';
import DropdownLanguage from './DropdownLanguage';
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react';
function Topbar() {
    let location = useLocation()
    useEffect(
        () => { },
        [location]
    )
    const links = [
        { title: '(+374) 41 373 373' },
        { title: 'info@fooddepot.am' }
    ];

    // const accountLinks = [
    //     { title: 'Dashboard', url: '/account/dashboard' },
    //     { title: 'Edit Profile', url: '/account/profile' },
    //     { title: 'Order History', url: '/account/orders' },
    //     { title: 'Addresses', url: '/account/addresses' },
    //     { title: 'Password', url: '/account/password' },
    //     { title: 'Logout', url: '/account/login' },
    // ];

    const linksList = links.map((item, index) => (
        <div key={index} className="topbar__item topbar__item--link d-none d-md-flex">
            <p className="topbar-link">{item.title}</p>
        </div>
    ));

    return (
        <div className={"site-header__topbar topbar "}>
            <div className="topbar__container">
                <div className="topbar__row">
                    {linksList}
                    <div className="topbar__spring topbar__item--link d-none d-md-block" />
                    <div className={"topbar__item " + (location.pathname != "/" ? 'header_nav_fm' : '')}>
                        <DropdownLanguage />
                    </div>
                    <div className={"topbar__item " + (location.pathname != "/" ? 'header_nav_fm' : '')}>
                        {/* <DropdownCurrency /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Topbar;
