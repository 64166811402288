// react
import React, {useCallback, useEffect, useState} from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import {connect} from "react-redux";

// application
//  import ProductCard from '../shared/ProductCard';
import Product from "../blocks/Product";
import {LayoutGrid16x16Svg, LayoutGridWithDetails16x16Svg, LayoutList16x16Svg} from "../../svg";
import {sidebarOpen} from "../../store/sidebar";
import {FormattedMessage} from "react-intl";
import {useLocation} from "react-router-dom";

// function useSetOption(option, filter, dispatch) {
//     const callback = useCallback(filter, []);
//
//     return useCallback(
//         (data) => {
//             dispatch({
//                 type: "SET_OPTION_VALUE",
//                 option,
//                 value: callback(data),
//             });
//         },
//         [option, callback, dispatch]
//     );
// }

function ProductsView(props) {
    const {
        isLoading,
        productsList,
        layout: propsLayout,
        grid,
        offcanvas,
        fromSearch = false
    } = props;
    const [layout, setLayout] = useState(propsLayout);
    const [modal, SetModal] = useState(false);
    const location = useLocation()

    // const total = Math.ceil((productsList?.meta?.total || productsList.length) / (options.limit || 6));
    // const handlePageChange = useSetOption("page", parseFloat, dispatch);
    // const handleSortChange = useSetOption("sort", (event) => event.target.value, dispatch);
    // const handleLimitChange = useSetOption("limit", (event) => parseFloat(event.target.value), dispatch);

    // const modalFunc = (product) => {
    //     SetModal(!modal);
    // };

    // const closeModal = (product) => {
    //     SetModal(!modal);
    // };
    //
    // const handleResetFilters = useCallback(() => {
    //     dispatch({type: "RESET_FILTERS"});
    // }, [dispatch]);
    //
    // const filtersCount = Object.keys(filters)
    //     .map((x) => filters[x])
    //     .filter((x) => x).length;
    let viewModes = [
        {key: "grid", title: "Grid", icon: <LayoutGrid16x16Svg/>},
        {key: "grid-with-features", title: "Grid With Features", icon: <LayoutGridWithDetails16x16Svg/>},
        {key: "list", title: "List", icon: <LayoutList16x16Svg/>},
    ];

    // viewModes = viewModes.map((viewMode) => {
    //     const className = classNames("layout-switcher__button", {
    //         "layout-switcher__button--active": layout === viewMode.key,
    //     });
    //
    //     return (
    //         <button
    //             key={viewMode.key}
    //             title={viewMode.title}
    //             type="button"
    //             className={className}
    //             onClick={() => setLayout(viewMode.key)}
    //         >
    //             {viewMode.icon}
    //         </button>
    //     );
    // });
    const buildData = productsList?.data || productsList;
    const productsListItems = buildData.map((product, i) => <Product product={product} key={i}/>);

    const rootClasses = classNames("products-view", {
        "products-view--loading": isLoading,
    });

    const viewOptionsClasses = classNames("view-options", {
        "view-options--offcanvas--always": offcanvas === "always",
        "view-options--offcanvas--mobile": offcanvas === "mobile",
    });

    let content;
    if (productsListItems.length > 0) {
        content = (
            <div className="products-view__content">
                {/* <div className="products-view__options">
                    <div className={viewOptionsClasses}>
                        <div className="view-options__filters-button">
                            <button type="button" className="filters-button" onClick={() => sidebarOpen()}>
                                <Filters16Svg className="filters-button__icon" />
                                <span className="filters-button__title">Filters</span>
                                {!!filtersCount && <span className="filters-button__counter">{filtersCount}</span>}
                            </button>
                        </div>

                        <div className="view-options__control">
                            <label htmlFor="view-options-sort">Sort By</label>
                            <div>
                                <select
                                    id="view-options-sort"
                                    className="form-control form-control-sm"
                                    value={options.sort || productsList.sort}
                                    onChange={handleSortChange}
                                >
                                    <option value="default">Default</option>
                                    <option value="name_asc">Name (A-Z)</option>
                                    <option value="name_desc">Name (Z-A)</option>
                                </select>
                            </div>
                        </div>
                        <div className="view-options__control">
                            <label htmlFor="view-options-limit">Show</label>
                            <div>
                                <select
                                    id="view-options-limit"
                                    className="form-control form-control-sm"
                                    value={options.limit || productsList.limit}
                                    onChange={handleLimitChange}
                                >
                                    <option value="6">6</option>
                                    <option value="12">12</option>
                                    <option value="18">18</option>
                                    <option value="24">24</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div
                    className="products-view__list products-list"
                    data-layout={layout !== "list" ? grid : layout}
                    data-with-features={layout === "grid-with-features" ? "true" : "false"}
                >
                    <div className="products-list__body">
                        <div className="product_lists_fm product_lists_fme">
                            <div className="cat_list_fm product_lists_fm">
                                <div className="category_list_fm">
                                    {/* <div className="cat_title_fm">
                                        {categorySlug}
                                        <div className="cat_line_fm"></div>
                                    </div> */}
                                    <div className="cat_items_fm">{productsListItems}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="products-view__pagination">
                    {total > 1 ? (
                        <Pagination
                            current={options.page || productsList.meta.current_page}
                            siblings={2}
                            total={productsList.meta.last_page} //{Math.ceil(productsList.meta.total / (options.limit || 6))}
                            onPageChange={handlePageChange}
                        />
                    ) : (
                        ""
                    )}
                </div> */}
            </div>
        );
    } else {
        content = (
            <div className="products-view__empty">
                <div className="products-view__empty-title">
                    <FormattedMessage id="no.matching.items" defaultMessage="No matching items"/>
                </div>
                {
                    !fromSearch && <div className="products-view__empty-subtitle">
                        <FormattedMessage id="try.resetting.the.filters" defaultMessage="Try resetting the filters"/>
                    </div>
                }

                {/* <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={handleResetFilters}
                >
                    <FormattedMessage id="reset" defaultMessage="Reset" />
                </button> */}
            </div>
        );
    }

    return (
        <div className={rootClasses}>
            {/*<div className="products-view__loader"/>*/}
            {content}
        </div>
    );
}

ProductsView.propTypes = {
    /**
     * Indicates that products is loading.
     */
    isLoading: PropTypes.bool,
    /**
     * ProductsList object.
     */
    productsList: PropTypes.object,
    /**
     * Products list options.
     */
    options: PropTypes.object,
    /**
     * Products list filters.
     */
    filters: PropTypes.object,
    /**
     * Category page dispatcher.
     */
    dispatch: PropTypes.func,
    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(["grid-3-sidebar", "grid-4-full", "grid-5-full"]),
    /**
     * indicates when sidebar should be off canvas
     */
    offcanvas: PropTypes.oneOf(["always", "mobile"]),
};

ProductsView.defaultProps = {
    layout: "grid",
    grid: "grid-3-sidebar",
    offcanvas: "mobile",
};

const mapDispatchToProps = {
    sidebarOpen,
};

export default connect(() => ({}), mapDispatchToProps)(ProductsView);
