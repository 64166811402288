import {combineReducers} from 'redux';

// reducers
import cartReducer from './cart';
import orderReducer from './order';
import compareReducer from './compare';
import currencyReducer from './currency';
import localeReducer from './locale';
import loginReducer from './login';
import mobileMenuReducer from './mobile-menu';
import quickviewReducer from './quickview';
import sidebarReducer from './sidebar';
import version from './version';
import wishlistReducer from './wishlist';
import customerReducer from './customer';
import generalReducer from './general';
import categoryNameReducer from './category'
import categoryRoutesReducer from './category-routes'
import ageAnswerReducer from './age-answer';
import imagesReducer from "./images";
import pageReducer from "./pages";

export default combineReducers({
    version: (state = version) => state,
    cart: cartReducer,
    compare: compareReducer,
    currency: currencyReducer,
    locale: localeReducer,
    mobileMenu: mobileMenuReducer,
    quickview: quickviewReducer,
    sidebar: sidebarReducer,
    wishlist: wishlistReducer,
    customer: customerReducer,
    login: loginReducer,
    order: orderReducer,
    general: generalReducer,
    categoryPage: categoryNameReducer,
    categoryRoutes: categoryRoutesReducer,
    ageAnswer: ageAnswerReducer,
    images: imagesReducer,
    pages: pageReducer,
});
