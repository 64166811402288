// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";

// data stubs
import theme from "../../data/theme";
import { Modal } from "reactstrap";
import { Cross20Svg } from "../../svg";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { url } from "../../helper";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";

export default function AccountPageAddresses() {
    const customer = useSelector((state) => state.customer);
    const costumerData = JSON.parse(localStorage.getItem("userData"));
    const [data, SetData] = useState([]);
    const [modal, SetModal] = useState(false);
    const [check, SetCheck] = useState(false);

    const intialValues = {
        address1: "",
        country: "AM",
        state: "Yerevan",
        city: "Yerevan",
        postcode: "1100",
        phone: "",
    };
    useEffect(() => {
        fetch(`${url}/api/addresses?token=${customer.token}`)
            .then((responce) => responce.json())
            .then(({ data }) => {
                if (data.length) {
                    SetData(data);
                    // setFormValues(data);
                }
            })
            .catch((err) => console.error(err));
    }, []);

    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        let errors = {};

        if (!values.address1) {
            errors.address1 = "This field is required";
        }

        if (!values.city) {
            errors.city = "This field is required";
        }

        if (!values.phone) {
            errors.phone = "This field is required";
        }

        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);

    const submitForm = () => {
        if (typeof formValues.address1 == "string") formValues.address1 = [formValues.address1];
        let obj = Object.assign(formValues, {
            token: customer.token,
            first_name: costumerData.first_name,
            last_name: costumerData.last_name,
        });
        let option = {
            method: check ? "PUT" : "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(obj),
        };
        if (check)
            fetch(`${url}/api/addresses/${data.id}`, option)
                .then((responce) => responce.json())
                .then((res) => {
                    toast.success(res.message);
                    // SetData(res.data);
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].id == res.data.id) {
                            data[i] = res.data;
                            SetData([...data]);
                            modalFunc();
                            return;
                        }
                    }
                })
                .catch((err) => console.error(err));
        else
            fetch(`${url}/api/addresses/create`, option)
                .then((responce) => responce.json())
                .then((res) => {
                    let message = <FormattedMessage id="created.successfully" defaultMessage={res.message}/>
                    toast.success(res.message);
                    SetData([{ ...res.data }, ...data]);
                    modalFunc();
                })
                .catch((err) => console.error(err));
    };

    const modalFunc = () => {
        SetModal(!modal);
    };

    const removeAddress = (id) => {
        if (customer.token) {
            let option = {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: customer.token,
                }),
            };
            fetch(`${url}/api/addresses/${id}`, option)
                .then((responce) => responce.json())
                .then((res) => {
                    if (res) {
                        let message = <FormattedMessage id="removed.successfully" defaultMessage={res.message}/>
                        toast.success(message);
                        SetData(data.filter((e) => e.id !== id));
                    }
                })
                .catch((err) => console.error(err));
        }
    };

    return (
        <>
            <Modal className="account_modal_fm" isOpen={modal} centered size="xl">
                <div className="quickview">
                    <button
                        className="quickview__close"
                        type="button"
                        onClick={() => {
                            modalFunc();
                        }}
                    >
                        <Cross20Svg />
                    </button>
                    <form action="" onSubmit={handleSubmit}>
                        <div className="title">
                            <FormattedMessage
                                id="account.editAccountInformation"
                                defaultMessage="Edit account information"
                            />
                        </div>
                        <div>
                            <FormattedMessage id="global.address" defaultMessage="Address">
                                {(placeholder) => (
                                    <input
                                        type="text"
                                        placeholder={`${placeholder}`.replace(' city,', '')}
                                        name="address1"
                                        id="address1"
                                        value={formValues.address1}
                                        onChange={handleChange}
                                    />
                                )}
                            </FormattedMessage>
                        </div>
                        <div>
                            {formErrors.address1 && (
                                <span className="error">
                                    <FormattedMessage
                                        id="account.error.required"
                                        defaultMessage={formErrors.address1}
                                    />
                                </span>
                            )}
                        </div>
                        <div>
                            <FormattedMessage id="global.country" defaultMessage="Country">
                                {(placeholder) => (
                                    <input
                                        type="text"
                                        placeholder={placeholder}
                                        name="country"
                                        id="country"
                                        value={formValues.country}
                                        onChange={handleChange}
                                        disabled="disabled"
                                    />
                                )}
                            </FormattedMessage>
                        </div>
                        <div>
                            {formErrors.country && (
                                <span className="error">
                                    <FormattedMessage id="account.error.required" defaultMessage={formErrors.country} />
                                </span>
                            )}
                        </div>
                        <div>
                            <FormattedMessage id="global.city" defaultMessage="City">
                                {(placeholder) => (
                                    <input
                                        type="text"
                                        placeholder={placeholder}
                                        name="city"
                                        id="city"
                                        value={formValues.city}
                                        onChange={handleChange}
                                        disabled={true}
                                    />
                                )}
                            </FormattedMessage>
                        </div>
                        <div>
                            {formErrors.city && (
                                <span className="error">
                                    <FormattedMessage id="account.error.required" defaultMessage={formErrors.city} />
                                </span>
                            )}
                        </div>
                        <div>
                            <FormattedMessage id="global.phoneNumber" defaultMessage="Phone number">
                                {(placeholder) => (
                                    <input
                                        type="text"
                                        placeholder={placeholder}
                                        id="phone"
                                        name="phone"
                                        value={formValues.phone}
                                        onChange={handleChange}
                                    />
                                )}
                            </FormattedMessage>
                        </div>
                        <div>
                            {formErrors.phone && (
                                <span className="error">
                                    <FormattedMessage id="account.error.required" defaultMessage={formErrors.phone} />
                                </span>
                            )}
                        </div>
                        <div className="butt_block_fm">
                            <div
                                className="cancel_modal_fm cursor_fm"
                                onClick={() => {
                                    modalFunc();
                                }}
                            >
                                <FormattedMessage id="account.cancel" defaultMessage="Cancel" />
                            </div>
                            <div className="change_modal_fm">
                                <button>
                                    {check ? (
                                        <FormattedMessage id="account.save" defaultMessage="Save" />
                                    ) : (
                                        <FormattedMessage id="account.create" defaultMessage="Create" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            {data.length ? (
                <div>
                    <Helmet>
                        <title>{`Addresses — ${theme.name}`}</title>
                    </Helmet>
                    {data && (
                        <div className="account_my_address">
                            {data.map(({ id, address1, country, city, phone }) => {
                                return (
                                    <div key={id}>
                                        <div>
                                            <div className="account__set_fm pers_info_fm">
                                                <div>
                                                    <FormattedMessage id="global.address" defaultMessage="Address" />
                                                </div>
                                                <div>{address1[0]}</div>
                                            </div>

                                            <div className="account__set_fm pers_info_fm">
                                                <div>
                                                    <FormattedMessage id="global.country" defaultMessage="Country" />
                                                    {"  "}/{"  "}
                                                    <FormattedMessage id="global.city" defaultMessage="City" />
                                                </div>
                                                <div>
                                                    {country}
                                                    {"  "}/{"  "}
                                                    {city}
                                                </div>
                                            </div>

                                            {/* <div className="account__set_fm pers_info_fm">
                                    <div>
                                        <FormattedMessage id="global.city" defaultMessage="City" />
                                    </div>
                                    <div>{city}</div>
                                </div>
                                */}
                                            <div className="account__set_fm pers_info_fm">
                                                <div>
                                                    <FormattedMessage id="account.phone" defaultMessage="Phone" />
                                                </div>
                                                <div>{phone}</div>
                                            </div>
                                        </div>
                                        <div className="account__set_fm">
                                            <span
                                                style={{ color: "#43B02A" }}
                                                className="cursor_fm"
                                                onClick={() => {
                                                    modalFunc();
                                                    SetCheck(true);
                                                    setFormValues(data.filter((e) => e.id == id)[0]);
                                                }}
                                            >
                                                <FormattedMessage id="account.change" defaultMessage="Change" />
                                            </span>
                                            <span onClick={() => removeAddress(id)} className="address-remove-button">
                                                <FormattedMessage id="remove" defaultMessage="Remove" />
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            ) : null}

            <div
                style={{ color: "#43B02A" }}
                className="cursor_fm"
                onClick={() => {
                    modalFunc();
                    SetCheck(false);
                    setFormValues(intialValues);
                }}
            >
                <FormattedMessage id="account.add" defaultMessage="Add" />
            </div>
        </>
    );
}
