// react
import React, {Component} from 'react';

// third-party
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';

// application
import Pagination from '../shared/Pagination';
import {connect} from 'react-redux';
import {url} from '../../helper';
import {FormattedMessage} from 'react-intl';

// data stubs
import theme from '../../data/theme';

import {saveOrderDetails} from '../../store/order';

class AccountPageOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: null,
            page: 1,
            customer: this.props.customer,
            locale: this.props.locale,
            pages: null,
            selectDate: [],
            subUsersOrdersForReduxCase: null,
        };
    }

    handlePageChange = (page) => {
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        let yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        today = yyyy + '-' + mm + '-' + dd;
        const makeFromDate = this.state.selectDate[0] ? this.state.selectDate[0] : '2021-01-01';
        const makeToDate = this.state.selectDate[1] ? this.state.selectDate[1] : today;

        this.setState(() => ({page}));

        fetch(
            `${url}/api/history?token=${this.state.customer.token}&locale=${this.state.locale}&limit=8&pagination=true&page=${page}&dateFrom=${makeFromDate}&dateTo=${makeToDate}`
        )
            // fetch(`${url}/api/history?token=${this.state.customer.token}&limit=8&page=${page}`) //&locale=${this.state.locale}
            .then((responce) => responce.json())
            .then((res) => {
                if (res.data.length) {
                    this.setState({orders: res.data});
                }
            });
    };

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevState.selectDate) !== JSON.stringify(this.state.selectDate)) {
            const makeFromDate = this.state.selectDate[0] ? this.state.selectDate[0] : '2021-01-01';
            const makeToDate = this.state.selectDate[1] ? this.state.selectDate[1] : '2021-05-14';

            // const makeFromDate = fromArrayFrom ? `${fromArrayFrom[2]}-${fromArrayFrom[0]}-${fromArrayFrom[1]}` : null;
            // const makeToDate = fromArrayTo ? `${fromArrayTo[2]}-${fromArrayTo[0]}-${fromArrayTo[1]}` : today

            let today = new Date();
            let dd = today.getDate();
            let mm = today.getMonth() + 1;
            let yyyy = today.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            today = yyyy + '-' + mm + '-' + dd;

            if (makeFromDate || makeToDate) {
                let newUrl = '';
                if (this.props.customer.role === 'subUser') {
                    newUrl = `${url}/api/checkout/group-user/cart/history/${makeFromDate}/${makeToDate}?token=${this.state.customer.token}&limit=8`;
                } else {
                    newUrl = `${url}/api/history?token=${this.state.customer.token}&limit=8&pagination=true&page=1&locale=${this.state.locale}&dateFrom=${makeFromDate}&dateTo=${makeToDate}`;
                }

                fetch(newUrl)
                    .then((responce) => responce.json())
                    .then((res) => {
                        if (this.props.customer.role === 'subUser') {
                            const keys = Object.keys(res);
                            const array = keys.map((key) => {
                                let createDate = '';
                                let total = 0;
                                for (let i = 0; i < res[key].length; i++) {
                                    const item = res[key][i];
                                    total += item.product_price * item.quantity;
                                    createDate = item.created_at;
                                }

                                return {id: key, base_grand_total: total, created_at: `${createDate}`.split(' ')[0]};
                            });

                            this.setState({orders: array, pages: res.meta, subUsersOrdersForReduxCase: res});
                        } else {
                            this.setState({orders: res.data, pages: res.meta});
                        }
                    });
            }
        }
    }

    componentDidMount() {
        let newUrl = '';

        let today = new Date();
        let dd = today.getDate();

        let mm = today.getMonth() + 1;
        let yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        today = yyyy + '-' + mm + '-' + dd;

        if (this.props.customer.role === 'subUser') {
            newUrl = `${url}/api/checkout/group-user/cart/history/2021-01-01/${today}?token=${this.state.customer.token}&limit=8`;
        } else {
            newUrl = `${url}/api/history?token=${this.state.customer.token}&limit=8&pagination=true&page=1&locale=${this.state.locale}&dateFrom=2021-01-01&dateTo=${today}`;
        }

        fetch(newUrl)
            .then((responce) => responce.json())
            .then((res) => {
                if (this.props.customer.role === 'subUser') {
                    const keys = Object.keys(res);
                    const array = keys.map((key) => {
                        let createDate = '';
                        let total = 0;
                        for (let i = 0; i < res[key].length; i++) {
                            const item = res[key][i];
                            total += item.product_price * item.quantity;
                            createDate = item.created_at;
                        }

                        return {id: key, base_grand_total: total, created_at: `${createDate}`.split(' ')[0]};
                    });

                    this.setState({orders: array, pages: res.meta, subUsersOrdersForReduxCase: res});
                } else {
                    if (res.data.length) this.setState({orders: res.data, pages: res.meta});
                }
            });
    }

    changeDate(e) {
        if (this.state.customer.role === 'subUser') {
            const [year, month, day] = e.split('-');
            return `${day}.${month}.${year}`;
        } else {
            let date = e.slice(1, 10);
            const [year, month, day] = date.split('-');
            return `${day}.${month}.${year}`;
        }
    }

    handleSetDate(value, key) {
        if (key === '0') {
            this.setState({selectDate: [value, this.state.selectDate[1]]});
        } else {
            this.setState({selectDate: [this.state.selectDate[0], value]});
        }
    }

    render() {
        const {page, orders, pages, customer} = this.state;
        const {locale} = this.props;
        const customerLimit = customer.limit


        const orderStatusColor = (status) => {
            switch (status) {
                case ('pending'):
                    return '#FFA500';
                case ('completed'):
                    return '#006400';
                case ('canceled'):
                    return '#FF0000';
                case ('closed'):
                    return '#0000FF';
                case ('processing'):
                    return '#16FF00';
                default:
                    return '#000000';
            }
        };

        // fizikakan

        const ordersList = orders
            ? orders.map((order) => (
                <tr key={order.id} onClick={this.a}>
                    <td
                        onClick={() => {
                            if (this.state.customer.role === 'subUser') {
                                this.props.saveOrderDetails(this.state.subUsersOrdersForReduxCase[order.id]);
                            }
                        }}
                    >
                        <Link to={`/${locale}/orderDetile/${order.id}`}>
                            #
                            {order.id}
                        </Link>
                    </td>
                    {this.state.customer.role !== "subUser" &&
                        <td className="order_satus_fm" style={{color: orderStatusColor(order.status)}}>
                            {order.status}
                        </td>}
                    <td>{this.changeDate(order.created_at)}</td>
                    <td>
                        {Math.round(order.base_grand_total)}
                        {' '}
                        <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                    </td>
                </tr>
            ))
            : '';
        const ordersListMob = orders
            ? orders.map((order) => {
                return (
                    <div className="order_mobile_content_fms">
                        <div>
                            <div>
                                <FormattedMessage id="order.id" defaultMessage="ID"/>
                            </div>
                            <Link to={`/${locale}/orderDetile/${order.id}`}>
                                <div>
                                    #
                                    {order.id}
                                </div>
                                {' '}
                            </Link>
                        </div>
                        {this.state.customer.role === 'Manager' && (
                            <div>
                                <div>
                                    <FormattedMessage id="order.status" defaultMessage="Status"/>
                                </div>
                                <div className="order_del_fm">{order.status}</div>
                            </div>
                        )}

                        <div>
                            <div>
                                <FormattedMessage id="order.date" defaultMessage="Date"/>
                            </div>
                            <div>{this.changeDate(order.created_at)}</div>
                        </div>
                        <div>
                            <div>
                                <FormattedMessage id="global.total" defaultMessage="Total"/>
                            </div>
                            <div>
                                {Math.round(order.base_grand_total)}
                                AMD
                            </div>
                        </div>
                    </div>
                )
            })
            :
            '';

        return (
            <>
                <div className="account_credit_balance">
                    <div><FormattedMessage id="global.creditBalance" defaultMessage="Credit balance"/> :</div>
                    <div>{customerLimit ? customerLimit : ''}</div>
                </div>
                <div className="card">
                    <Helmet>
                        <title>{`Order History — ${theme.name}`}</title>
                    </Helmet>


                    <div className="card-table d-none d-md-block">
                        <div className="table-responsive-sm">
                            <div className="set-history-date-container">
                                <div>
                                    <label htmlFor="fromdate">
                                        <FormattedMessage id="from" defaultMessage="From"/>
                                    </label>
                                    <input
                                        type="date"
                                        id="fromdate"
                                        placeholder="MM/DD/YYYY"
                                        onChange={(e) => this.handleSetDate(e.target.value, '0')}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="todate">
                                        <FormattedMessage id="to" defaultMessage="To"/>
                                    </label>
                                    <input
                                        type="date"
                                        id="todate"
                                        placeholder="MM/DD/YYYY"
                                        onChange={(e) => this.handleSetDate(e.target.value, '1')}
                                    />
                                </div>
                            </div>

                            {ordersList ? (
                                <table>
                                    <thead>
                                    <tr>
                                        <th>
                                            <FormattedMessage id="order.id" defaultMessage="ID"/>
                                        </th>
                                        {this.state.customer.role !== 'subUser' && (
                                            <th>
                                                <FormattedMessage id="order.status" defaultMessage="Status"/>
                                            </th>
                                        )}

                                        <th>
                                            <FormattedMessage id="order.date" defaultMessage="Date"/>
                                        </th>
                                        <th>
                                            <FormattedMessage id="global.total" defaultMessage="Total"/>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>{!ordersList[0] ? '' : ordersList}</tbody>
                                </table>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="order_mobile_list_fm  d-block d-md-none">
                        <div className="set-history-date-container">
                            <div>
                                <label htmlFor="fromdate">
                                    <FormattedMessage id="from" defaultMessage="From"/>
                                </label>
                                <input
                                    type="date"
                                    id="fromdate"
                                    placeholder="MM/DD/YYYY"
                                    onChange={(e) => this.handleSetDate(e.target.value, '0')}
                                />
                            </div>
                            <div>
                                <label htmlFor="todate">
                                    <FormattedMessage id="to" defaultMessage="To"/>
                                </label>
                                <input
                                    type="date"
                                    id="todate"
                                    placeholder="MM/DD/YYYY"
                                    onChange={(e) => this.handleSetDate(e.target.value, '1')}
                                />
                            </div>
                        </div>
                        {ordersListMob ? ordersListMob : ''}
                    </div>
                    <div className="card-divider"/>
                    <div className="card-footer">
                        {pages ? (
                            <Pagination current={page} total={pages.last_page} onPageChange={this.handlePageChange}/>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    customer: state.customer,
    locale: state.locale,
});

const mapDispatchToProps = {
    saveOrderDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageOrders);
