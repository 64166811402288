import React from "react"
import foodLogo from "../svg/foodLogo.png"

const Hided = () => {

    return <>

        <div className="comming_soon_wrapper">

            <div className="logo_comming">
                <div>
                    <img src={foodLogo} alt="" width="100%" height="100%"/>
                </div>
                <div>
                    <h1>Coming Soon</h1>
                </div>
            </div>

            <div className="info_comming">
                <ul>
                    <li>Yerevan, Armenia 0079 Mikoyan str. 15</li>
                    <li>|</li>
                    <li>sales@fooddepot.am</li>
                    <li>|</li>
                    <li>+37477071834</li>
                </ul>
            </div>

        </div>
    </>

}

export default Hided
