// react
import React from 'react';
import { FormattedMessage } from 'react-intl';


function PageHeader(props) {
    const { header } = props;

    return (
        <div className="page-header">
            <div className="page-header__container container">
                <div className="page-header__title">
                    <h1>{header}</h1>
                </div>
            </div>
        </div>
    );
}


export default PageHeader;
