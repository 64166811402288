// react
import React, {useState} from 'react';

// third-party
import classNames from 'classnames';
import {connect, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import {Cart16Svg} from '../../svg';
import {url} from '../../helper';
import {FormattedMessage} from 'react-intl';
import ProductModal from '../blocks/ProductModal';

function Suggestions(props) {
    const {
        context,
        className,
        products,
    } = props;
    const [modal, setModal] = useState(false);
    const [openedProd, setOpenedProd] = useState([])
    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);
    const locale = useSelector(state => state.locale)
    const isFounded = products[0]?.id !== -1

    const modalFunc = () => setModal(true)
    const closeFunc = () => setModal(false)

    const openProductModal = (product) => {
        setModal(true)
        setOpenedProd(product)
    }
    const list = products && products.map((product) => {
        return <>
            <Link to={`/${locale}/shop/products/${product.url_key}`} style={{color: "unset"}}>
                <li key={product.id} className="suggestions__item"
                    // onClick={() => openProductModal(product)}
                >
                    {product.image && (
                        <div className="suggestions__item-image product-image">
                            <div className="product-image__body">
                                <img className="product-image__img" src={`${url}/cache/small/${product.image}`} alt=""/>
                            </div>
                        </div>
                    )}
                    <div className="suggestions__item-info">
                        {product.name}
                    </div>
                    <div className="suggestions__item-price">
                        <Currency value={product.price}/>
                    </div>
                </li>
            </Link>
        </>
    });

    return (
        <>
            {/*<ProductModal modal={modal} modalFunc={!modal ? modalFunc : closeFunc} data={openedProd}/>*/}
            <div className={rootClasses}>
                <ul className="suggestions__list">
                    {
                        isFounded ? list : <div className="suggestions__item-name text-center">
                            <FormattedMessage id="SorryNothingFoundFor" defaultMessage="Sorry, nothing found for"/> "
                            {products[0].name}"
                        </div>
                    }
                </ul>
            </div>
        </>

    );
}

const mapStateToProps = () => ({});



export default connect(
    mapStateToProps,
)(Suggestions);
