// react
import React, {useEffect, useState} from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import {ArrowDown, Check9x7Svg} from '../../svg';
import {FormattedMessage} from 'react-intl';
import {useLocation} from "react-router-dom";

function FilterCheck(props) {
    const {data, value, onChangeValue, filterValues, code} = props;

    const [isOpen, setIsOpen] = useState(false);
    const [seeAll, setSeeAll] = useState(false)
    const location = useLocation()

    useEffect(() => {
        setIsOpen(false)
    }, [location.search]);


    const updateValue = (data, newValue, remove) => {

        onChangeValue({filter: code, value: newValue, remove: remove});
    };

    const handleChange = (event, value) => {

        if (event.target.checked) {
            updateValue(value, [event.target.value], false);
        }
        if (!event.target.checked) {
            updateValue(value, event.target.value, true);
        }
    };

    let fillVal;
    if (filterValues[value] !== undefined) {
        fillVal = filterValues[value].split(",");

    }

    let InitshowPerBlock = 4

    const itemsList = data.slice(0, data.length > InitshowPerBlock && !seeAll ? InitshowPerBlock : data.length).map((item) => {


        const itemClasses = classNames('filter-list__item', {
            'filter-list__item--disabled': item.count === 0,
        });

        return (
            <label key={item.slug} className={itemClasses}>
                <span className="filter-list__input input-check">
                    <span className="input-check__body">

                        <input
                            className="input-check__input"
                            type="checkbox"
                            value={item.id}
                            checked={filterValues && filterValues[value.code]
                                ? filterValues[value.code].toString().includes(item.id)
                                : ""}
                            disabled={item.count === 0}
                            onChange={handleChange}
                        />
                        <span className="input-check__box"/>
                        <Check9x7Svg className="input-check__icon"/>
                    </span>
                </span>
                <span className="filter-list__title">{item.label}</span>
            </label>
        );
    });

    return (
        <div className="filter-list">
            <div
                className="widget__title title-arrow-flex"
                onClick={(e) => {
                    setIsOpen(!isOpen);
                }}
            >
                <FormattedMessage id={props.title.toLowerCase().replace(/ /g, ".")} defaultMessage={props.title}/>
                <ArrowDown className={isOpen ? "d-block rott_fms" : "d-block"}/>
            </div>
            <div className={isOpen ? "dropdown-group active filter-list__list" : "dropdown-group"}>{itemsList}


                {data.length > InitshowPerBlock ? <div className='see-all-block'>
                    <span onClick={() => setSeeAll(!seeAll)}>
                        <FormattedMessage id={"see_all"} defaultMessage='See All ...'/>
                    </span>
                </div> : ""
                }

            </div>
        </div>
    );
}

FilterCheck.propTypes = {
    /**
     * Filter object.
     */
    data: PropTypes.object,
    /**
     * Value.
     */
    value: PropTypes.arrayOf(PropTypes.string),
    /**
     * Change value callback.
     */
    onChangeValue: PropTypes.func,
};

export default FilterCheck;
