import React, {useEffect, useState,} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {url} from "../../helper";
import {getCartDataMount} from "../../store/cart";
import {FormattedMessage} from "react-intl";
import {LogoSvg} from "../../svg";

function Login(props) {
    const cart = useSelector((state) => state.cart);
    const locale = useSelector((state) => state.locale);
    const history = useHistory();
    const dispatch = useDispatch();
    const intialValues = {email: "", password: ""};
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [checkLogin, setCheckLogin] = useState(false);
    // const location = useLocation()

    const submitForm = () => {
        // Ֆիզ Անձ => checkLogin => false
        // Իրավաբանական Անձ => checkLogin => true
        let urlLogin = checkLogin ? `${url}/api/company/login` : `${url}/api/customer/login?token=true`;


        let option = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formValues),
        };

        fetch(urlLogin, option)
            .then((responce) => responce.json())
            .then((res) => {
                if (res.error) {
                    setFormErrors({backError: res.error});
                } else {
                    if (checkLogin) {
                        // Իրավաբանական Անձ
                        if (res.role === "Manager") {
                            // Manager
                            dispatch(getCartDataMount(""))
                        } else {
                            // subUser
                            dispatch(getCartDataMount("subUser"))
                        }

                        localStorage.setItem("userData", JSON.stringify(res.data || res));
                        localStorage.setItem("companyName", JSON.stringify(res.company_name));
                        dispatch({type: 'LIMIT', payload: res.limit});
                        dispatch({type: "AUTHENTICATED", payload: true});
                        dispatch({type: "ROLE", payload: res.role});
                        dispatch({type: "CUSTOMER_TOKEN", payload: res.token});
                        props.close("login", false);
                        props.closeModal();
                        window.location.reload()
                    } else {
                        // Ֆիզ Անձ
                        if (res.token && res.token !== "") {
                            localStorage.setItem("userData", JSON.stringify(res.data));
                            dispatch({type: "ROLE", payload: false});
                            dispatch({type: "AUTHENTICATED", payload: true});
                            dispatch({type: "CUSTOMER_TOKEN", payload: res.token});
                            props.close("login", false);
                            props.closeModal();
                            history.push(`/${locale}/account/profile/`);

                            if (res.role !== "subUser" && res.role !== "Manager") {
                                dispatch(getCartDataMount(""))
                            }
                        } else {
                            setFormErrors({backError: res.error});
                        }
                        window.location.reload()
                    }
                }
            })
            .catch((err) => console.error(err));
    };

    // const submitCartData = (userCart, token) => {
    //     let product = [];
    //     cart.items.map((e) => {
    //         product.push(e);
    //         dispatch(cartRemoveItemSuccess(e.id));
    //         return e
    //     });
    //
    //     let filter;
    //     if (product.length)
    //         product.map((e, i) => {
    //             filter = userCart.items.filter((el) => el.product.id === e.product.id);
    //             if (!filter.length) {
    //                 fetch(
    //                     `${url}/api/checkout/cart/add/${e.product.id}?product_id=${e.product.id}&quantity=${e.quantity
    //                     }&api_token=${cart_token}${token ? `&token=${token}` : ""}`,
    //                     {method: "POST"}
    //                 )
    //                     .then((responce) => responce.json())
    //                     .then((res) => {
    //                         getCart(token);
    //                     })
    //                     .catch((err) => console.error(err));
    //             } else {
    //                 getCart(token);
    //             }
    //             return e
    //         });
    //     else getCart(token);
    // };

    // const getCart = (token) => {
    //     let newUrl = "";
    //     if (token) {
    //         newUrl = `${url}/api/checkout/cart?token=${token}&api_token=${cart_token}`;
    //     } else {
    //         newUrl = `${url}/api/checkout/group-user/cart?token=${token}&api_token=${cart_token}`;
    //     }
    //
    //     fetch(newUrl)
    //         .then((responce) => responce.json())
    //         .then((resUser) => {
    //             // if (checkLogin) {
    //             // }
    //
    //             if (resUser.data) {
    //                 let product = [];
    //                 resUser.data.items.map((e) => {
    //                     product.push({
    //                         id: e.id,
    //                         product: e.product,
    //                         quantity: e.quantity,
    //                         price: e.product.price,
    //                         options: [],
    //                         total: Math.round(e.total),
    //                         itemId: e.id,
    //                     });
    //
    //                     return e
    //                 });
    //                 dispatch(
    //                     cartAddItemSuccessAll(
    //                         product,
    //                         Math.round(resUser.data.base_grand_total),
    //                         resUser.data.items.length,
    //                         Math.round(resUser.data.base_sub_total)
    //                     )
    //                 );
    //             }
    //         })
    //         .catch((err) => console.error(err));
    // };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.email || !regex.test(values.email)) {
            errors.email = "Invalid email format";
        }

        if (!values.password || values.password.length < 4) {
            errors.password = "Password must be more than 6 characters";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);

    return (
        <div className="login_content_fm">
            {/* <img className="sh_fm" src="images/logos/sh.png" alt="sh" /> */}
            <div className='new_logo_fm'>
                <LogoSvg/>
            </div>

            <div className="logn_title_fm">
                <FormattedMessage id="login.welcome.back" defaultMessage="Welcome Back!"/>
            </div>
            <div className="choose_fm">
                <label className="radio_fm">
                    <FormattedMessage id="reg.phys" defaultMessage="Ֆիզ Անձ"/>
                    <input
                        type="radio"
                        name="radio"
                        checked={!checkLogin}
                        onClick={() => {
                            setCheckLogin(false);
                        }}
                    />
                    <span className="checkmark"></span>
                </label>
                <label className="radio_fm">
                    <FormattedMessage id="reg.loy" defaultMessage="Իրավաբանական Անձ"/>
                    <input
                        type="radio"
                        name="radio"
                        onClick={() => {
                            setCheckLogin(true);
                        }}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>
            <form action="" onSubmit={handleSubmit}>
                <div className={formErrors.email && "input-error_fm"}>
                    <img src="images/icon/envelope.png" alt="envelope"/>
                    <FormattedMessage id="login.email.address" defaultMessage="Email Address">
                        {(placeholder) => (
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="text"
                                name="email"
                                id="email"
                                value={formValues.email}
                                onChange={handleChange}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.email && (
                        <span className="error">
                            <FormattedMessage id="account.error.email" defaultMessage={formErrors.email}/>
                        </span>
                    )}
                </div>
                <div className={formErrors.password && "input-error_fm"}>
                    <img src="images/icon/password.png" alt="password"/>
                    <FormattedMessage id="account.password" defaultMessage="Password">
                        {(placeholder) => (
                            <input
                                className="login_inp_fm"
                                placeholder={placeholder}
                                type="password"
                                name="password"
                                id="password"
                                value={formValues.password}
                                onChange={handleChange}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.password && (
                        <span className="error">
                            <FormattedMessage id="account.errors.password" defaultMessage={formErrors.password}/>
                        </span>
                    )}
                    {formErrors.backError && <span className="error"><FormattedMessage id="invalid.e.pass"
                                                                                       defaultMessage={formErrors.backError}/></span>}
                </div>
                <div className="forgot_pass_fm">
                    <span
                        onClick={() => {
                            props.close("login", false);
                            props.clicked("forgot", true);
                        }}
                    >
                        <FormattedMessage id="login.forgot.password" defaultMessage="Forgot Password"/>
                    </span>
                </div>
                <div className="rem_fm">
                    <input type="checkbox"/>
                    <span>
                        <FormattedMessage id="login.remember.me" defaultMessage="REMEMBER ME"/>
                    </span>
                </div>
                <button>
                    <FormattedMessage id="login.log.in" defaultMessage="LOG IN"/>
                </button>
            </form>
            <div className="sign_up_fm">
                <div
                    className="singup_link_fm"
                    onClick={() => {
                        props.close("login", false);
                        props.clicked("reg", true);
                    }}
                >
                    <FormattedMessage id="login.dontHaveAnAccount" defaultMessage="Don't have an account?"/>
                    <span>
                        {" "}
                        <FormattedMessage id="login.sign.up" defaultMessage="Sign Up"/>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default connect(false)(Login);
