// react
import React, {useEffect} from 'react';
import queryString from 'query-string';
import {Link} from 'react-router-dom';

// application
import {Check100Svg} from '../../svg';
import {getCartDataMount} from "../../store/cart";
import {useDispatch} from "react-redux";

function ThanksPage(props) {

    const {id, orderID} = queryString.parse(props.location.search)
    const dispatch = useDispatch();
    let iframe;
    if (orderID || id) {

        if (id) {
            iframe = `<iframe src="https://testpayments.ameriabank.am/forms/frm_checkprint.aspx?lang=am&paymentid=${id}" width="100%" height="1000px"></iframe>`;
        } else {
            iframe = '<div style="height:250px"></div>'
        }

    }
    // else{
    //       window.location='/'
    // }

    const createMarkup = (item) => {
        return {__html: item};
    }
    useEffect(() => {
        dispatch(getCartDataMount())
        localStorage.removeItem("api_token");
    }, []);
    return (


        <div className="container">
            <div className="row justify-content-center">

                <div className="order-success__body">
                    <div className="order-success__header">
                        <Check100Svg className="order-success__icon"/>
                        <h1 className="order-success__title">Thank you</h1>
                        <div className="order-success__subtitle">Your order #{orderID} has been received</div>
                        <div className="order-success__actions">
                            <Link to="/" className="btn btn-xs btn-secondary">Go To Homepage</Link>
                        </div>
                    </div>
                </div>

                <div style={{

                    height: '100%'
                }} className={'col-md-12 '} dangerouslySetInnerHTML={createMarkup(iframe)}></div>


            </div>
        </div>

    );

}


export default ThanksPage;
