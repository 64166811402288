// react
import React from 'react';
import InnerProduct from './InnerProduct'
import Product from '../shared/Product'
import { Cross20Svg } from '../../svg';
import { Modal } from 'reactstrap';
import { useSelector } from 'react-redux';


export default function ProductModal(props) {

    const locale = useSelector(state=> state.locale)
    return (
        <Modal className="nav_modal_fm product_modal_fm" isOpen={props.modal} toggle={props.modalFunc} centered size="xl">
            <div className="quickview">
                <button className="quickview__close" type="button" onClick={() =>  props.modalFunc() }>
                    <Cross20Svg />
                </button>
                <InnerProduct  locale={locale} product={props.data} modalFunc={props.modalFunc}/>
            </div>
        </Modal>
    );
}
