// react
import React, {useEffect, useState} from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {ArrowRightSVG, ArrowTopSVG} from '../../svg';
import {addCategoryName} from "../../store/category";
import {useDispatch, useSelector} from "react-redux";

function MenuLinks(props) {
    const {links, depth, setOpenMenu, openMenu} = props;
    const [localLinks, setLocalLinks] = useState([])
    const locale = useSelector(state => state.locale)
    const dispatch = useDispatch()
    // const [localPages,setLocalPages] = useState([])

    useEffect(() => {

        setLocalLinks([...links])
    }, [links])


    const showSubLinks = (item, setCallback, callback) => {


        setCallback(callback.map(element => {
            if (element.id === item.id) {
                return {
                    ...element,
                    showSubs: !Boolean(element.showSubs)
                }
            }
            return element
        }))
    }

    const handleCloseMenu = () => {
        if (setOpenMenu) {
            setOpenMenu(!openMenu)
        }

    }



    const linksList = localLinks.map((link, index) => {


        let title = null;
        let subLinks = null;

        let hasSubs = false
        let buildedUrl = link.hasOwnProperty("page_id")
            ? `/${locale}/page/${link.page_id}`
            : link.mainCats &&  link.id !== 267
                ? `/${locale}/shop/catalog?category_slug=${link.slug}`
                : link.children?.length > 0
                    ? `/${locale}/shop/catalog?category_slug=${link.slug}`
                    : `/${locale}/shop/main/catalog?category_id=${link.id}`
        // let buildedUrl = "asd"


        if (link.children && link.children.length) {
            hasSubs = true
            subLinks =
                <MenuLinks links={link.children} setOpenMenu={setOpenMenu} openMenu={openMenu} depth={depth + 1}/>;
        }

        if (link.name) {
            title = <>
                <div className='menu-link-wrapper'>
                    <div
                        // className={link.slug === "profdepot" && "disabled"}
                        onClick={() => {
                            handleCloseMenu()
                            dispatch(addCategoryName(link.name))
                        }}
                    >
                        <Link to={buildedUrl}>{link.name}</Link>
                    </div>

                    {hasSubs &&
                        <div
                            //  onClick={() => showSubLinks(link,setLocalLinks,localLinks)}
                        >
                            {link.showSubs ? <ArrowTopSVG/> : <ArrowRightSVG/>}
                        </div>
                    }
                </div>
            </>
        }

        const classes = classNames('megamenu__item', {
            'megamenu__item--with-submenu': subLinks,
        });

        return (
            <li key={index} className={classes}
                onClick={(e) => {
                    e.stopPropagation()
                    return subLinks ? showSubLinks(link, setLocalLinks, localLinks) : null
                }}>
                {title}
                {link.showSubs && subLinks}
            </li>
        );
    });

    // const pagesList = localPages.map((link, index) => {

    //     let title = null;
    //     let subLinks = null;

    //     let hasSubs = false


    //     if (link.children && link.children.length) {
    //         hasSubs = true
    //         subLinks = <MenuLinks links={link.children} depth={depth + 1} />;
    //     }

    //     if (link.name) {
    //         title = <>

    //             <div className='menu-link-wrapper'>
    //                 <Link to={`/page/${link.id}`}>{link.name}</Link>
    //                 {hasSubs &&
    //                     <div onClick={() => showSubLinks(link,setLocalPages,localPages)}>
    //                         {link.showSubs ? <ArrowTopSVG /> : <ArrowRightSVG />}
    //                     </div>
    //                 }
    //             </div>

    //         </>
    //     }


    //     const classes = classNames('megamenu__item', {
    //         'megamenu__item--with-submenu': subLinks,
    //     });

    //     return (
    //         <li key={index} className={classes}>
    //             {title}
    //             {link.showSubs && subLinks}
    //         </li>
    //     );
    // })

    return (
        <ul className={`megamenu__links megamenu__links--level--${depth}`}>
            {linksList}
            {/* {pagesList} */}
        </ul>
    );
}

MenuLinks.propTypes = {
    links: PropTypes.array,
    depth: PropTypes.number,
};

MenuLinks.defaultProps = {
    depth: 0,
};

export default MenuLinks;
