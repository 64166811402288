// react
import React, {useEffect, useState} from 'react';

// third-party
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';


// application
import Dropdown from './Dropdown';
import {localeChange} from '../../store/locale';
import {languages} from '../utils';
import {useHistory, useLocation} from "react-router-dom";
import {url} from "../../services/utils"

function DropdownLanguage(props) {
    const {locale, localeChange: changeLocale, context, setOpenMenu} = props;
    const [selectedLocale, setSelectedLocale] = useState(locale)
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        setSelectedLocale(languages.find((x) => x.locale === locale));
    })

    const handleClick = (item) => {
        changeLocale(item.locale)
        const pathLocale = location.pathname.split("/")[1]
        const pathWithoutLocale = location.pathname.split(pathLocale + "/")[1] || ""

        history.push({
            pathname: `/${item.locale}/${pathWithoutLocale}`,
            search: location.search
        })
    }

    const title = (
        <React.Fragment>
            <img className="lang_image" src={selectedLocale.icon} alt="" width="26px" height="18px"/>
            <FormattedMessage id="topbar.language" defaultMessage={selectedLocale.code}/>
        </React.Fragment>
    );

    return (
        <Dropdown
            title={title}
            withIcons
            items={languages}
            onClick={handleClick}
            context={context}
            setOpenMenu={setOpenMenu}
        />
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownLanguage);
