import { toast } from 'react-toastify';
import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM,WISH_LIST } from './wishlistActionTypes';
import { url } from '../../helper';
import { FormattedMessage } from 'react-intl';
import React from "react";
export function wishlistAddItemSuccess(product,wishItemId,check) {

    return {
        type: WISHLIST_ADD_ITEM,
        product,
        wishItemId
    };
}

export  const  setData=(data)=>({
 
    type:WISH_LIST,
    payload: data

})

export function wishlistRemoveItemSuccess(productId) {
    return {
        type: WISHLIST_REMOVE_ITEM,
        productId,
    };
}

export function wishlistAddItem(product, token,check) {
    if(!token)
        return  (dispatch) => (
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(false);
                }, 0);
            })
        )
    else return (dispatch,selector) => (fetch(`${url}/api/wishlist/add/${product.id}?token=${token}&locale=${selector().locale}`)
            .then(responce => responce.json())
            .then(res => {
                if(res.data == null){
                    toast.success(<FormattedMessage id="remove.wishlist" defaultMessage="Item removed from wishlist successfully."/>)
                } else {
                    toast.success(<FormattedMessage id="add.wishlist" defaultMessage="Item Successfully Added To Wishlist."/>)
                }
                if(!check)
                    dispatch(wishlistAddItemSuccess(product,res.data.id,true));
                else
                    dispatch(wishlistRemoveItemSuccess(product.id));
            })
            .catch(err => console.error(err))
        );
}

export function wishlistRemoveItem(productId, token) {
    // sending request to server, timeout is used as a stub

    return (dispatch) => (fetch(`${url}/api/wishlist/add/${productId}?token=${token}`)
    .then(responce => responce.json())
    .then(res => {
        dispatch(wishlistRemoveItemSuccess(productId));
    })
    .catch(err => console.error(err))
);
}
