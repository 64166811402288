// react
import React from 'react';
import logoGif from "../../assets/gifs/logo.gif"

export default function BlockLoader() {
    return (
        <div className="block-loader">
            {/* <div className="block-loader__spinner" /> */}

            <div className='block-content'>
            <img src={logoGif} alt='logo gid' />
            </div>
        </div>
    );
}
