
import React from 'react';

import { useState } from 'react'
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { url } from '../../helper';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

export default function ChangeEmile(props) {
    const customer = useSelector(state => state.customer);
    const data = JSON.parse(localStorage.getItem('userData'))

    const intialValues = { email: data.email };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.email) {
            errors.email = "Invalid email format";
        } else if (!regex.test(values.email)) {
            errors.email = "Invalid email format";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);

    const submitForm = () => {
        let obj = Object.assign(formValues, 
            {
                token: customer.token,
                first_name: data.first_name,
                last_name: data.last_name,
                gender: 'gender',
                date_of_birth: null
            })
        let option = {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                obj
            ),
        }

        fetch(`${url}/api/customer/profile`, option)

            .then(responce => responce.json())
            .then(res => {
                toast.success(res.message);
                props.setData(res.data);
                props.modalFunc('close')
                localStorage.setItem('userData', JSON.stringify(res.data))
            })
            .catch(err => console.error(err));
    };


    return (
        <form action="" onSubmit={handleSubmit}>
            <div className="title">
                <FormattedMessage id="account.editEmailAddress"  defaultMessage="Edit email address" />
                        </div>
            <div>
            <FormattedMessage id="account.editEmailAddress"  defaultMessage="Edit email address">
                {placeholder => 
                    <input
                        type="text"
                        placeholder={placeholder}
                        name="email"
                        id="email"
                        value={formValues.email}
                        onChange={handleChange}
                    />
                }
            </FormattedMessage>
            </div>
            <div>
                {formErrors.email && (
                    <span className="error"><FormattedMessage id="account.error.email"  defaultMessage={formErrors.email} /></span>
                )}
            </div>
            <div className="butt_block_fm">
                <div className="cancel_modal_fm cursor_fm" onClick={() => { props.modalFunc('close') }}><FormattedMessage id="account.cancel"  defaultMessage="Cancel" /></div>
                <div className="change_modal_fm"><button><FormattedMessage id="account.change"  defaultMessage="Change" /></button></div>
            </div>
        </form>
    );
}
