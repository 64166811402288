import { CATEGORY_SET_ROUTES} from './category-routesActionTypes';

const initialState = []
export default function categoryNameReducer(state = initialState, action) {
   
    switch(action.type){
       
        case CATEGORY_SET_ROUTES:
            return [...action.payload]
        default:
            return state;
    }
   
    
}