import { UPDATE_CART_DATA } from "./cartActionTypes";

const initialState = {
    grand_total: "",
    sub_total: "",
    items: []
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_CART_DATA:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}
