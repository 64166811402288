// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import InputNumber from './InputNumber';
import ProductGallery from './ProductGallery';
import Rating from './Rating';
import { cartAddItemNew } from '../../store/cart';
import { compareAddItem } from '../../store/compare';
import { Wishlist16Svg, Compare16Svg } from '../../svg';
import { wishlistAddItem } from '../../store/wishlist';
import { FormattedMessage } from 'react-intl';

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customer: this.props.customer,
            quantity: 1,
        };
    }

    handleChangeQuantity = (quantity) => {
        this.setState({ quantity });
    };

    render() {
        const {
            product,
            // layout,
            // wishlistAddItem,
            // compareAddItem,
            cartAddItemNew,
        } = this.props;
        const { quantity, customer, cart } = this.state;
        let prices;

        return (
            <div><div className="product_fm">
                <div className="product_img_fm" style={{ backgroundImage: 'url("' + product.base_image.original_image_url + '")' }}>
                    <ProductGallery layout={'standard'} images={[product.base_image.original_image_url]} />
                </div>
                <div className="product_info_fm">
                    <div className="product_name_fm">
                        <span>{product.name}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22.48" height="20" viewBox="0 0 22.48 20">
                            <path id="heart" d="M11.24,20a1.318,1.318,0,0,1-.869-.327c-.908-.794-1.784-1.54-2.556-2.2l0,0A47.539,47.539,0,0,1,2.23,12.233,8.367,8.367,0,0,1,0,6.756a7.026,7.026,0,0,1,1.783-4.8A6.045,6.045,0,0,1,6.28,0,5.654,5.654,0,0,1,9.812,1.219,7.226,7.226,0,0,1,11.24,2.71a7.227,7.227,0,0,1,1.428-1.491A5.654,5.654,0,0,1,16.2,0a6.045,6.045,0,0,1,4.5,1.957,7.026,7.026,0,0,1,1.783,4.8,8.366,8.366,0,0,1-2.229,5.477,47.534,47.534,0,0,1-5.581,5.238c-.774.659-1.651,1.407-2.561,2.2A1.319,1.319,0,0,1,11.24,20ZM6.28,1.317A4.743,4.743,0,0,0,2.751,2.85,5.716,5.716,0,0,0,1.317,6.756a7.04,7.04,0,0,0,1.927,4.637,46.782,46.782,0,0,0,5.421,5.077l0,0c.775.661,1.654,1.41,2.569,2.21.92-.8,1.8-1.552,2.578-2.214a46.793,46.793,0,0,0,5.421-5.076,7.041,7.041,0,0,0,1.927-4.637A5.716,5.716,0,0,0,19.729,2.85,4.742,4.742,0,0,0,16.2,1.317a4.367,4.367,0,0,0-2.727.944,6.39,6.39,0,0,0-1.52,1.758.831.831,0,0,1-1.428,0,6.384,6.384,0,0,0-1.52-1.758A4.367,4.367,0,0,0,6.28,1.317Zm0,0" transform="translate(0 0)" fill="#b0c472" />
                        </svg>
                    </div>
                    {/* <div className="product_type_fm">Organic</div> */}
                    {product.old_price ?
                        <div className="product_price_fm">

                            <span className="dis_price_fm">
                                {Math.round(product.old_price)} <FormattedMessage id="global.curency" defaultMessage="AMD" />
                                <div></div>
                            </span>
                            {Math.round(product.price)} <FormattedMessage id="global.curency" defaultMessage="AMD" />
                        </div>
                        :
                        <div className="product_price_fm">
                            {Math.round(product.price)} <FormattedMessage id="global.curency" defaultMessage="AMD" />
                        </div>
                    }
                    {product.in_stock == true ?
                        <div className="product_stock_fm">In stock</div> :
                        <div className="product_stock_fm" style={{ color: 'red' }}>Out of stock</div>
                    }
                    <div className="product_qty_fm">Quantity:</div>
                    <div className="block_1">
                        <InputNumber
                            id="product-quantity"
                            aria-label="Quantity"
                            className="product__quantity"
                            size="lg"
                            min={1}
                            value={quantity}
                            onChange={this.handleChangeQuantity}
                        />
                        <div className="add_card_fm">
                            <AsyncAction
                                action={() => cartAddItemNew(product, quantity)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        onClick={run}
                                        className={classNames({
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <FormattedMessage id="add_to_cart" defaultMessage="Add to cart" />

                                    </button>
                                )}
                            />
                        </div>
                    </div>
                    <div className="share_fm">
                        <button className="fb_share_fm">
                            <img src="images/logos/fb_share.png" alt="" />
                        </button>
                    </div>
                </div>
            </div>
                {/* <RelatedItems /> */}
            </div>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
    layout: 'standard',
};

const mapStateToProps = (state) => ({
    customer: state,
    cart: state
});

const mapDispatchToProps = {
    cartAddItemNew,
    wishlistAddItem,
    compareAddItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Product);
