// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { useState } from 'react'
import { useEffect } from 'react';
import { url } from '../../helper';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
// data stubs
import { cartAddItemNew } from '../../store/cart';
import { useHistory } from "react-router-dom";

import theme from '../../data/theme';
import { toast } from 'react-toastify';
import {
    ArrowRoundedLeft6x9Svg,
    ArrowRoundedLeft7x11Svg,
    ArrowRoundedLeft8x13Svg
} from '../../svg';
import Breadcrumbs from "../shared/Breadcrumbs";

function AccountPageOrderDetails(props) {
    const {
        customer,
        orderId,
        order: reduxOrder,
        location,
    } = props
    const history = useHistory();
    const dispatch = useDispatch();
    const [order, getOrder] = useState();

    const locale = useSelector(state => state.locale);
    const cart = useSelector(state => state.cart);

    useEffect(() => {
        if (reduxOrder) {
            let base_grand_total = 0
            const array = reduxOrder && reduxOrder.map(e => {
                base_grand_total += e.product_price * e.quantity
                return {
                    ...e,
                    additional: {
                        quantity: e.quantity
                    },
                    name: e.product_name,
                    price: e.product_price,
                    qty_ordered: e.quantity,
                    grant_total: e.product_price * e.quantity,
                    product: {
                        base_image: {
                            original_image_url: e.product_image
                        }
                    }
                }
            })

            let obj = { id: reduxOrder[0].confirmation_id, items: array, base_grand_total: base_grand_total }
            getOrder(obj)
        }
    }, [reduxOrder])

    useEffect(() => {
        if (customer.role !== 'subUser') {
            let newUser = '';
            if (customer.role == "subUser") {
                newUser = `${url}/api/checkout/group-user/cart/order?id=${orderId}&token=${customer.token}`;
            } else {
                newUser = `${url}/api/orders?token=${customer.token}&id=${orderId}`;
            }

            fetch(newUser)
                .then(responce => responce.json())
                .then(res => {
                    if (res.data) {
                        getOrder(res.data[0])
                    } else {
                        getOrder(res[0])
                    }
                })
        }else if(!reduxOrder ){
            let newUser = '';
                newUser = `${url}/api/orders?token=${customer.token}&id=${orderId}`;
            fetch(newUser)
                .then(responce => responce.json())
                .then(res => {
                    if (res.data) {
                        getOrder(res.data[0])
                    } else {
                        getOrder(res[0])
                    }
                })
        }
    }, [customer.token]);

    function handlerReorder() {
        const { items } = order;
        new Promise(resolve => {
            setTimeout(() => {
                items.forEach((item, index) => {
                    let product = null;
                    if (customer.role == 'subUser') {
                        product = item;
                        delete product['id']
                        product['id'] = item.product_id;
                    } else {
                        product = item.product
                    }

                    dispatch(cartAddItemNew(
                        product,
                        +item.additional.quantity,
                        resolve,
                    ))
                });
            }, 500)
        }).then(res => {
            history.push(`${locale}/shop/cart`);
        })
    }

    const breadcrumb = [
        {title: <FormattedMessage id="home" defaultMessage="home"/>, url: url},
        {title: <FormattedMessage id="account.orderHistory" defaultMessage="Order History"/>, url: `/${locale}/account/orders`},
        {title: orderId, url: location.pathname},
    ];

    if (order) {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Order Details — ${theme.name}`}</title>
                </Helmet>
                <Breadcrumbs breadcrumb={breadcrumb}/>

                <div className="block">
                    <div className="container_fm">
                        <div className="order_title_fm"><FormattedMessage id="order.order" defaultMessage="Order" /> # {order.id}</div>
                        <div className="order_details_go_back">
                            <span onClick={() => history.goBack()}>
                                <ArrowRoundedLeft8x13Svg />
                                <FormattedMessage id="account.orderDetails.back" defaultMessage="Go to back" />
                            </span>
                        </div>

                        <div className="order_card_fm d-none d-md-block">
                            <table>
                                <thead className="order_detile_head">
                                    <tr className="order_detilet__row">
                                        <th className="order_detile__column">
                                            <FormattedMessage id="global.product" defaultMessage="Product" />
                                        </th>
                                        <th className="order_detile__column">
                                            <FormattedMessage id="global.price" defaultMessage="Price" />
                                        </th>
                                        <th className="order_detile__column">
                                            <FormattedMessage id="global.quantity" defaultMessage="Quantity" />
                                        </th>
                                        <th className="order_detile__column">
                                            <FormattedMessage id="global.total" defaultMessage="Total" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {order.items?.map(e =>
                                        <tr className="order_detile__row" key={e.id}>
                                            <td className="order_detile__columne">
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="16.459" height="16.459" viewBox="0 0 16.459 16.459">
                                            <path id="close" d="M9.738,8.366l6.409-6.409A1.066,1.066,0,1,0,14.639.449L8.23,6.858,1.821.449A1.066,1.066,0,1,0,.313,1.957L6.722,8.366.313,14.775a1.066,1.066,0,1,0,1.508,1.508L8.23,9.874l6.409,6.409a1.066,1.066,0,0,0,1.508-1.508Zm0,0" transform="translate(0 -0.136)" fill="#43b02a" />
                                        </svg> */}
                                                <img src={e?.product?.base_image?.original_image_url} alt="" width="77px" />
                                                {e.name}
                                            </td>
                                            <td className="order_detile__column">
                                                {Math.round(e.price)} <FormattedMessage id="global.curency" defaultMessage="AMD" />
                                            </td>
                                            <td className="order_detile__column">{e.qty_ordered}</td>
                                            <td className="order_detile__colum">{e.grant_total} <FormattedMessage id="global.curency" defaultMessage="AMD" /></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {order.items?.map((e, i) =>
                            <div key={order.id + i}>
                                <div className="order_mobile_fm d-block d-md-none">
                                    <div className="order_mobile_title_fm"><FormattedMessage id="global.product" defaultMessage="Product" /></div>
                                    <div className="order_detile__columne">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16.459 16.459">
                                <path id="close" d="M9.738,8.366l6.409-6.409A1.066,1.066,0,1,0,14.639.449L8.23,6.858,1.821.449A1.066,1.066,0,1,0,.313,1.957L6.722,8.366.313,14.775a1.066,1.066,0,1,0,1.508,1.508L8.23,9.874l6.409,6.409a1.066,1.066,0,0,0,1.508-1.508Zm0,0" transform="translate(0 -0.136)" fill="#43b02a" />
                            </svg> */}
                                        <img src={e?.product?.base_image?.original_image_url} alt="" width="39px" />
                                        {e.name}
                                    </div>
                                </div>
                                <div className="order_mobile_content_fm d-flex d-md-none">
                                    <div><FormattedMessage id="global.price" defaultMessage="Price" /></div>
                                    <div>{Math.round(e.price)} <FormattedMessage id="global.curency" defaultMessage="AMD" /></div>
                                </div>
                                <div className="order_mobile_content_fm d-flex d-md-none">
                                    <div><FormattedMessage id="global.quantity" defaultMessage="Quantity" /></div>
                                    <div>{e.qty_ordered}</div>
                                </div>
                                <div className="order_mobile_content_fm d-flex d-md-none">
                                    <div><FormattedMessage id="global.total" defaultMessage="Total" /></div>
                                    <div>{e.grant_total} <FormattedMessage id="global.curency" defaultMessage="AMD" /></div>
                                </div>
                            </div>
                        )}
                        <div className="order_card_fm">
                            <div className="order_total_fm">
                                <div>
                                    <p><FormattedMessage id="order.grandTotal" defaultMessage="Grand Total" /></p>
                                    <p>{Math.round(order.base_grand_total)} <FormattedMessage id="global.curency" defaultMessage="AMD" /></p>
                                    <button onClick={handlerReorder}>
                                       <FormattedMessage id="button.reorder" defaultMessage="Reorder" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }


    return null
}


const mapStateToProps = (state) => ({
    cart: state.cart,
    order: state.order.order,
    locale: state.locale,
    customer: state.customer,
    wishlist: state.wishlist
});

export default connect(mapStateToProps)(AccountPageOrderDetails);
