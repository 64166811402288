// react
import React, {useCallback, useEffect, useState} from "react";

// third-party
import classNames from "classnames";

// application
import FilterCheck from "../filters/FilterCheck";

import FilterRange from "../filters/FilterRange";

import {ArrowDown, FilterSVG} from "../../svg";
import Currency from '../shared/Currency';

import {FormattedMessage} from "react-intl";
import {connect, useSelector} from "react-redux";
import {useWindowWidth} from "../../hooks/useWindowWidth";
import {useLocation} from "react-router-dom";

// function parseQueryFilters(location) {
//     const query = queryString.parse(location);
//     const filterValues = {};
//
//     Object.keys(query).forEach((param) => {
//         const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);
//         if (!mr) {
//             return;
//         }
//         const filterSlug = mr[1];
//
//         filterValues[filterSlug] = query[param];
//     });
//
//     return filterValues;
// }

const CheckFilterHandler = {
    type: "check",
    serialize: (value) => value.join(","),
    // deserialize: (value) => (value ? value.split(",") : []),
    // isDefaultValue: (filter, value) => value.length === 0,
    // getDefaultValue: () => [],
};

function WidgetFilters(props) {
    const [openFilter, setOpenFilter] = useState(false)

    const maxPrice = useSelector(state => state.general.initialMaxPrice)
    const minPrice = useSelector(state => state.general.initialMinPrice)
    // const [maxPrice, setMaxPrice] = useState();
    const location = useLocation()

    useEffect(() => {
        setOpenFilter(false)
    }, [location.search]);

    const width = useWindowWidth()
    // const [foundedWidth,setWidth] = useState(width)

    // useEffect(()=>{

    //     setWidth(width)
    // },[width])


    // useEffect(() => {
    //     fetch(`${url}/api/products?limit=`)
    //         .then((responce) => responce.json())
    //         .then((e) => {

    //             console.log(e,"eeeee");
    //             setMaxPrice(e.max_price);
    //         });
    // }, []);

    const {dispatch, filters, values, title, stateFilters, offcanvas, initialMaxPrice} = props;

    const handleValueChange = useCallback(
        ({filter, value, remove}) => {
            if (remove) {
                dispatch({
                    type: "REMOVE_FILTER_VALUE",
                    filter: filter,
                    value: value,
                });
            } else {
                dispatch({
                    type: "SET_FILTER_VALUE",
                    filter: filter,
                    value: value ? CheckFilterHandler.serialize(value) : "",
                });
            }
        },
        [dispatch]
    );

    const handleResetFilters = () => {
        dispatch({type: "RESET_FILTERS"});
    };


    // const filtersList = (
    //     <>
    //         <div key={filters.id} className="widget-filters__item">
    //             <Collapse
    //                 toggleClass="filter--opened"
    //                 render={({ toggle, setItemRef, setContentRef }) => (
    //                     <div
    //                         className={
    //                             Object.keys(values).length !== 0 && Object.keys(values).includes("brand") === true
    //                                 ? "filter filter--opened"
    //                                 : "filter"
    //                         }
    //                         ref={setItemRef}
    //                     >
    //                         <button type="button" className="filter__title widget__title" onClick={toggle}>
    //                             <FormattedMessage id="brands" defaultMessage="Brands" />
    //                             <ArrowRoundedDown12x7Svg className="filter__arrow" />
    //                         </button>
    //                         <div className="filter__body" ref={setContentRef}>
    //                             <div className="filter__container">
    //                                 <FilterCheck
    //                                     filterValues={values}
    //                                     // checked={chekedValue}
    //                                     data={filters}
    //                                     value={"brand"}
    //                                     onChangeValue={handleValueChange}
    //                                 />
    //                             </div>
    //                         </div>
    //                     </div>
    //                 )}
    //             />
    //         </div>
    //     </>
    // );
    const classes = classNames("widget-filters widget", {
        "widget-filters--offcanvas--always": offcanvas === "always",
        "widget-filters--offcanvas--mobile": offcanvas === "mobile",
    });

    const filtersList = (
        <>
            {filters
                ? filters.map((item) => {
                    if (item.code !== "color") {
                        if (item.code === "price") {
                            return (
                                maxPrice && <div className={classes}>
                                    <div className="widget-filters__list">
                                        <FilterRange
                                            key={"Max Price"}
                                            data={{min: parseInt(minPrice), max: parseInt(maxPrice)}}
                                            value={stateFilters.filters.price !== undefined ? stateFilters.filters.price.split(",") : ""}
                                            onChangeValue={handleValueChange}
                                            maxPrice={maxPrice}
                                            initialMaxPrice={initialMaxPrice}
                                            title={item.name}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div className={classes}>
                                <div className="widget-filters__list">
                                    <FilterCheck
                                        filterValues={values}
                                        // checked={chekedValue}
                                        data={item.options}
                                        value={item}
                                        onChangeValue={handleValueChange}
                                        title={item.name}
                                        code={item.code}
                                    />
                                </div>
                            </div>
                        );
                    }
                    return item
                })
                : ""}
        </>
    );


    return (
        <>

            {width <= 991 ? <div className={`${classes} open-filters-block`} onClick={() => setOpenFilter(!openFilter)}>
                <div className="filter-open-field">

                    <div className="filter-title-nw">
                        <FilterSVG/>
                        <span><FormattedMessage id={'category_filter'} defaultMessage={"Filters"}/></span>
                    </div>

                    <ArrowDown className={openFilter ? "d-block rott_fms" : "d-block"}/>
                </div>
            </div> : ""}

            {
                width > 991 || openFilter ? <>
                        <div className={`${classes} main-filters-block`}>
                            <h4 className="widget-filters__title widget__title">{title}</h4>
                            <div className="widget-filters__list">{filtersList}</div>
                        </div>

                        <div className={classes}>
                            <div className="widget-filters__actions d-flex mb-n2">
                                <button type="button" className="btn reset_btn btn-sm ml-2"
                                        onClick={handleResetFilters}>
                                    <FormattedMessage id="reset" defaultMessage="Reset"/>
                                </button>
                            </div>
                        </div>

                    </>
                    : <></>
            }

        </>
    );
}

const mapStateToProps = (state) => {
    return ({
        initialMaxPrice: state.general.initialMaxPrice,
        initialMinPrice: state.general.initialMinPrice,
    })
}
const mapDispatchToProps = () => ({
    // setInitialMinPrice: (payload) => dispatch(setInitialMinPrice(payload)),
    // setInitialMaxPrice: (payload) => dispatch(setInitialMaxPrice(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetFilters);
