
import React from 'react';

import { useState } from 'react'
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { url } from '../../helper';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

export default function ChangePassword(props) {
    const customer = useSelector(state => state.customer);
    const data = JSON.parse(localStorage.getItem('userData'))

    const intialValues = { password: '', password_confirmation: '' };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        let errors = {};

        if (!values.password || values.password.length < 6) {
            errors.password = "Password must be more than 6 characters";
        }

        if (!values.password_confirmation || values.password_confirmation !== values.password) {
            errors.password_confirmation = 'Passwords do not match'
        }

        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);

    const submitForm = () => {
        let obj = Object.assign(formValues,
            {
                token: customer.token,
                first_name: data.first_name,
                last_name: data.last_name,
                gender: 'gender',
                date_of_birth: null
            })

        let option = {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                obj
            ),
        }

        fetch(`${url}/api/customer/profile`, option)

            .then(responce => responce.json())
            .then(res => {
                toast.success(res.message);
                props.modalFunc('close')
            })
            .catch(err => console.error(err));
    };

    return (
        <form action="" onSubmit={handleSubmit}>
            <div className="title">
                <FormattedMessage id="account.editPassword"  defaultMessage="Edit password" />
            </div>
            <div>
            <FormattedMessage id="account.editPassword"  defaultMessage="Edit password" >
                {placeholder=>
                    <input
                        type="password"
                        placeholder={placeholder}
                        name="password"
                        id="password"
                        value={formValues.password}
                        onChange={handleChange}
                    />
            }
            </FormattedMessage>
            </div>
            <div>
                {formErrors.password && (
                    <span className="error"><FormattedMessage id="account.error.password"  defaultMessage={formErrors.password} /></span>
                )}
            </div>
            <div>
            <FormattedMessage id="account.confirmPassword"  defaultMessage="Confirm password" >
                {placeholder => 
                    <input
                        type="password"
                        placeholder="Confirm password"
                        name="password_confirmation"
                        id="password_confirmation"
                        value={formValues.password_confirmation}
                        onChange={handleChange}
                    />
                
                }
            </FormattedMessage>
            </div>
            <div>
                {formErrors.password_confirmation && (
                    <span className="error"><FormattedMessage id="account.error.passwordConfirmation"  defaultMessage={formErrors.password_confirmation} /></span>
                )}
            </div>
            <div className="butt_block_fm">
                <div className="cancel_modal_fm cursor_fm" onClick={() => { props.modalFunc('close') }}><FormattedMessage id="account.cancel"  defaultMessage="Cancel" /></div>
                <div className="change_modal_fm"><button><FormattedMessage id="account.change"  defaultMessage="Change" /></button></div>
            </div>
        </form>
    );
}
