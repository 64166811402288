// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import StroykaSlick from '../shared/StroykaSlick';

// data stubs
import theme from '../../data/theme';

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function SitePageAboutUs() {
    return (
        <div className="about_us_fm">
            <div className="container_fm">
                <div className="video_fn">
                    <video width="640" height="360" id="player1" preload="none">
                        <source type="video/youtube" src="https://youtu.be/8w2lNpixqOc" />
                    </video>
                </div>
                <div className="content_about_fm">
                    <div className="title_about_fm">ABOUT US</div>
                    <div className="text_about_fm">
                        Founded in 1992, Majid Al Futtaim is the leading shopping mall, retail and leisure pioneer across the
                        Middle East and North Africa (MENA).<br />
                        Majid Al Futtaim owns and operates 17 shopping malls, 11 hotels and three mixed-use communities
                        in MENA, with further developments underway in the region. Founded in 1992, Majid Al Futtaim is the
                        leading shopping mall, retail and leisure pioneer across the Middle East and North Africa (MENA).
                        Majid Al Futtaim owns and operates 17 shopping malls, 11 hotels and three mixed-use communities
                        in MENA, with further developments underway in the region.<br />
                        Founded in 1992, Majid Al Futtaim is the leading shopping mall, retail and leisure pioneer across the
                        Middle East and North Africa (MENA). Majid Al Futtaim owns and operates 17 shopping malls, 11
                        hotels and three mixed-use communities in MENA, with further developments underway in the region.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageAboutUs;
