import React , {useState, useEffect} from "react";

export const useWindowWidth = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth);

    useEffect(() => {

        const resizeHandler = () => setWindowSize(window.innerWidth);
        window.addEventListener("resize",resizeHandler)
       
        return () =>{
            window.removeEventListener("resize",resizeHandler)
        }
    }, []);

    return windowSize
}

export const withHooksHOC = (Component) => {
   
    return (props) => {
        const screenWidth = useWindowWidth();

        return <Component width={screenWidth} {...props} />;
    };
};