// react
import React, {useEffect, useRef, useState} from 'react';
import Slider from "react-slick";
import "./product_inner.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {CarouselArrowLeft, CarouselArrowRight, ZoomIn24Svg} from "../../svg";
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import NewPhotoSwipe from "./NewPhotoSwipe";

const ProductGalary = (props) => {
    const {images} = props
    const [nav1, setNav1] = useState(images[0])
    const [nav2, setNav2] = useState(images[0])
    const [isOpen, setIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const imageRefs = useRef([])

    const settings = {
        slidesToShow: 3,
        arrows: true,
        infinite: true,
        swipeToSlide: true,
        focusOnSelect: true,
        className: "thumbnails",
        nextArrow: <CarouselArrowRight/>,
        prevArrow: <CarouselArrowLeft/>
    }

    useEffect(() => {
        if (isOpen) {
            const options = {
                index: currentIndex,
                bgOpacity: 0.9,
                history: false
            };

            const items = imageRefs.current.map((tag, index) => {
                const width = parseFloat(tag?.dataset?.width) || tag?.naturalWidth
                const height = parseFloat(tag?.dataset?.height) || tag?.naturalHeight
                return {
                    src: images[index].large_image_url,
                    msrc: images[index].large_image_url,
                    w: width,
                    h: height,
                }
            })

            let gallery = new PhotoSwipe(document.querySelector('.pswp'), PhotoSwipeUI_Default, items, options);
            gallery.init();


            gallery.listen('beforeChange', () => {
                setCurrentIndex(gallery.getCurrentIndex());
            });

            gallery.listen('close', () => {
                setIsOpen(false);
            });

            return () => {
                gallery = null
            };
        }
    }, [isOpen]);

    const openGallery = (index) => {
        setIsOpen(true);
        setCurrentIndex(index);
    };

    const fakeSlidesGen = () => {
        const result = []
        for (let i = 0; i < 3 - images.length; i++) {
            result.push(i)
        }

        return result.map((el, index) => {
            return <div className="image_wrapper" key={index}>
                {/*<img*/}
                {/*    src={image.original_image_url}*/}
                {/*    alt=""*/}
                {/*/>*/}
            </div>
        })
    }

    return <div className="product_galary">
        <NewPhotoSwipe/>

        <Slider
            asNavFor={nav2}
            ref={slider => setNav1(slider)}
        >
            {images.map((image, index) => {
                return <div key={index} className="image_wrapper">
                    <img
                        src={image.original_image_url}
                        alt=""
                        ref={(element) => imageRefs.current[index] = element}
                    />
                    <button
                        type="button"
                        className="product-gallery__zoom"
                        onClick={() => openGallery(index)}
                    >
                        <ZoomIn24Svg/>
                    </button>
                </div>
            })}
        </Slider>

        <Slider
            asNavFor={nav1}
            ref={slider => setNav2(slider)}
            {...settings}
        >
            {images.map((image, index) => {
                return <div className="image_wrapper" key={index}>
                    <img
                        src={image.original_image_url}
                        alt=""
                    />
                </div>
            })}

            {
                fakeSlidesGen()
            }
        </Slider>
    </div>
}

export default ProductGalary
