// react
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// data stubs

import { FormattedMessage } from "react-intl";
import Breadcrumbs from "../shared/Breadcrumbs";
import {url} from "../../services/utils";

export default function ContactWithUs(props) {
    const{location} = props
    const intialValues = { email: "", name: "", phone: "", message: "" };
    const locale = useSelector(state => state.locale)
    const [formValues, setFormValues] = useState(intialValues);
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    // const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();

    const submitForm = () => {
        let options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formValues),
        };

        fetch(`${url}/api/contact-us`, options)
            .then((responce) => responce.json())
            .then((res) => {
                if (res == "email sent successfully") {
                    history.push(`${locale}/thanksforMessage`);
                }
            })
            .catch((err) => console.error(err));
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };
    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.name) {
            errors.name = "This field is required";
        }

        if (!values.email) {
            errors.email = "This field is required";
        }
        if (values.email && !regex.test(values.email)) {
            errors.email = "Invalid email format";
        }

        if (!values.message) {
            errors.message = "This field is required";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);

    const breadcrumb = [
        {title: <FormattedMessage id="home" defaultMessage="home"/>, url: url.home},
        {title: <FormattedMessage id="contact.with.us" defaultMessage="Կապ մեզ հետ" />, url: location.pathname},
    ];


    return (
        <div className="site__body">
            <Breadcrumbs breadcrumb={breadcrumb}/>
            <div className="container p-4">
                <form action="" onSubmit={handleSubmit}>
                    <h1 className="contact-customhtml-fms">
                        <FormattedMessage id="contact.with.us" defaultMessage="Կապ մեզ հետ" />
                    </h1>
                    <fieldset className="fieldset">
                        <div className="fieldset-params-fms">
                            <div className="field email required">
                                <div className="label-fms">
                                    <div className="contact-label-fms">
                                        <FormattedMessage id="contact.email" defaultMessage={<span>Էլ․հասցե</span>} />
                                        <span className="red-fms"> *</span>
                                    </div>
                                </div>
                                <div className="control">
                                    <input
                                        name="email"
                                        title="email"
                                        className="input-text login_inp_fm-2"
                                        type="email"
                                        aria-required="true"
                                        onChange={handleChange}
                                        value={formValues.email}
                                    />
                                </div>

                                {formErrors.email && (
                                    <span className="error red-fms">
                                        <FormattedMessage
                                            id="account.error.required"
                                            defaultMessage={formErrors.email}
                                        />
                                    </span>
                                )}
                            </div>
                            <div className="field email required">
                                <div className="label-fms">
                                    <div className="contact-label-fms">
                                        <FormattedMessage id="contact.name" defaultMessage={<span>Անուն</span>} />
                                        <span className="red-fms"> *</span>
                                    </div>
                                </div>
                                <div className="control">
                                    <input
                                        name="name"
                                        title="name"
                                        className="input-text login_inp_fm-2"
                                        type="text"
                                        data-validate="{required:true, 'validate-email':true}"
                                        aria-required="true"
                                        onChange={handleChange}
                                        value={formValues.name}
                                    />
                                </div>

                                {formErrors.name && (
                                    <span className="error red-fms">
                                        <FormattedMessage
                                            id="account.error.required"
                                            defaultMessage={formErrors.name}
                                        />
                                    </span>
                                )}
                            </div>
                            <div className="field email required">
                                <div className="label-fms">
                                    <div className="contact-label-fms">
                                        <FormattedMessage id="contact.tel" defaultMessage="Հեռախոսահամար" />
                                    </div>
                                </div>
                                <div className="control">
                                    <input
                                        name="phone"
                                        title="Հեռախոսահամար"
                                        value={formValues.phone}
                                        className="input-text login_inp_fm-2"
                                        type="phone"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="field comment required">
                            <div className="label-fms">
                                <div className="contact-label-fms">
                                    <FormattedMessage
                                        id="contact.comment"
                                        defaultMessage={<span>Հաղորդագրություն</span>}
                                    />
                                    <span className="red-fms"> *</span>
                                </div>
                            </div>
                            <div className="comment-fms">
                                <textarea
                                    name="message"
                                    title="message"
                                    className="input-text "
                                    type="text"
                                    onChange={handleChange}
                                    aria-required="true"
                                    value={formValues.message}
                                />
                            </div>
                        </div>
                        {formErrors.message && (
                            <span className="error red-fms">
                                <FormattedMessage id="account.error.required" defaultMessage={formErrors.message} />
                            </span>
                        )}

                        <div className="action-fms">
                            <button onClick={() => submitForm} type="submit" class="btn-primary submit-primary-fms">
                            <FormattedMessage id="contact.submit" defaultMessage={<span>Ուղարկել</span>} />
                            </button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );
}
