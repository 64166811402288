// react
import React, {Component, useEffect, useState} from "react";
import {cartRemoveItemNew, cartUpdateQuantitiesNew, getCartDataMount} from "../../store/cart";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classNames from "classnames";
import AllPurchases from "./AllPurchases";
import AsyncAction from "../shared/AsyncAction";
import ProductModal from "../blocks/ProductModal";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import InputNumberCart from "../shared/InputNumberCart";
import shopApi from "../../api/shop";
import {toast} from "react-toastify";
import {url} from "../../services/utils";
import PageHeader from "../shared/PageHeader";
import Breadcrumbs from "../shared/Breadcrumbs";
import {Helmet} from "react-helmet-async";
import {Arrow2} from "../../svg";

class ShopPageCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cart: this.props.cart,
            modal: false,
            product: null,
            quantities: [],
            isLoadingSync: 0,
            updateSync: false,
            customer: this.props.customer,
            isOpenPurchases: false,
            isClicked: false,
        };
    }

    handleChangeQuantity = (item, qty) => {
        const stateQuantity = this.state.quantities.find((x) => x.itemId === item.id);
        const newState = this.state

        if (!stateQuantity) {
            newState.quantities.push({itemId: item.id, value: qty});
        } else {
            stateQuantity.value = qty;
        }

        this.setState(() => {
            return {
                quantities: newState.quantities
            }
        });

        this.props.cartUpdateQuantitiesNew(
            this.state.quantities,
            this.props.cart.items,
        )
    };

    getItemQuantity(item) {
        const {quantities} = this.state;
        const quantity = quantities.find((x) => x.itemId === item.id);
        return quantity ? quantity.value : item.quantity;
    }


    cartNeedUpdate() {
        const {quantities} = this.state;
        const {cart} = this.props;

        return (
            quantities.filter((x) => {
                const item = cart.items.find((item) => item.id === x.itemId);

                return item && item.quantity !== x.value && x.value !== "";
            }).length > 0
        );
    }

    modalFunc = (product) => {
        this.setState({product: product, modal: true});
    };

    closeModal = () => {
        this.setState({modal: false});
    };


    handleManagerConfirm() {

        const [details, setDetails] = useState([])

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({token: this.state.customer.token}),
        };
        shopApi.sendConfirm({options}).then((response) => {
            toast.success(<FormattedMessage id="subuser_cart_confirm"
                                            defaultMessage="Your products successfully send to the Company admin"/>);
            this.props.getCartDataMount(localStorage.getItem("api_token"))
        });
        // useEffect(() => {
        //         shopApi.getProductAttributes(simpleProduct?.id, locale)
        //             .then(res => res.data)
        //             .then(attributes => setDetails((attributes)))
        // },)
        //
        // console.log(details, 'detailssssss')

        this.props.history.push("/")
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (this.props.cart.items.length === 0) {
    //         localStorage.removeItem("api_token");
    //     }
    // }


    render() {
        // item
        const {cart, cartRemoveItemNew, location, locale} = this.props;
        const {modal, product, customer} = this.state;

        const subTotal = (parseFloat(cart?.sub_total)).toFixed(2);

        const breadcrumb = [
            {title: <FormattedMessage id="home" defaultMessage="home"/>, url: url.home},
            {title: <FormattedMessage id="cart.cart" defaultMessage="CART"/>, url: location.pathname},
        ];

        if (cart)
            return <>
                <Helmet>
                    <title>cart</title>
                </Helmet>
                <div className="cart_page_fm">
                    <ProductModal modal={modal} modalFunc={this.closeModal} data={product}/>
                    <Breadcrumbs breadcrumb={breadcrumb}/>
                    <div className="container_fm cart-page_container">
                        {/*<div className="cart_title_fm">*/}
                        {/*    <FormattedMessage id="cart.cart" defaultMessage="CART"/>*/}
                        {/*</div>*/}
                        <PageHeader header=<FormattedMessage id="cart.cart" defaultMessage="CART"/>/>

                        {cart.items?.length > 0 && (
                            <div className="cart_block_fm">
                                <div className="block">
                                    <div className="container_fm">
                                        <div className="cart_table_fm d-block d-md-none">
                                            <div className="cart_mob_tab_fm">
                                                {cart.items?.map((e, i) => {

                                                    let minQty = Boolean(e.product?.min_qty) ? Number(e.product.min_qty) : 1
                                                    return <div className="cart_mob_blok_fm" key={i}>
                                                        <div className="cart_mob_prod_fm">
                                                            <div
                                                                className='image_container_mob'
                                                                // onClick={() => {
                                                                //     this.modalFunc(e.product);
                                                                // }}
                                                            >
                                                                <Link
                                                                    to={`/${locale}/shop/products/${e.product?.url_key}`}
                                                                    style={{color: "unset"}}>
                                                                    <img
                                                                        src={
                                                                            e?.product?.base_image?.original_image_url ||
                                                                            e?.product?.product?.base_image
                                                                                ?.original_image_url
                                                                        }
                                                                        alt=""
                                                                        height="70px"
                                                                    />
                                                                </Link>
                                                            </div>
                                                            <div className='product_data_mob'>
                                                                <div
                                                                    className='product_name_mob'
                                                                    // onClick={() => {
                                                                    //     this.modalFunc(e.product);
                                                                    // }}
                                                                >
                                                                    <span>
                                                                        <Link
                                                                            to={`/${locale}/shop/products/${e.product?.url_key}`}
                                                                            style={{color: "unset"}}>
                                                                            {e.product.name}
                                                                        </Link>
                                                                    </span>
                                                                </div>
                                                                <div className='price_mob'>
                                                                    <div>
                                                                        <FormattedMessage
                                                                            id="global.price"
                                                                            defaultMessage="Price"
                                                                        />
                                                                        {' -'}
                                                                    </div>
                                                                    <div className='space'>
                                                                        {Math.round(e.price)}{" "}
                                                                        <FormattedMessage
                                                                            id="global.curency"
                                                                            defaultMessage="AMD"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='prod-box_price'>
                                                                    {!!e.product.box_price && !!e.product.box_qty ?
                                                                        <>
                                                                            <div className='box_pr'>
                                                                                <FormattedMessage
                                                                                    id='box_price'
                                                                                    defaultMessage='Box price - '
                                                                                />
                                                                                {' - '}
                                                                                {e.product.box_price}
                                                                                {' '}
                                                                                <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                                                            </div>
                                                                            <div className='box_qty'>
                                                                                <FormattedMessage
                                                                                    id='box_qty'
                                                                                    defaultMessage='Quantity in box - '
                                                                                />
                                                                                {' - '}
                                                                                {e.product.box_qty}
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        ''
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <div className='quantity_mob'>
                                                                        <InputNumberCart
                                                                            min={minQty}
                                                                            max={e.product.qty}
                                                                            step={minQty}
                                                                            itemId={cart.items[i].id}
                                                                            cartRemoveItemNew={cartRemoveItemNew}
                                                                            onChange={(quantity) => {
                                                                                if (quantity <= 0) {
                                                                                    quantity = minQty
                                                                                } else if (quantity > e.product.qty) {
                                                                                    quantity = e.product.qty
                                                                                }
                                                                                this.handleChangeQuantity(
                                                                                    e,
                                                                                    quantity,
                                                                                    cart.items[i].itemId
                                                                                )
                                                                            }}
                                                                            value={this.getItemQuantity(e)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div/>
                                                            </div>
                                                        </div>
                                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                                            <div className="cart_mob_title_fm_last_row">
                                                                <div className='cart-total_mob'>
                                                                    <div>
                                                                        <FormattedMessage
                                                                            id="global.total"
                                                                            defaultMessage="Total"
                                                                        />
                                                                        {' - '}
                                                                    </div>
                                                                    <div className='space'>
                                                                        {Math.round(e.total)}{" "}
                                                                        <FormattedMessage
                                                                            id="global.curency"
                                                                            defaultMessage="AMD"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <AsyncAction
                                                                        action={() => {
                                                                            return cartRemoveItemNew(e.id);
                                                                        }}
                                                                        render={({run, loading}) => (
                                                                            <button
                                                                                type="button"
                                                                                onClick={run}
                                                                                className={`btn_cart_rem_fm ${classNames({
                                                                                    "btn-loading": loading,
                                                                                })}`}
                                                                            >
                                                                                <FormattedMessage id='remove' defaultMessage='Remove'/>
                                                                            </button>
                                                                        )}
                                                                    />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                })}
                                            </div>
                                        </div>

                                        <div className="cart_table_fm  d-none d-md-table">
                                            <div>
                                                <div className="border_bottom cart-page_headings">
                                                    <div className="cart_block_title cart_block_title1">
                                                        <FormattedMessage id="global.product.l" defaultMessage="PRODUCT"/>
                                                    </div>
                                                    <div className="cart_block_title"></div>
                                                    <div className="cart_block_title">
                                                        <FormattedMessage id="global.price.l" defaultMessage="PRICE"/>
                                                    </div>
                                                    <div className="cart_block_title">
                                                        <FormattedMessage id="global.quantity.l" defaultMessage="QUANTITY"/>
                                                    </div>
                                                    <div className="cart_block_title">
                                                        <FormattedMessage id="global.total.l" defaultMessage="Subtotal"/>
                                                    </div>
                                                    <div className="cart_block_title"></div>
                                                </div>
                                            </div>
                                            <div>
                                            {cart.items?.map((e, i) => {

                                                let minQty = Boolean(e.product?.min_qty) ? Number(e.product.min_qty) : 1
                                                return <div className="border_bottom" key={i}>
                                                    <div
                                                        className="cart_block_content cart_block_content_img"
                                                        // onClick={() => {
                                                        //     this.modalFunc(e.product);
                                                        // }}
                                                    >
                                                        <Link to={`/${locale}/shop/products/${e.product?.url_key}`}
                                                              style={{color: "unset"}}>
                                                            <img
                                                                src={
                                                                    e?.product?.base_image?.original_image_url ||
                                                                    e?.product?.product?.base_image?.original_image_url
                                                                }
                                                                alt=""
                                                                height="100px"
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div className="cart_block_content" style={{justifySelf: 'left'}}>
                                                        <span>
                                                            <Link
                                                                to={`/${locale}/shop/products/${e.product?.url_key}`}
                                                                style={{color: "unset"}}>
                                                                        {e.product.name}
                                                            </Link>
                                                        </span>
                                                    </div>
                                                    <div className="cart_block_content cart_block_content-price">
                                                        <div className='prod-price'>
                                                            {Math.round(e.price)}{" "}
                                                            <FormattedMessage
                                                                id="global.curency"
                                                                defaultMessage="AMD"
                                                            />
                                                        </div>
                                                        <div className='prod-box_price'>
                                                            {!!e.product.box_price && !!e.product.box_qty ?
                                                                <>
                                                                    <div className='box_pr'>
                                                                        <FormattedMessage
                                                                            id='box_price'
                                                                            defaultMessage='Box price - '
                                                                        />
                                                                        {' - '}
                                                                        {e.product.box_price}
                                                                        {' '}
                                                                        <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                                                    </div>
                                                                    <div className='box_qty'>
                                                                        <FormattedMessage
                                                                            id='box_qty'
                                                                            defaultMessage='Quantity in box - '
                                                                        />
                                                                        {' - '}
                                                                        {e.product.box_qty}
                                                                    </div>
                                                                </>
                                                                :
                                                                ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="cart_block_content">
                                                        <InputNumberCart
                                                            min={minQty}
                                                            max={e.product.qty}
                                                            step={minQty}
                                                            itemId={cart.items[i].id}
                                                            cartRemoveItemNew={cartRemoveItemNew}
                                                            onChange={(quantity) => {
                                                                if (quantity <= 0) {
                                                                    quantity = minQty
                                                                } else if (quantity > e.product.qty) {
                                                                    quantity = e.product.qty
                                                                }
                                                                this.handleChangeQuantity(
                                                                    e,
                                                                    quantity,
                                                                    cart.items[i].itemId
                                                                )
                                                            }}
                                                            value={this.getItemQuantity(e)}
                                                        />
                                                    </div>
                                                    <div className="cart_block_content">
                                                        {Math.round(e.total)}{" "}
                                                        <FormattedMessage
                                                            id="global.curency"
                                                            defaultMessage="AMD"
                                                        />
                                                    </div>
                                                    <div className="cart_block_content cart_block_content_cancel">
                                                        <AsyncAction
                                                            action={() => {
                                                                return cartRemoveItemNew(e.id);
                                                            }}
                                                            render={({run, loading}) => (
                                                                <button
                                                                    type="button"
                                                                    onClick={run}
                                                                    className={`btn_cart_rem_fm ${classNames({
                                                                        "btn-loading": loading,
                                                                    })}`}
                                                                >
                                                                    <FormattedMessage id='remove' defaultMessage='Remove'/>
                                                                </button>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            })}
                                            </div>
                                        </div>
                                        <div className='last_row_container reverse-columns'>
                                            <Link to='/'>
                                                <div className='column1'>
                                                    <Arrow2/>
                                                    <FormattedMessage id='continue_shopping' defaultMessage='Continue shopping'/>
                                                </div>
                                            </Link>
                                            <div className='column2'>
                                                <FormattedMessage id='total_price' defaultMessage='Total price'/>
                                                <div className='space'>
                                                    {subTotal}{" "}
                                                    <FormattedMessage id='global.curency' defaultMessage='AMD'/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="update_cart_fm">
                                            {customer.authenticated && customer.role === "Manager" && (
                                                <button
                                                    onClick={() => {

                                                        this.setState({
                                                            isLoadingSync: this.state.isLoadingSync + 1,
                                                            isOpenPurchases: true,
                                                            isClicked: true
                                                        })

                                                    }}
                                                    type="button"
                                                    className={classNames({
                                                        buy_butt_fm: true,
                                                        "btn-loading": this.state.isClicked,
                                                    })}
                                                >
                                                    <FormattedMessage id="cart.sync" defaultMessage="Sync"/>
                                                </button>
                                            )}
                                        </div>

                                        <div className="buy_fm">
                                            {!customer ||
                                            (customer && customer.role === "Manager") ||
                                            (customer && !customer.role) ? (
                                                <Link to={`/${locale}/shop/checkout`}>
                                                    <button className="buy_butt_fm">
                                                        <FormattedMessage
                                                            id="cart.goToCheckout"
                                                            defaultMessage="Go To Checkout"
                                                        />
                                                    </button>
                                                </Link>
                                            ) : (
                                                <button
                                                    className="buy_butt_fm"
                                                    onClick={() => this.handleManagerConfirm()}
                                                >
                                                    <FormattedMessage id="cart.confirm" defaultMessage="Confirm"/>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <>
                            <div className="cart_title_fm1">
                                {cart.items?.length === 0 && customer.authenticated && customer.role === "Manager" && (
                                    <button
                                        onClick={() => this.setState({
                                            // isLoadingSync: +1,
                                            // isOpenPurchases: true,
                                            // isClicked: !this.state.isClicked
                                            isLoadingSync: this.state.isLoadingSync + 1,
                                            isOpenPurchases: true,
                                            isClicked: true
                                        })}
                                        type="button"
                                        className={classNames({
                                            buy_butt_fm: true,
                                            "btn-loading": this.state.isClicked,
                                        })}
                                    >
                                        <FormattedMessage id="cart.sync" defaultMessage="Sync"/>
                                    </button>
                                )}

                                {/* {isOpenPurchases ? ( */}
                                <div>
                                    <AllPurchases
                                        token={this.state.customer.token}
                                        isLoadingSync={this.state.isLoadingSync}
                                        callback={() => this.setState({isClicked: false})}
                                    />
                                </div>
                                {/* ) : null} */}
                            </div>

                            {!cart.items?.length && (
                                <div className="cart_title_fm1">
                                    <FormattedMessage id="cart.yourCartIsEmpty" defaultMessage="Your cart is empty"/>
                                </div>
                            )}
                        </>
                    </div>
                </div>
            </>
    }
}

ShopPageCart.propTypes = {
    /** product object */
    cart: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

ShopPageCart.defaultProps = {
    layout: "standard",
};

const mapStateToProps = (state) => ({
    cart: state.cart,
    customer: state.customer,
    locale: state.locale
});

const mapDispatchToProps = {
    cartRemoveItemNew,
    getCartDataMount,
    cartUpdateQuantitiesNew,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
