const locale = JSON.parse(localStorage.getItem("state"))?.locale || "hy"

export const url = {
    home: () => '/',

    catalog: () => `/${locale}/shop/catalog`,

    category: (category) => `/${locale}/shop/main/catalog/${category.slug}`,

    product: (product) => `/${locale}/shop/products/${product.url_key}`,

    image: (product) => `https://api.fooddepot.am/storage/`
};

export function getCategoryParents(category) {
    return category.parent ? [...getCategoryParents(category.parent), category.parent] : [];
}

export const translateMetas = (str) => {
    const metaTranslations = str.split('|')
    return {
        "hy": metaTranslations[0] || "",
        "ru": metaTranslations[1] || "",
        "en": metaTranslations[2] || ""
    }
}
